import React, { useContext, useEffect, useRef, useState } from "react";
import {
  fetchExtCrmPurchaseOrdersProto,
  fetchExtPurchaseRequests,
  fetchExtPurchaseRequestsItems,
  fetchJobsProtoSimple,
  fetchPrItemDetail,
  intlFormat,
  makeDateString,
  padNumber,
} from "../../helpers";
import { AppContext } from "../../App";
import { ExtPurchaseRequest, PrItemDetail } from "../../models/model";
import { useParams } from "react-router-dom";
import "./print-media-query.css";
import { CrmPurchaseOrders, PpicJobs } from "../../masterbigsystem";

const PurchaseRequestPrintPage = () => {
  const ctx = useContext(AppContext);

  const prs = useRef(null as ExtPurchaseRequest[] | null);
  const prItems = useRef([] as PrItemDetail[]);
  const jobs = useRef(PpicJobs.fromPartial({ jobs: [] }));
  const poLoading = useRef(false);
  const { id } = useParams() as { id: string };

  const handleInit = async () => {
    fetchExtPurchaseRequestsData();
    fetchExtPurchaseOrdersData();
    fetchJobsData();
  };

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const pos = useRef(CrmPurchaseOrders.fromPartial({ purchaseOrders: [] }));

  const fetchExtPurchaseRequestsData = async () => {
    const d = await fetchExtPurchaseRequests({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      prs.current = d;

      const prItemsD = await fetchPrItemDetail({ prId: id });

      if (prItemsD) {
        prItems.current = prItemsD;
      }
    }

    render();
  };

  const fetchExtPurchaseOrdersData = async () => {
    poLoading.current = true;
    render();

    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
    }
    poLoading.current = false;

    render();
  };

  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey ?? "",
      withPurchaseOrders: true,
    });

    if (d) {
      jobs.current = d;
    }

    render();
  };

  useEffect(() => {
    handleInit();
  }, []);

  const foundPR = prs.current?.find((p) => `${p.id}` === `${id}`);
  const foundJob = jobs.current.jobs.find(
    (j) => `${j.masterJavaBaseModel?.id}` === `${foundPR?.jobId}`
  );
  const foundPO = pos.current.purchaseOrders.filter((p) =>
    foundJob?.jobPurchaseOrders.find(
      (jp) => `${jp.extPurchaseOrderId}` === `${p.id}`
    )
  );
  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <h4>Purchase Request</h4>
          </div>

          <div>
            <div>
              <table className="table table-sm">
                <tr>
                  <th className="border border-dark">No. Dokumen</th>
                  <th className="border border-dark"> QF-PPC-0002</th>
                </tr>
                <tr>
                  <th className="border border-dark">No. Revisi</th>
                  <th className="border border-dark">No. 01</th>
                </tr>
                <tr>
                  <th className="border border-dark">Tanggal Efektif</th>
                  <th className="border border-dark">
                    {intlFormat({
                      date: foundPR?.createdAt,
                      dateStyle: "medium",
                    })}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <div>Intercon Plaza Blok D.11</div>
            <div>Jl. Meruya Ilir, Srengseng-Kembangan</div>
            <div>Jakarta Barat 11630</div>
            <div>Phone: (62-21)7587-9949/51</div>
          </div>

          <div>
            <table className="table table-sm">
              <tr>
                <th className="border border-dark">PR No.</th>
                <th className="border border-dark">
                  {foundPR?.prNumber}
                  {padNumber(foundPR?.prNumberSeq ?? 0, 4)}
                </th>
              </tr>
              <tr>
                <th className="border border-dark">PR Date</th>
                <th className="border border-dark">
                  {" "}
                  {intlFormat({
                    date: makeDateString(new Date(foundPR?.prDate ?? "")),
                    dateStyle: "medium",
                  })}
                </th>
              </tr>
              <tr>
                <th className="border border-dark">Requested By</th>
                <th className="border border-dark">{foundPR?.requestFrom}</th>
              </tr>
            </table>
          </div>
        </div>

        <div>
          <hr className="border border-dark" />
        </div>

        <div className="border border-dark p-1">
          <strong>DETAILS</strong>
          <div>Source: BSD-Assembly</div>
          <div>Request Mode: Routine</div>
          <div>
            Purpose: {foundPR?.purpose} | {foundPR?.purposeRemark}
          </div>
          <div>
            PO:{" "}
            {poLoading.current ? (
              <>Loading...</>
            ) : (
              <>
                {foundPO
                  .map((p) => `${p?.purchaseOrderNumber} (${p?.account?.name})`)
                  .join(", ")}
              </>
            )}
          </div>
        </div>

        <div>
          <hr className="border border-dark" />
        </div>

        <div>
          <table className="table table-sm">
            <tr>
              {[
                "#",
                "PN",
                "MFR",
                "Desc",
                "PO",
                "PO Cust Qty",
                "PR Qty",
                "PR Balance",
                "Deliv. Date",
              ].map((h) => {
                return (
                  <>
                    <th className="border border-dark">{h}</th>
                  </>
                );
              })}
            </tr>
            {prItems.current.map((p, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark p-0 m-0">{i + 1}</td>
                    <td className="border border-dark p-0 m-0">
                      {p.item?.partNum}
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {p.item?.mfr}
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {p.item?.partName} {p.item?.partDesc}
                    </td>
                    <td className="border border-dark p-0 m-0">-</td>
                    <td className="border border-dark p-0 m-0">-</td>
                    <td className="border border-dark p-0 m-0">{p.qtyPr}</td>
                    <td className="border border-dark p-0 m-0">-</td>
                    <td className="border border-dark p-0 m-0">
                      {intlFormat({
                        date: foundPR?.prTarget,
                        dateStyle: "medium",
                      })}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>

        <div>
          <div>
            Note: Please write our P.O. Number on all correspondences. Please
            sign and fax back our P.O. for comfirmation
          </div>
        </div>

        <div
          style={{ marginTop: "2em" }}
          className="d-flex justify-content-around"
        >
          <div>
            <div>
              <strong>Created by:</strong>
            </div>
          </div>

          <div>
            <div>
              <strong>Product Manager:</strong>
            </div>
          </div>

          <div>
            <div>
              <strong>Approved by GM Operational:</strong>
            </div>
          </div>

          <div>
            <div>
              <strong>Approved by GM Accounting:</strong>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseRequestPrintPage;
