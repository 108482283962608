import { ChevronLeft } from "@material-ui/icons";
import { maxTime } from "date-fns";
import { get, set } from "idb-keyval";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalDialog,
  ModalFooter,
  Spinner,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Select from "react-select";
import { createJsxJsxClosingFragment } from "typescript";
import { AppContext } from "../../../App";
import {
  fetchBomLeveledsRecursiveProto,
  fetchCustomersProto,
  fetchDepartmentTemplatesProto,
  fetchDepartmentTemplatesView,
  fetchExtInventory,
  fetchExtPurchaseRequests,
  fetchExtPurchaseRequestsItems,
  fetchHandoversProto,
  fetchIntegrationModulesProto,
  fetchJob,
  fetchJobOverviewHiddenCols,
  fetchJobsDataProto,
  fetchJobsDataProtoCompose,
  fetchMachinePrograms,
  fetchMaterialRequestsFiltered,
  fetchPurchaseOrderSupplier,
  fetchRcemDepartmentsProto,
  fetchSsoUsers,
  fetchWarehouseItemsProto,
  fetchWorkOrdersProto,
  fetchWorkOrdersProtoMongo,
  fetchWorkOrdersProtoMongoDay,
  flattenModules,
  getChromaColor,
  getItemFullDescription,
  getPanelCodeCompleteSerialNumber,
  getPanelCodeCompleteSerialNumberProto,
  getPurchaseRequestInfo,
  getWorkOrderTimeMinutes,
  jobDeptCategories,
  jobOverviewHeaders,
  JobPriority,
  JobTypeSelection,
  makeDateString,
  makeReadableDateStringIntlDateOnlyUtc,
  parseJwt,
  PrInfoReturnBody,
  progressColor,
} from "../../../helpers";
import {
  HandoverQAStatus,
  MeetingTaskListsView,
  PpicBomLeveled,
  PpicBomLeveleds,
  PpicDepartmentTemplates,
  PpicExtDepartmentHiddenJobOverviewCols,
  PpicHandover_Status,
  PpicHandovers,
  PpicIndividualEntities,
  PpicIndividualEntity,
  PpicIntegrationModules,
  PpicJob,
  PpicJobOverview,
  PpicJobOverviews,
  PpicJobs,
  PpicMachine,
  PpicMachinePrograms,
  PpicMaterialRequestsSimple,
  PpicPanelCode,
  PpicPanelCodeSerialNumber,
  PpicPanelCodeSerialNumberManual,
  PpicRcemDepartments,
  PurchasingPurchaseRequestItemsOutstanding,
  PurchasingPurchaseRequests,
  PurchasingSupplierPurchaseOrders,
  SsoUserEntitiesData,
  WarehouseItem,
  WarehouseItems,
  handoverQAStatusFromJSON,
  handoverQAStatusToJSON,
  ppicHandover_StatusFromJSON,
} from "../../../masterbigsystem";
import {
  DepartmentTemplateView,
  ExtInventory,
  ExtPurchaseRequest,
  ExtPurchaseRequestPostBodyJson,
  JobDeptCategory,
  JobMapped,
  JobType,
  MachineProgram,
} from "../../../models/model";
import {
  initialExtPurchaseRequestPostBodyItemJson,
  initialExtPurchaseRequestPostBodyJson,
} from "../../../models/modelinitials";

const JobOverviewPage = () => {
  type SortBy = "Deadline" | "Priority" | "Customer" | "Job";

  const ctx = useContext(AppContext);
  const [showCreatePrModal, setshowCreatePrModal] = useState(false);
  const [extPurchaseRequests, setExtPurchaseRequests] =
    useState<PurchasingPurchaseRequestItemsOutstanding>(
      PurchasingPurchaseRequestItemsOutstanding.fromPartial({
        purchaseRequestItems: [],
      })
    );
  const [panelCodeWos, setPanelCodeWos] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const [panelCodeCommentSelectedJob, setPanelCodeCommentSelectedJob] =
    useState<PpicJob>(PpicJob.fromPartial({}));

  const [panelCodeRemarkSelectedJob, setPanelCodeRemarkSelectedJob] =
    useState<PpicJob | null>(null);
  const [panelCodeRemarkDialog, setShowPanelCodeRemarkDialog] = useState(false);

  const handovers = useRef(PpicHandovers.fromPartial({}));
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);
  const [rcemDepartments, setRcemDepartments] =
    useState<PpicRcemDepartments | null>(null);
  const [integrationModules, setIntegrationModules] =
    useState<PpicIntegrationModules | null>(
      PpicIntegrationModules.fromPartial({})
    );
  const [machineProgramsProto, setMachineProgramsProto] =
    useState<PpicMachinePrograms>(
      PpicMachinePrograms.fromPartial({ machinePrograms: [] })
    );

  const [prLoading, setPrLoading] = useState(false);
  const [prInfo, setPrInfo] = useState<PrInfoReturnBody[]>([]);
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState<ExtInventory[]>([]);
  const [inventoryLoading, setInventoryLoading] = useState(false);
  const [jobMaterialRequests, setJobMaterialRequests] = useState(
    PpicMaterialRequestsSimple.fromPartial({ materialRequests: [] })
  );
  const [ssoUsers, setSsoUsers] = useState(SsoUserEntitiesData.fromPartial({}));

  const [jobMaterialRequestsLoading, setJobMaterialRequestsLoading] =
    useState(false);
  const [items, setItems] = useState<WarehouseItems>(
    WarehouseItems.fromPartial({ items: [] })
  );
  const [itemsLoading, setItemsLoading] = useState(false);
  const [jobs, setJobs] = useState<PpicJobOverviews>(
    PpicJobOverviews.fromPartial({ jobs: [] })
  );
  const [customers, setCustomers] = useState<PpicIndividualEntities>(
    PpicIndividualEntities.fromPartial({ entities: [] })
  );
  const [bomLeveleds, setBomLeveleds] = useState<PpicBomLeveleds>(
    PpicBomLeveleds.fromPartial({ bomLeveleds: [] })
  );
  const [extPurchaseOrderSupplier, setExtPurchaseOrderSupplier] =
    useState<PurchasingSupplierPurchaseOrders>(
      PurchasingSupplierPurchaseOrders.fromPartial({ purchaseOrders: [] })
    );
  const [jobOverviewHiddenCols, setjobOverviewHiddenCols] =
    useState<PpicExtDepartmentHiddenJobOverviewCols>(
      PpicExtDepartmentHiddenJobOverviewCols.fromPartial({ hiddenCols: [] })
    );

  const [filters, setFilters] = useState({
    jobClosed: false,
    customer: null as any,
    category: "PPC" as JobDeptCategory,
    priority: null as string | null,
  });
  const [departmentTemplates, setDepartmentTemplates] = useState<
    DepartmentTemplateView[] | null | undefined
  >(null);
  const [departmentTemplatesProto, setDepartmentTemplatesProto] = useState<
    PpicDepartmentTemplates | null | undefined
  >(null);

  const [selectedJobWos, setSelectedJobWos] =
    useState<MeetingTaskListsView | null>(null);
  const [selectedJobId, setSelectedJobId] = useState<any>(null);
  const [selectedJobFull, setSelectedJobFull] = useState<{
    jobPriority: JobPriority;
    jobTypeSelections: JobTypeSelection[] | undefined;
    job: JobMapped;
  } | null>(null);

  const [showPanelModal, setShowPanelModal] = useState(false);
  const [showShortageModal, setshowShortageModal] = useState(false);

  const [showActualShortageModal, setshowActualShortageModal] = useState(false);

  const [workOrdersLoading, setWorkOrdersLoading] = useState(false);
  const [workOrders, setWorkOrders] = useState<MeetingTaskListsView>(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );

  const [selectedPanelCodeId, setSelectedPanelCodeId] = useState<any>(null);
  const [filterBy, setFilterBy] = useState<SortBy>("Deadline");
  const [filterAsc, setFilterAsc] = useState(false);
  const [hiddenOverviewFields, setHiddenOverviewFields] = useState<
    string[] | null | undefined
  >([]);
  const [blacklistedItems, setBlacklistedItems] = useState<string[]>([]);
  useEffect(() => {
    initHiddenOverviewFields();
  }, []);
  const [remainingFilter, setRemainingFilter] = useState<"OK" | "Prob" | "All">(
    "All"
  );
  const fetchSsoUsersData = async () => {
    setSsoUsers(await fetchSsoUsers({ apiKey: ctx?.apiKey ?? "" }));
  };
  const [taskListsJobToday, setTaskListsJobToday] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const [taskListsJobNextDay, setTaskListsJobNextDay] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const jobDetailViewType = useRef("Panel" as "Panel" | "Batch");

  const initHiddenOverviewFields = async () => {
    console.log("[hidden field]", await get("hiddenOverviewFields"));
    if (!(await get("hiddenOverviewFields"))) {
      await set("hiddenOverviewFields", []);
      setHiddenOverviewFields([]);
    } else {
      setHiddenOverviewFields(await get("hiddenOverviewFields"));
    }
  };

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const fetchHandoversData = async () => {
    const d = await fetchHandoversProto({
      apiKey: ctx?.apiKey ?? "",
      all: true,
    });

    if (d) {
      handovers.current = d;
      render();
    }
  };

  useEffect(() => {
    fetchJobsData();
    fetchCustomersData();
    fetchDepartmentTemplatesData();
    fetchPpicBomLeveledsData();
    fetchItemsData();
    fetchExtInventoryData();
    fetchPurchaseOrderSupplierData();
    fetchSsoUsersData();
    fetchJobOverviewHiddenColsData();
    fetchPrData();
    fetchRcemDepartmentsData();
    fetchProgramsDataProto();
    fetchPpicIntegrationModulesData();
    fetchDepartmentTemplatesProtoData();
    fetchTaskListsTodayData();
    fetchTaskListsNextDayData();
    fetchHandoversData();
  }, [filters]);

  const fetchDepartmentTemplatesProtoData = async () => {
    const d = await fetchDepartmentTemplatesProto({
      apiKey: ctx?.apiKey ?? "",
    });
    setDepartmentTemplatesProto(d);
  };

  const fetchTaskListsTodayData = async () => {
    const d = await fetchWorkOrdersProtoMongoDay({
      apiKey: ctx?.apiKey ?? "",
      date: makeDateString(new Date()),
    });
    setTaskListsJobToday(d);
  };
  const fetchTaskListsNextDayData = async () => {
    const d = await fetchWorkOrdersProtoMongoDay({
      apiKey: ctx?.apiKey ?? "",
      date: makeDateString(new Date(new Date().getTime() + 86400000)),
    });
    setTaskListsJobNextDay(d);
  };

  const fetchRcemDepartmentsData = async () => {
    setRcemDepartments(
      await fetchRcemDepartmentsProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const fetchProgramsDataProto = async () => {
    const d = await fetchMachinePrograms({ apiKey: ctx?.apiKey ?? "" });
    if (d) {
      setMachineProgramsProto(d);
    }
  };

  const fetchPanelCodesWorkOrderData = async (job: PpicJob) => {
    const d = await fetchWorkOrdersProtoMongo({
      apiKey: ctx?.apiKey ?? "",
      extPanelCodeIds:
        job.panelCodes.map((c) => c.masterJavaBaseModel?.id) ?? [],
    });

    setPanelCodeWos(d);
  };

  const fetchPpicIntegrationModulesData = async () => {
    setIntegrationModules(
      await fetchIntegrationModulesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const fetchJobsData = async () => {
    setLoading(true);
    const j = await fetchJobsDataProtoCompose({
      apiKey: ctx?.apiKey ?? "",
      closed: filters.jobClosed,
      individualEntityId: filters.customer,
      category: filters.category,
      priority: filters.priority,
    });
    setJobs(j);

    setLoading(false);
  };

  const fetchWosByJobsData = async (jobs: PpicJobOverviews) => {
    setWorkOrdersLoading(true);
    setWorkOrders(
      await fetchWorkOrdersProto({
        extJobIds: jobs.jobs
          .filter((j) => j.job?.masterJavaBaseModel?.id)
          .map((j) =>
            isNaN(parseInt(j.job?.masterJavaBaseModel?.id ?? ""))
              ? null
              : parseInt(j.job?.masterJavaBaseModel?.id ?? "")
          )
          .filter((j) => j),
      })
    );
    setWorkOrdersLoading(false);
  };

  const fetchPrData = async () => {
    setPrLoading(true);
    setExtPurchaseRequests(
      await fetchExtPurchaseRequestsItems({ apiKey: ctx?.apiKey ?? "" })
    );
    setPrLoading(false);
  };
  const fetchJobOverviewHiddenColsData = async () => {
    setjobOverviewHiddenCols(
      await fetchJobOverviewHiddenCols({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchCustomersData = async () => {
    setCustomers(await fetchCustomersProto({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchExtInventoryData = async () => {
    setInventoryLoading(true);
    setInventory(await fetchExtInventory({ apiKey: ctx?.apiKey ?? "" }));
    setInventoryLoading(false);
  };

  const fetchItemsData = async () => {
    setItemsLoading(true);
    setItems(await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" }));
    setItemsLoading(false);
  };

  const fetchPpicBomLeveledsData = async () => {
    const d = await fetchBomLeveledsRecursiveProto({ apiKey: ctx?.apiKey ?? "" })

    if(d){
    setBomLeveleds(
      d
    );}
  };

  const fetchDepartmentTemplatesData = async () => {
    setDepartmentTemplates(
      await fetchDepartmentTemplatesView({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchPurchaseOrderSupplierData = async () => {
    setExtPurchaseOrderSupplier(
      await fetchPurchaseOrderSupplier({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const foundJob = jobs.jobs.find(
    (jx) => `${jx.job?.masterJavaBaseModel?.id}` === `${selectedJobId}`
  );

  const jobBoms = (
    foundJob?.job?.jobBomLeveleds
      .filter((jbl) => !blacklistedItems.includes(`${jbl.bomLeveledId}`))
      .map((jbl) => {
        const found = bomLeveleds.bomLeveleds.find(
          (bl) => `${bl.masterJavaBaseModel?.id}` === `${jbl.bomLeveledId}`
        );

        // Flatten qty
        return found
          ? {
              ...found,
              qty:
                (found.qty && found.qty !== 0 ? found.qty : 1) *
                (jbl.qty && jbl.qty !== 0 ? jbl.qty : 1),
            }
          : undefined;
      }) ?? []
  )
    .filter((jbl) => jbl)
    .map((jbl) => jbl as PpicBomLeveled);

  const flattenBomItems = (bl?: PpicBomLeveled | null): PpicBomLeveled[] =>
    bl
      ? [
          ...(bl.type == "Component" ? [bl] : []),
          // Flatten qty
          ...(bl.children
            .map((blx) =>
              flattenBomItems({
                ...blx,
                qty:
                  (blx.qty && blx.qty !== 0 ? blx.qty : 1) *
                  (bl.qty && bl.qty !== 0 ? bl.qty : 1),
              })
            )
            .flat() ?? []),
        ]
      : [];

  const jobItems = jobBoms
    .map((jbl) => {
      const flattened = flattenBomItems(jbl);
      console.log("[flattened]", flattened);
      return flattened;
    })
    .flat();

  const jobItemsUnique = [
    ...new Set(
      jobItems
        .map((ji) => ji.extItemId)
        .filter((ji) => ji)
        .map((ji) => ji as string)
    ),
  ].map((ji) => {
    return {
      item: items.items.find((i) => `${i.id}` === `${ji}`),
      qty: jobItems
        .filter((jix) => `${jix.extItemId}` === `${ji}`)
        .reduce((acc, ji) => (acc ?? 0) + (ji.qty ?? 0), 0),
    };
  });

  console.log("gspe user JTI", parseJwt(ctx?.apiKey ?? "")?.jti);
  const foundGspeUserId = ssoUsers.ssoUserEntities
    .find((ue) =>
      ue.ssoUserAppEntity.find(
        (uae) =>
          `${uae.ssoAppEntity?.name}` === `ppic` &&
          `${uae.extUserId}` ===
            `${(() => {
              try {
                return parseJwt(ctx?.apiKey ?? "")?.jti;
              } catch (e) {
                return null;
              }
            })()}`
      )
    )
    ?.ssoUserAppEntity.find(
      (uae) => `${uae.ssoAppEntity?.name}` === `gspe`
    )?.extUserId;
  const foundExtUser = foundGspeUserId
    ? ctx?.extUsers.find((u) => `${u.id}` === `${foundGspeUserId}`)
    : undefined;

  console.log("found gspe user", foundGspeUserId, foundExtUser);

  const MapJob = (props: { jobsData: PpicJobOverview[] }) => (
    <>
      {(() => {
        const jobsData = props.jobsData;

        const priorityParse = (prio: string | null | undefined) => {
          switch (prio?.toLowerCase()) {
            case "high":
              return 3;
            case "medium":
              return 2;
            case "low":
              return 1;
            default:
              return 0;
          }
        };

        switch (filterBy) {
          case "Deadline":
            jobs.jobs.sort((a, b) =>
              filterAsc
                ? a.job?.deliveryDeadline?.localeCompare(
                    b.job?.deliveryDeadline ?? ""
                  ) ?? 0
                : b.job?.deliveryDeadline?.localeCompare(
                    a.job?.deliveryDeadline ?? ""
                  ) ?? 0
            );
            break;

          case "Priority":
            jobs.jobs.sort((a, b) =>
              filterAsc
                ? priorityParse(a.job?.priority) -
                  priorityParse(b.job?.priority)
                : priorityParse(b.job?.priority) -
                  priorityParse(a.job?.priority)
            );
            break;
          case "Customer":
            jobs.jobs.sort((a, b) =>
              filterAsc
                ? (
                    customers.entities.find(
                      (e) =>
                        `${e.masterJavaBaseModel?.id}` ===
                        `${a.job?.individualEntityId}`
                    )?.name ?? ""
                  )?.localeCompare(
                    customers.entities.find(
                      (e) =>
                        `${e.masterJavaBaseModel?.id}` ===
                        `${b.job?.individualEntityId}`
                    )?.name ?? ""
                  )
                : (
                    customers.entities.find(
                      (e) =>
                        `${e.masterJavaBaseModel?.id}` ===
                        `${b.job?.individualEntityId}`
                    )?.name ?? ""
                  )?.localeCompare(
                    customers.entities.find(
                      (e) =>
                        `${e.masterJavaBaseModel?.id}` ===
                        `${a.job?.individualEntityId}`
                    )?.name ?? ""
                  )
            );
          case "Job":
            jobs.jobs.sort((a, b) =>
              filterAsc
                ? a.job?.name?.localeCompare(b.job?.name ?? "") ?? 0
                : b.job?.name?.localeCompare(a.job?.name ?? "") ?? 0
            );
            break;

            break;
        }

        return jobsData;
      })().map((j, i_) => {
        return (
          <>
            <tr>
              {(() => {
                return [
                  {
                    head: "#",
                    content: (
                      <>
                        {" "}
                        <td className="border border-dark">
                          <div className="d-flex align-items-center">
                            <div> {i_ + 1}</div>
                            <div>
                              {taskListsJobToday.taskLists.find(
                                (tL) =>
                                  `${tL.taskList?.extJobId}` ===
                                  `${j.job?.masterJavaBaseModel?.id}`
                              ) ? (
                                <div
                                  style={{ backgroundColor: "lightsalmon" }}
                                  className="px-1 py-0"
                                >
                                  <small>
                                    <strong>WO</strong>
                                  </small>
                                </div>
                              ) : taskListsJobNextDay.taskLists.find(
                                  (tL) =>
                                    `${tL.taskList?.extJobId}` ===
                                    `${j.job?.masterJavaBaseModel?.id}`
                                ) ? (
                                <div
                                  style={{ backgroundColor: "khaki" }}
                                  className="px-1 py-0"
                                >
                                  <small>
                                    <strong>WO</strong>
                                  </small>
                                </div>
                              ) : (
                                ``
                              )}
                            </div>
                          </div>
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Customer",
                    content: (
                      <>
                        {" "}
                        <td className="border border-dark">
                          <div
                            style={{
                              maxWidth: 150,
                            }}
                          >
                            {
                              customers.entities.find(
                                (e) =>
                                  `${e.masterJavaBaseModel?.id}` ===
                                  `${j.job?.individualEntityId}`
                              )?.name
                            }
                          </div>
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Job",
                    content: (
                      <>
                        {" "}
                        <td className="border border-dark text-primary">
                          {" "}
                          <div
                            style={{
                              cursor: "pointer",
                              maxWidth: 150,
                            }}
                            onClick={async () => {
                              setSelectedJobId(j.job?.masterJavaBaseModel?.id);
                              setShowPanelModal(true);

                              const [jobsData, wosData] = await Promise.all([
                                await fetchJob({
                                  id: isNaN(
                                    parseInt(
                                      j.job?.masterJavaBaseModel?.id ?? ""
                                    )
                                  )
                                    ? 0
                                    : parseInt(
                                        j.job?.masterJavaBaseModel?.id ?? ""
                                      ),
                                  apiKey: ctx?.apiKey ?? "",
                                }),
                                await fetchWorkOrdersProto({
                                  apiKey: ctx?.apiKey ?? "",
                                  extJobId: j.job?.masterJavaBaseModel?.id,
                                }),
                              ]);

                              setSelectedJobFull(jobsData);
                              setSelectedJobWos(wosData);
                            }}
                          >
                            {j?.job?.shortName && j?.job?.shortName !== ""
                              ? j?.job?.shortName
                              : j?.job?.name}
                          </div>{" "}
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Product",
                    content: (() => {
                      const types = [
                        ...new Set(
                          j.job?.panelCodes.map((c) => {
                            const type = c.type;

                            const numOfTypes = j.job?.panelCodes
                              .filter((c) => `${c.type}` === `${type}`)
                              .reduce((acc, c) => acc + (c.qty ?? 0), 0);

                            return `${type} (${numOfTypes})`;
                          })
                        ),
                      ];

                      return (
                        <>
                          <td className="border border-dark">
                            <small>
                              <strong>{types.join(",")}</strong>
                            </small>
                          </td>
                        </>
                      );
                    })(),
                  },
                  {
                    head: "Qty",
                    content: (
                      <>
                        {" "}
                        <td
                          className="border border-dark"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {" "}
                          <div>
                            {(() => {
                              const closed = j.job?.panelCodes
                                .filter((pc) => pc.closed)
                                .reduce((acc, pc) => (pc.qty ?? 0) + acc, 0);
                              const open = j.job?.panelCodes.reduce(
                                (acc, pc) => (pc.qty ?? 0) + acc,
                                0
                              );

                              const totalSNs: {
                                type: "auto" | "manual";
                                snManual?: PpicPanelCodeSerialNumberManual;
                                sn?: PpicPanelCodeSerialNumber;
                              }[] = [
                                ...(j.job?.panelCodes
                                  .flatMap((c) => c.serialNumbers)
                                  .map((s) => ({
                                    type: "auto" as "auto" | "manual",
                                    sn: s,
                                  })) ?? []),

                                ...(j.job?.panelCodes
                                  .flatMap((c) => c.serialNumberManuals)
                                  .map((s) => ({
                                    type: "manual" as "auto" | "manual",
                                    snManual: s,
                                  })) ?? []),
                              ];

                              const totalSNDoneHandover = totalSNs.filter((s) =>
                                s.type === "auto"
                                  ? handoverQAStatusFromJSON(
                                      handovers.current.handovers
                                        .flatMap((h) => h.items)
                                        .find(
                                          (i) =>
                                            `${i.panelCodeSerialNumberId}` ===
                                            `${s.sn?.masterJavaBaseModel?.id}`
                                        )?.qaStatus ??
                                        HandoverQAStatus.UNRECOGNIZED
                                    ) === HandoverQAStatus.QA_PASSED
                                  : s.type === "manual"
                                  ? handoverQAStatusFromJSON(
                                      handovers.current.handovers
                                        .flatMap((h) => h.items)
                                        .find(
                                          (i) =>
                                            `${i.panelCodeSerialNumberManualId}` ===
                                            `${s.snManual?.masterJavaBaseModel?.id}`
                                        )?.qaStatus ??
                                        HandoverQAStatus.UNRECOGNIZED
                                    ) === HandoverQAStatus.QA_PASSED
                                  : false
                              );

                              return (
                                <>
                                  <div
                                    style={{
                                      backgroundColor: getChromaColor(
                                        totalSNDoneHandover.length /
                                          totalSNs.length
                                      ),
                                    }}
                                  >
                                    <strong>
                                      {(open ?? 0) - (closed ?? 0)}
                                    </strong>{" "}
                                    / {closed} / {open}
                                  </div>
                                </>
                              );
                            })()}
                          </div>{" "}
                        </td>
                      </>
                    ),
                  },

                  {
                    head: "Deadline",
                    content: (
                      <>
                        <td
                          className="border border-dark"
                          style={{
                            backgroundColor:
                              j.job?.deliveryDeadline &&
                              j.job.deliveryDeadline <=
                                makeDateString(new Date())
                                ? `lightsalmon`
                                : ``,
                          }}
                        >
                          {(() => {
                            try {
                              return makeReadableDateStringIntlDateOnlyUtc(
                                new Date(`${j.job?.deliveryDeadline}T00:00:00Z`)
                              );
                            } catch (e) {
                              return "invalid";
                            }
                          })()}
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Status",
                    content: (
                      <>
                        <td className="border border-dark">
                          {j.job?.closed ? "Closed" : "Open"},{" "}
                          {j.job?.closingDate}
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Last QA",
                    content: (
                      <>
                        <td className="border border-dark">
                          {(() => {
                            const lastSN = (() => {
                              const sns = [
                                ...(j.job?.panelCodes.flatMap((c) =>
                                  c.serialNumbers.map((s) => ({
                                    panelCode: c,
                                    sn: s,
                                    mode: "auto",
                                  }))
                                ) ?? []),
                                ...(j.job?.panelCodes.flatMap((c) =>
                                  c.serialNumberManuals.map((s) => ({
                                    panelCode: c,
                                    snManual: s,
                                    mode: "manual",
                                  }))
                                ) ?? []),
                              ] as {
                                panelCode: PpicPanelCode;
                                sn?: PpicPanelCodeSerialNumber;
                                snManual?: PpicPanelCodeSerialNumberManual;
                                mode: string;
                              }[];

                              sns.sort((a, b) => {
                                const aDate = a.sn
                                  ? a.sn.masterJavaBaseModel?.createdAt
                                  : a.snManual
                                  ? a.snManual.masterJavaBaseModel?.createdAt
                                  : null;

                                const bDate = b.sn
                                  ? b.sn.masterJavaBaseModel?.createdAt
                                  : b.snManual
                                  ? b.snManual.masterJavaBaseModel?.createdAt
                                  : null;

                                return bDate?.localeCompare(aDate ?? "") ?? 0;
                              });

                              return sns;
                            })()?.[0];

                            const date = lastSN?.sn?.masterJavaBaseModel
                              ?.createdAt
                              ? lastSN?.sn?.masterJavaBaseModel?.createdAt
                              : lastSN?.snManual?.masterJavaBaseModel
                                  ?.createdAt;

                            const sn = lastSN?.sn?.fullName
                              ? lastSN.sn.fullName
                              : lastSN?.snManual?.serialNumber;

                            const code = `${lastSN?.panelCode.type ?? ""}:${
                              lastSN?.panelCode.name ?? ""
                            }`;

                            return (
                              <>
                                <small>
                                  <strong>
                                    {(() => {
                                      try {
                                        return Intl.DateTimeFormat("en-US", {
                                          dateStyle: "short",
                                          timeStyle: "short",
                                        } as any).format(new Date(date ?? ""));
                                      } catch (e) {
                                        return "";
                                      }
                                    })()}
                                    :{sn}:{code}
                                  </strong>
                                </small>
                              </>
                            );
                          })()}
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Priority",
                    content: (
                      <>
                        <td className="border border-dark">
                          <div
                            className={`font-weight-bold text-light p-1 ${(() => {
                              switch (j.job?.priority?.toLowerCase()) {
                                case "high":
                                  return `bg-danger`;
                                case "medium":
                                  return `bg-warning`;
                                case "low":
                                  return `bg-success`;
                                default:
                                  return "";
                              }
                            })()}`}
                          >
                            {j.job?.priority}
                          </div>
                        </td>
                      </>
                    ),
                  },
                  // {
                  //   head: "No. of WOs",
                  //   content: (
                  //     <>
                  //       <td className="border border-dark">
                  //         {j.wos?.done}/{j.wos?.total}
                  //       </td>
                  //     </>
                  //   ),
                  // },
                  // {
                  //   head: "No. of Tasks",
                  //   content: (
                  //     <>
                  //       <td className="border border-dark">
                  //         {j.tasks?.done}/{j.tasks?.total}
                  //       </td>
                  //     </>
                  //   ),
                  // },
                  {
                    head: "Last Status",
                    content: (
                      <>
                        <td className="border border-dark">
                          {(() => {
                            const lastPlanDateSorted = j.job?.panelCodes
                              .map((pc) => pc.departmentTemplateItems ?? [])
                              .flat()
                              .filter(
                                (dti) =>
                                  dti.departmentTemplateItemId &&
                                  dti.departmentTemplateItemId !== "0"
                              );

                            lastPlanDateSorted?.sort(
                              (a, b) =>
                                (b.completedDate
                                  ? new Date(b.completedDate).getTime()
                                  : 0) -
                                (a.completedDate
                                  ? new Date(a.completedDate).getTime()
                                  : 0)
                            );

                            const lastPlanDeptTemplateItem =
                              (lastPlanDateSorted?.length ?? 0) > 0
                                ? lastPlanDateSorted?.[0]
                                : null;

                            return (
                              <>
                                {/* {JSON.stringify(lastPlanDeptTemplateItem)} */}
                                <div>
                                  {(() => {
                                    try {
                                      return (
                                        <>
                                          <div>
                                            <div>
                                              {makeReadableDateStringIntlDateOnlyUtc(
                                                new Date(
                                                  lastPlanDeptTemplateItem?.completedDate ??
                                                    ""
                                                )
                                              )}
                                            </div>
                                            <div>
                                              {departmentTemplates
                                                ?.map(
                                                  (dt) =>
                                                    dt.departmentTemplateItems ??
                                                    []
                                                )
                                                .flat()
                                                .find(
                                                  (dti) =>
                                                    `${dti.departmentTemplateItem?.id}` ===
                                                    `${lastPlanDeptTemplateItem?.departmentTemplateItemId}`
                                                )
                                                ?.departmentTemplateItem?.name?.substring(
                                                  0,
                                                  6
                                                )}{" "}
                                              (
                                              {departmentTemplates
                                                ?.find((dt) =>
                                                  dt.departmentTemplateItems.find(
                                                    (dti) =>
                                                      `${dti.departmentTemplateItem?.id}` ===
                                                      lastPlanDeptTemplateItem?.departmentTemplateItemId
                                                  )
                                                )
                                                ?.departmentTemplate?.name?.substring(
                                                  0,
                                                  6
                                                )}
                                              )
                                            </div>
                                          </div>
                                        </>
                                      );
                                    } catch (e) {
                                      return "invalid";
                                    }
                                  })()}
                                </div>
                              </>
                            );
                          })()}
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Last Plan",
                    content: (
                      <>
                        <td className="border border-dark">
                          {(() => {
                            const lastPlanDateSorted = j.job?.panelCodes
                              .map((pc) => pc.departmentTemplateItems ?? [])
                              .flat()
                              .filter(
                                (dti) =>
                                  dti.departmentTemplateItemId &&
                                  dti.departmentTemplateItemId !== "0" &&
                                  dti.endDate &&
                                  dti.endDate !== ""
                              );

                            lastPlanDateSorted?.sort(
                              (a, b) =>
                                (b.endDate
                                  ? new Date(b.endDate).getTime()
                                  : 0) -
                                (a.endDate ? new Date(a.endDate).getTime() : 0)
                            );

                            const lastPlanDeptTemplateItem =
                              (lastPlanDateSorted?.length ?? 0) > 0
                                ? lastPlanDateSorted?.[0]
                                : null;

                            console.log(
                              j.job?.name,
                              j.job?.panelCodes,
                              lastPlanDateSorted,
                              lastPlanDeptTemplateItem
                            );

                            return (
                              <>
                                {/* {JSON.stringify(lastPlanDeptTemplateItem)} */}
                                <div>
                                  {(() => {
                                    try {
                                      return (
                                        <>
                                          <div>
                                            <div>
                                              {makeReadableDateStringIntlDateOnlyUtc(
                                                new Date(
                                                  lastPlanDeptTemplateItem?.endDate ??
                                                    ""
                                                )
                                              )}
                                            </div>
                                            <div>
                                              {departmentTemplates
                                                ?.map(
                                                  (dt) =>
                                                    dt.departmentTemplateItems ??
                                                    []
                                                )
                                                .flat()
                                                .find(
                                                  (dti) =>
                                                    `${dti.departmentTemplateItem?.id}` ===
                                                    `${lastPlanDeptTemplateItem?.departmentTemplateItemId}`
                                                )
                                                ?.departmentTemplateItem?.name?.substring(
                                                  0,
                                                  6
                                                )}{" "}
                                              (
                                              {departmentTemplates
                                                ?.find((dt) =>
                                                  dt.departmentTemplateItems.find(
                                                    (dti) =>
                                                      `${dti.departmentTemplateItem?.id}` ===
                                                      lastPlanDeptTemplateItem?.departmentTemplateItemId
                                                  )
                                                )
                                                ?.departmentTemplate?.name?.substring(
                                                  0,
                                                  6
                                                )}
                                              )
                                            </div>
                                          </div>
                                        </>
                                      );
                                    } catch (e) {
                                      return "invalid";
                                    }
                                  })()}
                                </div>
                              </>
                            );
                          })()}
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Man Days",
                    content: (
                      <>
                        <td className="border border-dark">
                          {workOrdersLoading ? (
                            <Spinner animation="border" />
                          ) : (
                            <>
                              {(() => {
                                const wos = workOrders.taskLists.filter(
                                  (tL) =>
                                    `${tL.taskList?.extJobId}` ===
                                    `${j.job?.masterJavaBaseModel?.id}`
                                );
                                const totalHours = wos.reduce(
                                  (acc, tL) =>
                                    acc +
                                    getWorkOrderTimeMinutes(
                                      tL.taskList,
                                      rcemDepartments,
                                      integrationModules,
                                      machineProgramsProto,
                                      departmentTemplatesProto,
                                      null,
                                      null,
                                      null
                                    ).totalHours,
                                  0.0
                                );

                                return totalHours;
                              })().toFixed(1)}{" "}
                              h
                            </>
                          )}
                        </td>
                      </>
                    ),
                  },
                  // {
                  //   head: "Comments",
                  //   content: (
                  //     <>
                  //       <td className="border border-dark"></td>
                  //     </>
                  //   ),
                  // },
                  {
                    head: "MR Simulation",
                    content: (
                      <>
                        <td className="border border-dark">
                          <div>
                            <div>
                              <small>
                                {(j.job?.jobBomLeveleds.length ?? 0) > 0 ? (
                                  <>{j.job?.jobBomLeveleds.length} BOM(s)</>
                                ) : (
                                  <></>
                                )}
                              </small>
                            </div>
                            <div>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={async () => {
                                  setSelectedJobId(
                                    j.job?.masterJavaBaseModel?.id
                                  );
                                  setshowShortageModal(true);

                                  // Fetch job material requests
                                  setJobMaterialRequestsLoading(true);

                                  setJobMaterialRequests(
                                    await fetchMaterialRequestsFiltered({
                                      apiKey: ctx?.apiKey ?? "",
                                      jobId:
                                        j.job?.masterJavaBaseModel?.id ?? "",
                                    })
                                  );

                                  setJobMaterialRequestsLoading(false);
                                }}
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Shortage List",
                    content: (
                      <>
                        <td className="border border-dark">
                          <div>
                            <div>
                              <small>
                                {(j.job?.jobBomLeveleds.length ?? 0) > 0 ? (
                                  <>{j.job?.jobBomLeveleds.length} BOM(s)</>
                                ) : (
                                  <></>
                                )}
                              </small>
                            </div>
                            <div>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={async () => {
                                  setSelectedJobId(
                                    j.job?.masterJavaBaseModel?.id
                                  );
                                  setshowActualShortageModal(true);
                                }}
                              >
                                View
                              </button>
                            </div>
                          </div>{" "}
                        </td>
                      </>
                    ),
                  },
                  {
                    head: "Job Comments",
                    content: (
                      <>
                        {" "}
                        <td className="border border-dark">
                          <button
                            className="btn btn-sm btn-outline-primary"
                            onClick={async () => {
                              if (j.job) {
                                setPanelCodeCommentSelectedJob(j.job);
                                await fetchPanelCodesWorkOrderData(j.job);
                              }
                              setShowCommentsDialog(true);
                            }}
                          >
                            WO
                          </button>
                          <button
                            className="btn btn-sm btn-outline-primary"
                            onClick={async () => {
                              if (j.job) {
                                setPanelCodeRemarkSelectedJob(j.job);
                              }
                              setShowPanelCodeRemarkDialog(true);
                            }}
                          >
                            PanelRemarks (
                            {j.job?.panelCodes
                              .map((c) => c.remarks.length)
                              .reduce((acc, r) => acc + r, 0) ?? 0}
                            )
                          </button>
                        </td>
                      </>
                    ),
                  },
                ];
              })()
                .filter((h) =>
                  foundExtUser?.departmentId
                    ? !jobOverviewHiddenCols.hiddenCols.find(
                        (c) =>
                          `${c.extDepartmentId}` ===
                            `${foundExtUser?.departmentId}` && c.name === h.head
                      )
                    : true
                )
                .map((e) => {
                  return <>{e.content}</>;
                })}

              {/* <td className="border border-dark"></td> */}
            </tr>
          </>
        );
      })}
    </>
  );

  const getJobsTodayCriteria = (j: PpicJobOverview) =>
    taskListsJobToday.taskLists.find(
      (tL) => `${tL.taskList?.extJobId}` === `${j.job?.masterJavaBaseModel?.id}`
    ) &&
    !taskListsJobNextDay.taskLists.find(
      (tL) => `${tL.taskList?.extJobId}` === `${j.job?.masterJavaBaseModel?.id}`
    );

  const getJobsTomorrowCriteria = (j: PpicJobOverview) =>
    !taskListsJobToday.taskLists.find(
      (tL) => `${tL.taskList?.extJobId}` === `${j.job?.masterJavaBaseModel?.id}`
    ) &&
    taskListsJobNextDay.taskLists.find(
      (tL) => `${tL.taskList?.extJobId}` === `${j.job?.masterJavaBaseModel?.id}`
    );

  return (
    <>
      <div className="m-3">
        <pre>
          <small>{JSON.stringify(jobItems)}</small>
        </pre>
        <div className="d-flex">
          {loading ? <Spinner animation="border" /> : <></>}
          <h4>Job Overview</h4>
          <div>
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                fetchWosByJobsData(jobs);
              }}
            >
              Get man days
            </button>
          </div>
          <div className="mx-2 flex-grow-1">
            <Select
              options={[
                { label: "All", value: null },
                { label: "Outstanding", value: false },
                { label: "Closed", value: true },
              ]}
              //  getOptionLabel={(c) => `${c.name}`}
              placeholder="Status..."
              onChange={(v) => {
                const val = v as unknown as { value: boolean };
                setFilters({ ...filters, jobClosed: val.value });
              }}
            />
          </div>
          <div className="mx-2 flex-grow-1">
            <Select
              options={customers.entities ?? []}
              getOptionLabel={(c) => `${c.name}`}
              placeholder="Customer..."
              onChange={(c) => {
                const val = c as PpicIndividualEntity;

                setFilters({
                  ...filters,
                  customer: val.masterJavaBaseModel?.id,
                });
              }}
            />
          </div>
          <div className="mx-2 flex-grow-1">
            <Select
              options={jobDeptCategories}
              getOptionLabel={(c) => `${c.label}`}
              placeholder="Job Category..."
              onChange={(c) => {
                const val = c as { label: string; value: JobDeptCategory };

                setFilters({
                  ...filters,
                  category: val.value,
                });
              }}
            />
          </div>
          <div className="mx-2 flex-grow-1">
            <Select
              options={[
                { label: "All", value: null },
                { label: "High", value: "High" },
                { label: "Medium", value: "Medium" },
                { label: "Low", value: "Low" },
              ]}
              placeholder="Job Priority..."
              onChange={(v) => {
                const val = v as unknown as { label: string; value: string };

                setFilters({
                  ...filters,
                  priority: val.value,
                });
              }}
            />
          </div>
        </div>
        <hr className="border border-dark" />

        <div className="d-flex flex-wrap">
          {hiddenOverviewFields?.map((f) => {
            return (
              <>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    setHiddenOverviewFields(
                      hiddenOverviewFields.filter((fx) => fx !== f)
                    );
                    await set(
                      "hiddenOverviewFields",
                      hiddenOverviewFields.filter((fx) => fx !== f)
                    );
                  }}
                  className="border border-dark rounded rounded-md px-2 py-1 mx-2"
                >
                  <div>{f}</div>
                </div>
              </>
            );
          })}
        </div>

        <div>
          <strong>
            Detected user: {foundExtUser?.name} ({foundExtUser?.username})
          </strong>
        </div>

        <div>
          <strong>Detected dept: {foundExtUser?.departmentName} </strong>
        </div>

        <>
          <div
            className="overflow-auto border border-dark"
            style={{ height: "75vh", resize: "vertical" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <thead>
                <tr>
                  {jobOverviewHeaders
                    .filter((h) =>
                      foundExtUser?.departmentId
                        ? !jobOverviewHiddenCols.hiddenCols.find(
                            (c) =>
                              `${c.extDepartmentId}` ===
                                `${foundExtUser?.departmentId}` &&
                              c.name === h.name
                          )
                        : true
                    )
                    .map((h) => (
                      <th
                        className="bg-dark text-light"
                        style={{
                          cursor: "pointer",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        <div>
                          <div>{h.name}</div>
                          <div className="d-flex justify-content-between">
                            {/* <div>
                              <button
                                onClick={async () => {
                                  if (
                                    hiddenOverviewFields &&
                                    !hiddenOverviewFields.find(
                                      (f) => f === h.name
                                    )
                                  ) {
                                    await set("hiddenOverviewFields", [
                                      ...hiddenOverviewFields,
                                      h.name,
                                    ]);
                                    setHiddenOverviewFields([
                                      ...hiddenOverviewFields,
                                      h.name,
                                    ]);
                                  }
                                }}
                                className="btn btn-secondary btn-sm"
                              >
                                Hide
                              </button>
                            </div> */}
                            {h.sortable ? (
                              <>
                                {" "}
                                <div>
                                  <button
                                    onClick={() => {
                                      console.log(h.name);
                                      switch (h.name) {
                                        case "Deadline":
                                          setFilterBy("Deadline");
                                          setFilterAsc(!filterAsc);
                                          break;
                                        case "Job":
                                          setFilterBy("Job");
                                          setFilterAsc(!filterAsc);
                                          break;
                                        case "Priority":
                                          setFilterBy("Priority");
                                          setFilterAsc(!filterAsc);
                                          break;
                                        case "Customer":
                                          setFilterBy("Customer");
                                          setFilterAsc(!filterAsc);
                                          break;
                                        default:
                                          break;
                                      }
                                    }}
                                    className="btn btn-primary btn-sm"
                                  >
                                    Sort
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                <MapJob jobsData={jobs.jobs} />
              </tbody>
            </table>
          </div>
        </>
        <Modal
          size="xl"
          show={panelCodeRemarkDialog}
          onHide={() => {
            setShowPanelCodeRemarkDialog(false);
          }}
        >
          <ModalHeader>
            <div>
              <h4>Job remarks: {panelCodeRemarkSelectedJob?.name}</h4>
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              className="overflow-auto"
              style={{ height: "75vh", resize: "vertical" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                {["#", "Code", "Remark", "Done", "Created"].map((h) => (
                  <>
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                ))}
                {panelCodeRemarkSelectedJob?.panelCodes
                  .map((c) =>
                    c.remarks.map((r) => ({ panelCode: c, remark: r }))
                  )
                  .flat()
                  .map((d, i) => {
                    return (
                      <>
                        <tr>
                          <td className="border border-dark">{i + 1}</td>
                          <td className="border border-dark">
                            {d.panelCode.type}:{d.panelCode.name}:
                            {d.panelCode.qty} unit(s)
                          </td>
                          <td className="border border-dark">
                            {d.remark.remark}
                          </td>
                          <td className="border border-dark">
                            <input type="checkbox" checked={d.remark.done} />
                          </td>
                          <td className="border border-dark">
                            {(() => {
                              try {
                                return Intl.DateTimeFormat("en-US", {
                                  dateStyle: "short",
                                  timeStyle: "medium",
                                  hourCycle: "h23",
                                } as any).format(
                                  new Date(
                                    d?.remark.masterJavaBaseModel?.createdAt ??
                                      ""
                                  )
                                );
                              } catch (e) {
                                return "";
                              }
                            })()}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </table>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size="xl"
          show={showCommentsDialog}
          onHide={() => {
            setShowCommentsDialog(false);
          }}
        >
          <ModalHeader>
            <div>
              <h4>Job Comments: {panelCodeCommentSelectedJob.name}</h4>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div
                className="overflow-auto"
                style={{ height: "75vh", resize: "vertical" }}
              >
                <table>
                  <tr>
                    {[
                      "#WOID",
                      "#TaskID",
                      "Timestamp",
                      "Panel",
                      "User",
                      "Task Desc",
                      "Comment",
                    ].map((h) => (
                      <>
                        <th
                          className="bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      </>
                    ))}
                  </tr>
                  {(() => {
                    const arr = panelCodeWos.taskLists
                      .map((tL) =>
                        tL.taskList?.meetingTasks
                          .map((mt) =>
                            mt.meetingTaskComments.map((c) => ({
                              taskList: tL,
                              meetingTask: mt,
                              comment: c,
                            }))
                          )
                          .flat()
                      )
                      .flat()
                      .filter((d) => d?.comment);

                    arr.reverse();

                    return arr;
                  })().map((d, i) => {
                    return (
                      <>
                        <tr>
                          <td className="border border-dark">
                            #{d?.taskList.taskList?.masterJavaBaseModel?.id}
                          </td>
                          <td className="border border-dark">
                            #{d?.meetingTask?.masterJavaBaseModel?.id}
                          </td>

                          <td
                            className="border border-dark"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {(() => {
                              try {
                                return Intl.DateTimeFormat("en-US", {
                                  dateStyle: "short",
                                  timeStyle: "medium",
                                  hourCycle: "h23",
                                } as any).format(
                                  new Date(
                                    d?.comment?.masterJavaBaseModel
                                      ?.createdAt ?? ""
                                  )
                                );
                              } catch (e) {
                                return "";
                              }
                            })()}
                          </td>
                          <td className="border border-dark">
                            {(() => {
                              const fcode = jobs.jobs
                                .map((j) => j.job?.panelCodes)
                                .flat()
                                .find(
                                  (cx) =>
                                    `${cx?.masterJavaBaseModel?.id}` ===
                                    `${d?.taskList.taskList?.extPanelCodeId}`
                                );

                              if (!fcode) {
                                return <></>;
                              }

                              return `${fcode.type}:${fcode.name}:${fcode.qty} unit(s)`;
                            })()}
                          </td>
                          <td className="border border-dark">
                            {
                              ctx?.extUsers.find(
                                (u) => `${u.id}` === `${d?.comment?.extUserId}`
                              )?.username
                            }
                          </td>
                          <td className="border border-dark">
                            {d?.meetingTask.description}
                          </td>
                          <td className="border border-dark">
                            {d?.comment.comment}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size="xl"
          show={showPanelModal}
          onHide={() => {
            setShowPanelModal(false);
          }}
        >
          <ModalHeader>
            <div>
              <div>
                <h4>Job Detail</h4>
              </div>
              <div>
                <small>#{selectedJobId}</small>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="d-flex">
              {(["Panel", "Batch"] as ("Panel" | "Batch")[]).map((h) => (
                <>
                  <div>
                    <button
                      onClick={() => {
                        jobDetailViewType.current = h;
                        render();
                      }}
                      className={`btn btn-sm ${
                        jobDetailViewType.current === h
                          ? `btn-primary`
                          : `btn-outline-primary`
                      }`}
                    >
                      {h}
                    </button>
                  </div>
                </>
              ))}
            </div>
            {jobDetailViewType.current === "Panel" ? (
              <>
                {(() => {
                  const foundJob = jobs.jobs.find(
                    (j) =>
                      `${j.job?.masterJavaBaseModel?.id}` === `${selectedJobId}`
                  );

                  const qty = foundJob?.job?.panelCodes.reduce(
                    (acc, pc) => acc + (pc.qty ?? 0),
                    0
                  );

                  const closed = (
                    [
                      ...(foundJob?.job?.panelCodes.flatMap((c) =>
                        c.serialNumbers.map((s) => ({
                          mode: "auto",
                          sn: s,
                        }))
                      ) ?? []),
                      ...(foundJob?.job?.panelCodes.flatMap((c) =>
                        c.serialNumberManuals.map((s) => ({
                          mode: "manual",
                          snManual: s,
                        }))
                      ) ?? []),
                    ] as {
                      mode: string;
                      sn?: PpicPanelCodeSerialNumber;
                      snManual?: PpicPanelCodeSerialNumberManual;
                    }[]
                  ).filter((s) => {
                    switch (s.mode) {
                      case "auto":
                        return (
                          handoverQAStatusFromJSON(
                            handovers.current.handovers
                              .flatMap((h) => h.items)
                              .find(
                                (h) =>
                                  `${h.panelCodeSerialNumberId}` ===
                                  `${s.sn?.masterJavaBaseModel?.id}`
                              )?.qaStatus
                          ) === HandoverQAStatus.QA_PASSED
                        );
                      case "manual":
                        return (
                          handoverQAStatusFromJSON(
                            handovers.current.handovers
                              .flatMap((h) => h.items)
                              .find(
                                (h) =>
                                  `${h.panelCodeSerialNumberManualId}` ===
                                  `${s.snManual?.masterJavaBaseModel?.id}`
                              )?.qaStatus
                          ) === HandoverQAStatus.QA_PASSED
                        );
                      default:
                        return true;
                    }
                  }).length;

                  const open = (qty ?? 0) - (closed ?? 0);

                  return (
                    <>
                      <div>
                        <div>
                          <div>Job: {foundJob?.job?.name}</div>
                          <div>
                            <strong>
                              Products: {qty}, SN:{" "}
                              {
                                [
                                  ...(foundJob?.job?.panelCodes.flatMap((c) =>
                                    c.serialNumbers.map((s) => ({
                                      mode: "auto",
                                      sn: s,
                                    }))
                                  ) ?? []),
                                  ...(foundJob?.job?.panelCodes.flatMap((c) =>
                                    c.serialNumberManuals.map((s) => ({
                                      mode: "manual",
                                      snManual: s,
                                    }))
                                  ) ?? []),
                                ].length
                              }
                              , closed:
                              {closed}, open: {open}
                            </strong>
                          </div>
                          <div
                            className="overflow-auto"
                            style={{ resize: "vertical", height: "60vh" }}
                          >
                            <table
                              className="table table-sm"
                              style={{ borderCollapse: "separate" }}
                            >
                              <thead>
                                <tr>
                                  {[
                                    "#",
                                    "Name",
                                    // "Closed",
                                    "Program Tree",
                                    "Product Tree",
                                    "Qty",
                                    "Deadline",
                                    // "WOs",

                                    // "Tasks",
                                    // "Last Status",
                                    // "Last Plan",
                                  ].map((h) => {
                                    return (
                                      <>
                                        <th
                                          className="bg-dark text-light"
                                          style={{ position: "sticky", top: 0 }}
                                        >
                                          {h}
                                        </th>
                                      </>
                                    );
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {(() => {
                                  const mapJob = (
                                    pc: PpicPanelCode,
                                    i: number
                                  ) => {
                                    const flattenedModules = flattenModules(
                                      selectedJobFull?.job.jobIntegrationList?.filter(
                                        (jIL) =>
                                          `${jIL.jobIntegrationList?.panelCode?.id}` ===
                                          `${pc.masterJavaBaseModel?.id}`
                                      )
                                    );

                                    const programTreeDone =
                                      selectedJobWos?.taskLists
                                        .filter(
                                          (tL) =>
                                            `${tL.taskList?.extPanelCodeId}` ===
                                              `${pc.masterJavaBaseModel?.id}` &&
                                            tL.taskList?.extProgramId &&
                                            tL.taskList?.extProgramId !== "0"
                                          // pc.machinePrograms.find(
                                          //   (mp) =>
                                          //     `${mp.masterJavaBaseModel?.id}` ===
                                          //     `${tL.taskList?.extProgramId}`
                                          // )
                                        )
                                        .reduce(
                                          (acc, tL) =>
                                            acc +
                                            (tL.taskList
                                              ?.extProgramRunQtyActual ?? 0),
                                          0
                                        ) ?? 0;
                                    const programTreeNeeded =
                                      pc.machinePrograms.reduce(
                                        (acc, mp) => acc + (mp.qtyRun ?? 0),
                                        0
                                      );

                                    const productTreeDone =
                                      selectedJobWos?.taskLists
                                        .filter(
                                          (tL) =>
                                            `${tL.taskList?.extPanelCodeId}` ===
                                              `${pc.masterJavaBaseModel?.id}` &&
                                            ((tL.taskList?.extSetId &&
                                              tL.taskList?.extSetId !== "0") ||
                                              (tL.taskList?.extModuleId &&
                                                tL.taskList?.extModuleId !==
                                                  "0") ||
                                              (tL.taskList?.extSubModuleId &&
                                                tL.taskList?.extSubModuleId !==
                                                  "0"))
                                        )
                                        .reduce(
                                          (acc, tL) =>
                                            acc +
                                            (tL.taskList
                                              ?.extIntegrationModuleQtyActual ??
                                              0),
                                          0
                                        ) ?? 0;
                                    const productTreeNeeded =
                                      flattenedModules.length;

                                    return (
                                      <>
                                        <tr>
                                          <td className="border border-dark">
                                            <div>{i + 1}</div>
                                          </td>
                                          <td className="border border-dark">
                                            <div>
                                              {pc.type} {pc.name}
                                            </div>
                                          </td>
                                          {/* <td
                                        className={`${"border border-dark"} ${
                                          pc.closed
                                            ? "text-danger"
                                            : "text-success"
                                        }`}
                                      >
                                        <div>
                                          {pc.closed ? "Closed" : "Open"}
                                        </div>
                                      </td> */}
                                          <td
                                            className="border border-dark"
                                            style={{
                                              backgroundColor: progressColor(
                                                programTreeDone,
                                                programTreeNeeded
                                              ),
                                            }}
                                          >
                                            <small>
                                              <strong>
                                                {programTreeDone}/
                                                {programTreeNeeded} (
                                                {programTreeNeeded > 0
                                                  ? (
                                                      (programTreeDone /
                                                        programTreeNeeded) *
                                                      100
                                                    ).toFixed(1)
                                                  : 0}
                                                %)
                                              </strong>
                                            </small>
                                          </td>
                                          <td
                                            className="border border-dark"
                                            style={{
                                              backgroundColor: progressColor(
                                                productTreeDone,
                                                productTreeNeeded
                                              ),
                                            }}
                                          >
                                            <small>
                                              <strong>
                                                {productTreeDone}/
                                                {productTreeNeeded} (
                                                {productTreeNeeded > 0
                                                  ? (
                                                      (productTreeDone /
                                                        productTreeNeeded) *
                                                      100
                                                    ).toFixed(1)
                                                  : 0}
                                                %)
                                              </strong>
                                            </small>
                                          </td>
                                          <td className="border border-dark">
                                            <div>{pc.qty}</div>
                                          </td>

                                          <td className="border border-dark">
                                            <div>
                                              {(() => {
                                                try {
                                                  return (
                                                    <>
                                                      {makeReadableDateStringIntlDateOnlyUtc(
                                                        new Date(
                                                          `${pc.deadline}T00:00:00Z`
                                                        )
                                                      )}
                                                    </>
                                                  );
                                                } catch (e) {
                                                  return <>Invalid</>;
                                                }
                                              })()}
                                            </div>
                                          </td>
                                        </tr>
                                        {(
                                          [
                                            ...pc.serialNumbers.map((sn) => ({
                                              type: "auto",
                                              sn: sn,
                                            })),
                                            ...pc.serialNumberManuals.map(
                                              (sn) => ({
                                                type: "manual",
                                                snManual: sn,
                                              })
                                            ),
                                          ] as {
                                            type: "auto" | "manual";
                                            snManual?: PpicPanelCodeSerialNumberManual;
                                            sn?: PpicPanelCodeSerialNumber;
                                          }[]
                                        ).map((sn) => {
                                          const flippedHandover = [
                                            ...handovers.current.handovers,
                                          ];
                                          flippedHandover.reverse();

                                          const foundHandover =
                                            flippedHandover.find(
                                              (h) =>
                                                h.items.find((i) =>
                                                  sn.type === "auto"
                                                    ? `${i.panelCodeSerialNumberId}` ===
                                                      `${sn.sn?.masterJavaBaseModel?.id}`
                                                    : sn.type === "manual"
                                                    ? `${i.panelCodeSerialNumberManualId}` ===
                                                      `${sn.snManual?.masterJavaBaseModel?.id}`
                                                    : false
                                                ) &&
                                                ppicHandover_StatusFromJSON(
                                                  h.status
                                                ) ===
                                                  PpicHandover_Status.APPROVED
                                            );

                                          const foundHandoverItem =
                                            flippedHandover
                                              .flatMap((h) => h.items)
                                              .find((i) =>
                                                sn.type === "auto"
                                                  ? `${i.panelCodeSerialNumberId}` ===
                                                    `${sn.sn?.masterJavaBaseModel?.id}`
                                                  : sn.type === "manual"
                                                  ? `${i.panelCodeSerialNumberManualId}` ===
                                                    `${sn.snManual?.masterJavaBaseModel?.id}`
                                                  : false
                                              );

                                          return (
                                            <>
                                              <tr>
                                                <td className="border border-dark"></td>

                                                <td
                                                  className={`border border-dark`}
                                                >
                                                  <div className="d-flex">
                                                    <strong
                                                      className={`${
                                                        handoverQAStatusFromJSON(
                                                          foundHandoverItem?.qaStatus
                                                        ) ===
                                                        HandoverQAStatus.QA_PASSED
                                                          ? `text-success`
                                                          : `text-danger`
                                                      }`}
                                                    >
                                                      {sn.type === "auto"
                                                        ? getPanelCodeCompleteSerialNumberProto(
                                                            pc,
                                                            sn.sn
                                                          )
                                                        : sn.snManual
                                                            ?.serialNumber}{" "}
                                                    </strong>
                                                    {handoverQAStatusFromJSON(
                                                      foundHandoverItem?.qaStatus
                                                    ) ===
                                                    HandoverQAStatus.QA_PASSED ? (
                                                      <>
                                                        <div>
                                                          <div className="bg-success text-light">
                                                            <small>
                                                              <strong>
                                                                QA Passed{" "}
                                                                {(() => {
                                                                  try {
                                                                    return Intl.DateTimeFormat(
                                                                      "en-US",
                                                                      {
                                                                        dateStyle:
                                                                          "medium",
                                                                        // timeStyle:
                                                                        //   "short",
                                                                      } as any
                                                                    ).format(
                                                                      new Date(
                                                                        foundHandoverItem?.qaUserCheckedTimestamp ??
                                                                          ""
                                                                      )
                                                                    );
                                                                  } catch (e) {
                                                                    return "";
                                                                  }
                                                                })()}
                                                              </strong>
                                                            </small>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : handoverQAStatusFromJSON(
                                                        foundHandoverItem?.qaStatus
                                                      ) ===
                                                      HandoverQAStatus.QA_NOT_PASSED ? (
                                                      <>
                                                        <div className="bg-danger text-light">
                                                          <small>
                                                            <strong>
                                                              QA Not Passed
                                                            </strong>
                                                          </small>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        <div className="bg-dark text-light">
                                                          <small>
                                                            <strong>
                                                              QA Outs
                                                            </strong>
                                                          </small>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </td>
                                                <td className="border border-dark"></td>
                                                <td className="border border-dark"></td>
                                                <td className="border border-dark"></td>
                                                <td className="border border-dark"></td>
                                                <td className="border border-dark"></td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </>
                                    );
                                  };

                                  return [
                                    ...(foundJob?.job?.panelCodes
                                      .filter((pc) => !pc.closed)
                                      .map((pc, i) => {
                                        return mapJob(pc, i);
                                      }) ?? []),
                                    ...(foundJob?.job?.panelCodes
                                      .filter((pc) => pc.closed)
                                      .map((pc, i) => {
                                        return mapJob(pc, i);
                                      }) ?? []),
                                  ];
                                })()}
                              </tbody>
                            </table>
                          </div>
                          <ol></ol>
                        </div>
                      </div>
                    </>
                  );
                })()}
              </>
            ) : (
              <></>
            )}
            {jobDetailViewType.current === "Batch" ? (
              <>
                <div>
                  <div>No. of batches: {foundJob?.job?.batchCount}</div>
                  <div>
                    <div>
                      <table
                        className="table table-sm"
                        style={{ borderCollapse: "separate" }}
                      >
                        <tr>
                          {[...Array(foundJob?.job?.batchCount ?? 0)]
                            .map((_, i) => i + 1)
                            .map((n) => {
                              return (
                                <>
                                  <th className="bg-dark text-light p-0 m-0">
                                    {n}
                                  </th>
                                </>
                              );
                            })}
                        </tr>
                        <tr>
                          {[...Array(foundJob?.job?.batchCount ?? 0)]
                            .map((_, i) => i + 1)
                            .map((n) => {
                              const foundPCodes =
                                foundJob?.job?.panelCodes.filter(
                                  (c) => `${c.batch}` === `${n}`
                                );
                              return (
                                <>
                                  <td className="border border-dark p-0 m-0">
                                    <ol>
                                      {foundPCodes?.map((c) => {
                                        return (
                                          <>
                                            <li>
                                              <div>
                                                <div>
                                                  {c.type}:{c.name}
                                                </div>
                                              </div>
                                            </li>
                                          </>
                                        );
                                      })}
                                    </ol>
                                  </td>
                                </>
                              );
                            })}
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </ModalBody>
        </Modal>
        <Modal
          size="xl"
          show={showCreatePrModal}
          onHide={() => {
            setshowCreatePrModal(false);
          }}
        >
          {(() => {
            const filteredPrs = prInfo.filter((pr) => pr.remaining > 0);
            return (
              <>
                <ModalHeader>
                  <h4>Create PR</h4>
                </ModalHeader>
                <ModalBody>
                  <div
                    className="overflow-auto"
                    style={{ height: "65vh", resize: "vertical" }}
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {["#", "ID", "P/N", "PartDesc", "Qty PR"].map((h) => (
                          <>
                            <th
                              className="bg-dark text-light"
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h}
                            </th>
                          </>
                        ))}
                      </tr>
                      {(() => {
                        return filteredPrs.map((pr, i) => {
                          return (
                            <tr>
                              <td className="border border-dark">{i + 1}</td>
                              <td className="border border-dark">
                                {pr.jobItemUnique.item?.id}
                              </td>
                              <td className="border border-dark">
                                {pr.jobItemUnique.item?.partNum}
                              </td>
                              <td className="border border-dark">
                                <div>
                                  <div>{pr.jobItemUnique.item?.partName}</div>
                                  <div>{pr.jobItemUnique.item?.partDesc}</div>
                                </div>
                              </td>
                              <td className="border border-dark">
                                {pr.remaining}
                              </td>
                            </tr>
                          );
                        });
                      })()}
                    </table>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={async () => {
                      try {
                        if (
                          window.confirm(
                            `Confirm create PR for job ${
                              jobs.jobs.find(
                                (j) =>
                                  `${j.job?.masterJavaBaseModel?.id}` ===
                                  `${selectedJobId}`
                              )?.job?.name
                            }?`
                          )
                        ) {
                          const newPr: ExtPurchaseRequestPostBodyJson = {
                            ...initialExtPurchaseRequestPostBodyJson,
                            prDate: new Date(
                              `${makeDateString(new Date())}T00:00:00Z`
                            )
                              .toISOString()
                              .split("T")[0],
                            prNumber: `PR/PPIC/${new Date().getFullYear()}/${
                              new Date().getMonth() + 1 < 10
                                ? `0${new Date().getMonth() + 1}`
                                : `${new Date().getMonth() + 1}`
                            }/${
                              new Date().getDate() < 10
                                ? `0${new Date().getDate()}`
                                : `${new Date().getDate()}`
                            }/`,
                            jobId: selectedJobId,
                            extPurchaseRequestPostBodyItemsJson:
                              filteredPrs.map((i) => ({
                                ...initialExtPurchaseRequestPostBodyItemJson,
                                productId: i.jobItemUnique.item?.id
                                  ? isNaN(
                                      parseInt(`${i.jobItemUnique.item?.id}`)
                                    )
                                    ? 0
                                    : parseInt(`${i.jobItemUnique.item?.id}`)
                                  : 0,
                                qtyPr: i.remaining,
                                jobId: selectedJobId,
                              })),
                          };

                          try {
                            const resp = await fetch(
                              `${ctx?.baseUrl}/ext-purchase-requests-save`,
                              {
                                method: "post",
                                headers: {
                                  authorization: ctx?.apiKey ?? "",
                                  "content-type": "application/json",
                                },
                                body: JSON.stringify(newPr),
                              }
                            );

                            if (resp.status !== 200) throw await resp.text();
                          } catch (e) {
                            console.error(e);
                          }
                        }
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                  >
                    Create PR
                  </button>
                </ModalFooter>
              </>
            );
          })()}
        </Modal>
        <Modal
          size="xl"
          show={showShortageModal}
          onHide={() => {
            setBlacklistedItems([]);
            setshowShortageModal(false);
          }}
        >
          <ModalHeader>
            <h4>Shortage List</h4>
          </ModalHeader>
          <ModalBody>
            {(() => {
              const foundJob = jobs.jobs.find(
                (jx) =>
                  `${selectedJobId}` === `${jx.job?.masterJavaBaseModel?.id}`
              );
              return (
                <>
                  {" "}
                  <div>
                    Shortage for job: {foundJob?.job?.masterJavaBaseModel?.id}{" "}
                    {foundJob?.job?.name}
                  </div>
                  <hr />
                  <strong>Not included:</strong>
                  <div className="">
                    <ol>
                      {blacklistedItems.map((bl) => {
                        const foundBomLeveled = bomLeveleds.bomLeveleds.find(
                          (jblx) => jblx && jblx.masterJavaBaseModel?.id === bl
                        );
                        console.log("[found bom level]", foundBomLeveled);
                        return (
                          <>
                            <li>
                              <div>
                                <div
                                  className="p-1 m-1 border border-dark rounded rounded-md"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setBlacklistedItems(
                                      blacklistedItems.filter(
                                        (blx) => `${blx}` !== `${bl}`
                                      )
                                    );
                                  }}
                                >
                                  {foundBomLeveled?.name ?? ""}
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ol>
                  </div>
                  <hr />
                  <strong>Included:</strong>
                  <div>
                    <div>
                      <ol>
                        {foundJob?.job?.jobBomLeveleds
                          .filter(
                            (jbl) =>
                              !blacklistedItems.includes(`${jbl.bomLeveledId}`)
                          )
                          .map((jbl, i) => {
                            const foundBomLeveled =
                              bomLeveleds.bomLeveleds.find(
                                (jblx) =>
                                  jblx &&
                                  jblx.masterJavaBaseModel?.id ===
                                    jbl.bomLeveledId
                              );
                            return (
                              <>
                                <li>
                                  <div
                                    className="p-1 my-1 border border-dark rounded rounded-md"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setBlacklistedItems([
                                        ...blacklistedItems,
                                        jbl.bomLeveledId ?? "",
                                      ]);
                                    }}
                                  >
                                    <div>
                                      {foundBomLeveled?.name}: x{jbl.qty}
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                      </ol>
                    </div>
                  </div>
                  <div
                    className="overflow-auto border border-dark"
                    style={{ height: "65vh" }}
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      <thead>
                        <tr>
                          {[
                            "#",
                            "Item",
                            "Qty",
                            "In MR",
                            "In stock",
                            "Free Stock",
                            "Remaining",
                          ].map((h) => (
                            <th
                              className="bg-dark text-light"
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {jobItemsUnique.map((ji, i) => {
                          const filteredInventory = inventory.filter(
                            (i) => `${i.products?.id}` === `${ji.item?.id}`
                          );
                          const filteredInStock = filteredInventory.reduce(
                            (acc, inv) => (acc ?? 0) + (inv.qty ?? 0),
                            0
                          );
                          const filteredFreeStock = filteredInventory.reduce(
                            (acc, inv) => (acc ?? 0) + (inv.qtyBalance ?? 0),
                            0
                          );
                          const filteredMrs =
                            jobMaterialRequests.materialRequests
                              .map(
                                (mr) =>
                                  mr.items.map((mri) => ({
                                    mr: mr,
                                    item: mri,
                                  })) ?? []
                              )
                              .flat()
                              .filter(
                                (mri) =>
                                  `${mri.item.extItemId}` === `${ji.item?.id}`
                              );
                          const qtyInMr = filteredMrs.reduce(
                            (acc, mr) => (acc ?? 0) + (mr.item.qty ?? 0),
                            0
                          );
                          const remaining = (ji.qty ?? 0) - qtyInMr;
                          const remainingView = remaining < 0 ? 0 : remaining;
                          return (
                            <>
                              <tr>
                                <td className="border border-dark">{i + 1}</td>
                                <td className="border border-dark">
                                  {itemsLoading ? (
                                    <Spinner animation="border" />
                                  ) : (
                                    <>
                                      <div>
                                        <div>
                                          {ji.item?.partNum}:{" "}
                                          {ji.item?.partName}
                                        </div>
                                        <div>
                                          <small>
                                            <small>{ji.item?.partDesc}</small>
                                          </small>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td className="border border-dark">
                                  {ji.qty?.toFixed(1)} {ji.item?.defaultUm}
                                </td>
                                <td className="border border-dark">
                                  {jobMaterialRequestsLoading ? (
                                    <>
                                      {" "}
                                      <Spinner animation="border" />
                                    </>
                                  ) : (
                                    <>
                                      <div>
                                        <div>{qtyInMr}</div>
                                        <div>
                                          <small>
                                            {filteredMrs.map((m, i) => {
                                              return (
                                                <>
                                                  <div>
                                                    {i + 1}. #{m.mr?.id}
                                                  </div>
                                                </>
                                              );
                                            })}
                                          </small>
                                        </div>{" "}
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td className="border border-dark">
                                  {inventoryLoading ? (
                                    <>
                                      {" "}
                                      <Spinner animation="border" />
                                    </>
                                  ) : (
                                    <>
                                      <div>{filteredInStock}</div>
                                    </>
                                  )}
                                </td>
                                <td className="border border-dark">
                                  {inventoryLoading ? (
                                    <>
                                      {" "}
                                      <Spinner animation="border" />
                                    </>
                                  ) : (
                                    <>
                                      <div>{filteredFreeStock}</div>
                                    </>
                                  )}
                                </td>
                                <td
                                  className={`border border-dark text-light font-weight-bold ${
                                    remainingView > 0
                                      ? `bg-danger`
                                      : `bg-success`
                                  }`}
                                >
                                  {remainingView.toFixed(1)}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })()}
          </ModalBody>
        </Modal>
        <Modal
          size="xl"
          show={showActualShortageModal}
          onHide={() => {
            setBlacklistedItems([]);
            setshowActualShortageModal(false);
          }}
        >
          <ModalHeader>
            <h4>Shortage List</h4>
          </ModalHeader>
          <ModalBody>
            {(() => {
              const foundJob = jobs.jobs.find(
                (jx) =>
                  `${selectedJobId}` === `${jx.job?.masterJavaBaseModel?.id}`
              );
              return (
                <>
                  {" "}
                  <div>
                    Shortage for job {foundJob?.job?.masterJavaBaseModel?.id}:{" "}
                    {foundJob?.job?.name}
                  </div>
                  <hr />
                  <strong>Not included:</strong>
                  <div className="">
                    <ol>
                      {blacklistedItems.map((bl) => {
                        const foundBomLeveled = bomLeveleds.bomLeveleds.find(
                          (jblx) => jblx && jblx.masterJavaBaseModel?.id === bl
                        );
                        console.log("[found bom level]", foundBomLeveled);
                        return (
                          <>
                            <li>
                              <div>
                                <div
                                  className="p-1 m-1 border border-dark rounded rounded-md"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setBlacklistedItems(
                                      blacklistedItems.filter(
                                        (blx) => `${blx}` !== `${bl}`
                                      )
                                    );
                                  }}
                                >
                                  {foundBomLeveled?.name ?? ""}
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ol>
                  </div>
                  <hr />
                  <strong>Included:</strong>
                  <div>
                    <div>
                      <ol>
                        {foundJob?.job?.jobBomLeveleds
                          .filter(
                            (jbl) =>
                              !blacklistedItems.includes(`${jbl.bomLeveledId}`)
                          )
                          .map((jbl, i) => {
                            const foundBomLeveled =
                              bomLeveleds.bomLeveleds.find(
                                (jblx) =>
                                  jblx &&
                                  jblx.masterJavaBaseModel?.id ===
                                    jbl.bomLeveledId
                              );
                            return (
                              <>
                                <li>
                                  <div
                                    className="p-1 my-1 border border-dark rounded rounded-md"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setBlacklistedItems([
                                        ...blacklistedItems,
                                        jbl.bomLeveledId ?? "",
                                      ]);
                                    }}
                                  >
                                    <div>
                                      {foundBomLeveled?.name}: x{jbl.qty}
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                      </ol>
                    </div>
                  </div>
                  <div
                    className="overflow-auto border border-dark"
                    style={{ height: "65vh" }}
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      <thead>
                        <tr>
                          {[
                            "#",
                            "Item",
                            "Qty",
                            // "In MR",
                            "In Stock",
                            "Free Stock",
                            "PO Supplier Outs",
                            "PR",

                            <>
                              <div>
                                <div>Remaining</div>
                                <div className="d-flex">
                                  <div>
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={() => {
                                        setRemainingFilter("OK");
                                      }}
                                    >
                                      OK
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        setRemainingFilter("Prob");
                                      }}
                                    >
                                      Prob
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        setRemainingFilter("All");
                                      }}
                                    >
                                      All
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>,
                            <>
                              <div>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => {
                                    setshowCreatePrModal(true);
                                    setPrInfo(
                                      getPurchaseRequestInfo(
                                        jobItemsUnique,
                                        inventory,
                                        extPurchaseOrderSupplier,
                                        extPurchaseRequests,
                                        selectedJobId
                                      )
                                    );
                                  }}
                                >
                                  Create PR
                                </button>
                              </div>
                            </>,
                          ].map((h) => (
                            <th
                              className="bg-dark text-light"
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {getPurchaseRequestInfo(
                          jobItemsUnique,
                          inventory,
                          extPurchaseOrderSupplier,
                          extPurchaseRequests,
                          selectedJobId
                        ).map((ji, i) => {
                          const ToView = () => (
                            <tr>
                              <td className="border border-dark">{i + 1}</td>
                              <td className="border border-dark">
                                {itemsLoading ? (
                                  <Spinner animation="border" />
                                ) : (
                                  <>
                                    <div>
                                      <div>
                                        {ji.jobItemUnique.item?.partNum}:{" "}
                                        {ji.jobItemUnique.item?.partName}
                                      </div>
                                      <div>
                                        <small>
                                          <small>
                                            {ji.jobItemUnique.item?.partDesc}
                                          </small>
                                        </small>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </td>
                              <td className="border border-dark">
                                {ji.jobItemUnique.qty.toFixed(1)}{" "}
                                {ji.jobItemUnique.item?.defaultUm}
                              </td>
                              {/* <td className="border border-dark"></td> */}
                              <td className="border border-dark">
                                {ji.filteredInStock.toFixed(1)}
                              </td>
                              <td className="border border-dark">
                                {ji.filteredFreeStock.toFixed(1)}
                              </td>
                              <td className="border border-dark">
                                {ji.inPoSupplier.toFixed(1)}
                              </td>
                              <td className="border border-dark">
                                {prLoading ? (
                                  <>
                                    <Spinner animation="border" />
                                  </>
                                ) : (
                                  <>{ji.inPr.toFixed(1)}</>
                                )}
                              </td>
                              <td
                                className={`border border-dark text-light font-weight-bold ${
                                  ji.remaining <= 0 ? `bg-success` : `bg-danger`
                                }`}
                              >
                                {ji.remaining <= 0 ? (
                                  <>{(0).toFixed(1)}</>
                                ) : (
                                  <>{ji.remaining.toFixed(1)}</>
                                )}
                              </td>
                              <td className="border border-dark">
                                {ji.remaining.toFixed(1)}
                                {/* <input
                                  placeholder="Qty..."
                                  defaultValue={ji.remaining}
                                  className="form-control form-control-sm"
                                  style={{ width: 75 }}
                                /> */}
                              </td>
                            </tr>
                          );

                          switch (remainingFilter) {
                            case "All":
                              return (
                                <>
                                  <ToView />
                                </>
                              );
                            case "OK":
                              return ji.remaining > 0 ? (
                                <></>
                              ) : (
                                <>
                                  <ToView />
                                </>
                              );
                            case "Prob":
                              return ji.remaining > 0 ? (
                                <>
                                  <ToView />
                                </>
                              ) : (
                                <></>
                              );
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })()}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default JobOverviewPage;
