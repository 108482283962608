import { Box, responsiveFontSizes, styled, TableCell } from "@material-ui/core";
import { blue, green, orange, red, yellow } from "@material-ui/core/colors";
import { PanoramaSharp, PartyModeSharp } from "@material-ui/icons";
import { exists } from "fs";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4, v4 } from "uuid";
import { IAppState } from "../App";
import PahoMQTT from "paho-mqtt";
import {
  AuthserverDepartmentGroups,
  AuthserverShiftTemplates,
  AuthserverUserShifts,
  BomItemType,
  CrmBoqItems,
  CrmBoqs,
  CrmBPOs,
  CrmCustomers,
  CrmDJOs,
  CrmPOOutstandings,
  CrmPurchaseOrders,
  JobExtDepartmentTemplates,
  KpiDeptCache,
  KpiDeptCacheCategoryEnum,
  KpiDeptCacheCategoryRangeEnum,
  MasterJavaBaseModel,
  MeetingKpi,
  MeetingLastPlanDate,
  MeetingMasterTemplatePresets,
  MeetingMeetingTask,
  MeetingMeetingTaskComments,
  MeetingMeetingTaskInCharge,
  MeetingMeetingTaskListTemplate,
  MeetingMeetingTaskListTemplates,
  MeetingMeetingTaskProblemCatalog,
  MeetingMeetingTaskTargetDate,
  MeetingProblemCatalog,
  MeetingProblemCatalogs,
  meetingProblemTypeToJSON,
  MeetingProject,
  MeetingProjectMasterTemplates,
  MeetingProjects,
  MeetingSites,
  MeetingTaskList,
  meetingTaskListChangeLog_MeetingTaskListChangeLogTypeFromJSON,
  MeetingTaskListsView,
  MeetingTaskListView,
  PpicAutoMRWarehouseFilters,
  PpicBomLeveled,
  PpicBomLeveledGroup,
  PpicBomLeveledGroups,
  PpicBomLeveleds,
  PpicDeliveryNote,
  PpicDeliveryNotes,
  PpicDepartmentTemplates,
  PpicExtDepartmentHiddenJobOverviewCols,
  PpicHandover,
  PpicHandover_Status,
  PpicHandovers,
  PpicHandoverType,
  PpicHolidays,
  PpicIndividualEntities,
  PpicIndividualEntity,
  PpicIntegrationModules,
  PpicJob,
  PpicJob_PpicJobDeptCategory,
  PpicJobInfoList,
  PpicJobOverviews,
  PpicJobs,
  PpicMachineProgramMachine,
  PpicMachineProgramMachines,
  PpicMachinePrograms,
  PpicMachines,
  PpicMacroCategories,
  PpicManpowerAssistances,
  PpicMaterialRequestsSimple,
  PpicMRAuthorizationDefaults,
  PpicNewJobNotifications,
  PpicPanelCode,
  PpicPanelCodeDepartmentTemplateItems,
  PpicPanelCodeDepartmentTemplatePresets,
  PpicPanelCodes,
  PpicPanelCodeSerialNumber,
  PpicPartNumberCustomerMatches,
  PpicPartNumberMatches,
  PpicPartNumberMatchGroups,
  PpicRcemDepartments,
  PpicRcemProcessSkills,
  PpicRcemSubProcess,
  PpicRcemSubProcesses,
  PpicRcemSubProcessSkills,
  PpicRemoteControlPayload,
  PpicRemoteControlPreset,
  PpicRemoteControlPresets,
  PpicSimplifiedProcessTypeMultipliers,
  PpicSimplifiedProcessTypes,
  PpicSkill,
  PpicSkills,
  PpicSkillWorker_SkillLevel,
  ppicSkillWorker_SkillLevelToNumber,
  PpicWorkingHours,
  PurchasingPurchaseRequestItemsOutstanding,
  PurchasingPurchaseRequests,
  PurchasingSupplierPurchaseOrders,
  SsoUser,
  SsoUserEntitiesData,
  WarehouseItem,
  WarehouseItems,
} from "../masterbigsystem";
import {
  BaseModel,
  BomLeveledRecursive,
  BomType,
  Deadline,
  DeadlineDetail,
  DepartmentTemplateView,
  ExtCustomer,
  ExtDepartment,
  ExtInventory,
  ExtItem,
  ExtItemReq,
  ExtMaterialRequestItem,
  ExtOrganization,
  ExtPrItemOutstanding,
  ExtProjectProductPanelTemplate,
  ExtProjectSimple,
  ExtProjectWorkOrder,
  ExtProjectWorkOrderView,
  ExtPurchaseRequest,
  ExtReserveList,
  ExtUser,
  FepDocument,
  FepLeveled,
  ForProcess,
  IndividualEntity,
  Integration,
  IntegrationModuleView,
  IntegrationView,
  Job,
  JobBomLeveledView,
  JobDeptCategory,
  JobIntegrationListView,
  JobMapped,
  JobType,
  Machine,
  MachineProgram,
  MaterialRequest,
  MaterialType,
  PanelCode,
  PanelCodeSerialNumber,
  PanelTemplate,
  PanelTemplateMechanicalProcessType,
  PanelTemplateMechanicalProcessTypeBlacklist,
  PanelTemplateProcessTypeGeneral,
  PanelTemplateProcessTypeGeneralBlacklist,
  PanelTemplateProcessTypeGeneralItem,
  PanelTemplateProcessTypeGeneralItemBlacklist,
  PanelTemplateProcessTypeGeneralItemSubProcess,
  PanelTemplateProcessTypeGeneralItemSubProcessBlacklist,
  PanelTemplateProcessTypeGeneralItemSubProcessWorker,
  PrItemDetail,
  ProcessType,
  ProcessTypeGeneralItemView,
  ProcessTypeGeneralView,
  Project,
  Ral,
  SimplifiedProcessType,
  User,
  WiringProcessType,
  WiringWorkOrderDoneStatus,
  WorkOrderRev,
  JobBomLeveled,
  ExtApp,
} from "../models/model";
import MeetingTs from "../models/meeting";

import {
  defaultJobIntegrationList,
  initialJob,
  initialUser,
} from "../models/modelinitials";
import { RequestStatus } from "../models/RequestStatus";
import { initialRole } from "../models/Role";
import { createTextChangeRange } from "typescript";
import {
  AttendanceNote,
  AttendanceNoteType,
  LocationRecord,
  MeetingTaskProblemCatalog,
  ProblemCatalog,
  UserFirstCheckIn,
} from "../models/meeting";
import { aliases, KpiRange } from "../components/RcemKpiPage/RcemKpiPage";
import chroma from "chroma-js";
import { ByCreatedDateType } from "../components/Operations/Handover/HandoverPage";

export enum JobTypeSelection {
  Cabinet = "Cabinet",
  Wiring = "Wiring",
}

export enum JobPriority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export function makeDateString(date: Date) {
  const y = `${date.getFullYear()}`;
  const m =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return `${y}-${m}-${d}`;
}
export function makeTimeString(date: Date) {
  return `${
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`
  }:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`
  }`;
}

export const days = [
  "Minggu",
  "Senin",
  "Selasa",
  "Rabu",
  "Kamis",
  "Jumat",
  "Sabtu",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const fetchMaterialRequests = async (props: {
  apiKey?: string | null;
  showHidden?: boolean | null;
  extJobId?: any;
  from?: any;
  to?: any;
  id?: any;
}) => {
  try {
    // const url =
    const resp = await fetch(fetchMaterialRequestsUrl(props), {
      headers: {
        authorization: props?.apiKey ?? "",
      },
    });

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as MaterialRequest[];
  } catch (e) {
    return [];
  }
};

export const fetchMaterialRequestsByJobProduct = async (props: {
  apiKey?: string | null;
  jobId?: any;
  extItemId?: any;
  extPurchaseOrderId?: any;
}) => {
  try {
    // const url =
    const resp = await fetch(fetchMaterialRequestsByJobProductUrl(props), {
      headers: {
        authorization: props?.apiKey ?? "",
      },
    });

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as MaterialRequest[];
  } catch (e) {
    return [];
  }
};

export const fetchMaterialRequestsUrl = (props: {
  apiKey?: string | null;
  showHidden?: boolean | null;
  extJobId?: any;
  from?: any;
  to?: any;
  id?: any;
}) => {
  return `${process.env.REACT_APP_BASE_URL}/materialrequests?showHidden=${
    props.showHidden ?? false
  }&extJobId=${props.extJobId ?? ""}&from=${props.from}&to=${props.to}&id=${
    props.id ?? ""
  }`;
};

export const fetchMaterialRequestsByJobProductUrl = (props: {
  apiKey?: string | null;
  jobId?: any;
  extItemId?: any;
  extPurchaseOrderId?: any;
}) => {
  return `${
    process.env.REACT_APP_BASE_URL
  }/materialrequests-by-job-product?jobId=${props.jobId ?? ""}&extItemId=${
    props.extItemId ?? ""
  }&extPurchaseOrderId=${props.extPurchaseOrderId ?? ""}`;
};

export const fetchMaterialRequestsFiltered = async (props: {
  apiKey?: string | null;
  jobId?: any | null;
  hideItems?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/materialrequests-filtered?jobId=${
        props.jobId ?? ""
      }&hideItems=${props.hideItems ?? ""}`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return PpicMaterialRequestsSimple.fromPartial(await resp.json());
  } catch (e) {
    return PpicMaterialRequestsSimple.fromPartial({ materialRequests: [] });
  }
};

export const fetchWorkingHoursProto = async (props: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/workinghours`, {
      headers: {
        authorization: props?.apiKey ?? "",
      },
    });

    if (resp.status !== 200) throw await resp.text();

    return PpicWorkingHours.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchJobExternalDepartmentTemplates = async (props: {
  apiKey?: string | null;
  closed?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/job-ext-dept-templates?closed=${
        props.closed ?? ""
      }`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return JobExtDepartmentTemplates.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchMaterialRequest = async (props: {
  apiKey?: string | null;
  id: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/materialrequests/${props.id}`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as MaterialRequest | null | undefined;
  } catch (e) {
    return null;
  }
};

export const fetchMaterialRequestView = async (props: {
  apiKey?: string | null;
  id: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/materialrequests-view/${props.id}`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as MaterialRequest | null | undefined;
  } catch (e) {
    return null;
  }
};

export const fetchDepartmentTemplates = async (props: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/departmenttemplates`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as PpicDepartmentTemplates | null | undefined;
  } catch (e) {
    return null;
  }
};

export const fetchDepartmentTemplatesView = async (props: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/departmenttemplates-view`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as DepartmentTemplateView[];
  } catch (e) {
    return [];
  }
};
export const fetchPurchaseOrderSupplier = async (props: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-purchase-orders-supplier`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return PurchasingSupplierPurchaseOrders.fromPartial(await resp.json());
  } catch (e) {
    return PurchasingSupplierPurchaseOrders.fromPartial({ purchaseOrders: [] });
  }
};
export const fetchDepartmentTemplatesProto = async (props: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/departmenttemplates-proto`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return PpicDepartmentTemplates.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchJobsDeptProgress = async (props: {
  apiKey?: string | null;
  extJobId?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobs-deptprogress?extJobId=${
        props.extJobId ?? ""
      }`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as Job[];
  } catch (e) {
    return [];
  }
};

export const dateToSecs = (date: Date | null | undefined) => {
  const h = date ? date.getHours() * 3600 : 0;
  const m = date ? date.getMinutes() * 60 : 0;
  const s = date ? date.getSeconds() : 0;
  return h + m + s;
};

export const secondsToHms = (secs: number | null | undefined) => {
  if (secs === null || secs === undefined) {
    return 0;
  } else {
    const hrs = `${Math.floor(secs / 3600)}`;
    const mins = `${Math.floor((secs % 3600) / 60)}`;
    const secsNew = `${(secs % 60).toFixed()}`;

    const hrsParsed = hrs.length > 1 ? hrs : `0${hrs}`;
    const minsParsed = mins.length > 1 ? mins : `0${mins}`;
    const secsParsed = secsNew.length > 1 ? secsNew : `0${secsNew}`;

    return `${hrsParsed}:${minsParsed}:${secsParsed}`;
  }
};

export const deleteBatch = async (path: string, ids: number[]) => {
  return Promise.all(
    ids.map(async (id) => {
      return await fetch(`${process.env.REACT_APP_BASE_URL}/${path}/${id}`, {
        method: "DELETE",
      });
    })
  );
};

export const makeDeadlineData = (
  name: string,
  deadlines: string[],
  jobId: number
): Deadline => {
  const newDeadlineDetails: DeadlineDetail[] = deadlines.map((deadline) => ({
    id: 0,
    uuid: `DeadlineDetail_${uuidv4()}`,
    name: deadline,
    plan: null,
    actual: null,
    deadline: null,
    createdAt: null,
    updatedAt: null,
    createdBy: null,
  }));

  return {
    id: 0,
    uuid: `Deadline_${uuidv4()}`,
    name: name,
    deadlineDetails: newDeadlineDetails,
    job: { ...initialJob, id: jobId },
    createdAt: null,
    updatedAt: null,
    createdBy: null,
  };
};

export const compareDeadlineInterval = (
  date: Date,
  deadlineDatestring: string
) => {
  const dateToCheck = new Date(makeDateString(date)).getTime();
  const deadlineDate = new Date(deadlineDatestring).getTime();

  const millisDay = 86400000; // One day in millis

  if (dateToCheck >= deadlineDate) {
    return red[100];
  } else if (deadlineDate - dateToCheck <= millisDay * 2) {
    // Deadline 2 days left
    return orange[100];
  } else {
    return "white";
  }
};

export const makeReadableDateString = (date: Date) => {
  const y = `${date.getFullYear()}`;
  const m = months[date.getMonth()];
  const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return `${days[date.getDay()]} ${d} ${m} ${y}`;
};

export const intlFormat = (params: {
  date: any;
  dateStyle?: "short" | "medium" | "long" | "full";
  timeStyle?: "short" | "medium" | "long" | "full";
}) => {
  try {
    return Intl.DateTimeFormat("en-US", {
      dateStyle: params.dateStyle,
      timeStyle: params.timeStyle,
    }).format(new Date(params.date));
  } catch (e) {
    return null;
  }
};

export const makeReadableDateStringShortIntl = (date: Date) =>
  Intl.DateTimeFormat("id-ID", {
    dateStyle: "short",
  } as any).format(date);

export const makeReadableDateStringIntl = (date: Date) =>
  Intl.DateTimeFormat(navigator.language ?? "en-US", {
    dateStyle: "full",
  } as any).format(date);

export const makeReadableDateStringIntlUtc = (date: Date) =>
  Intl.DateTimeFormat(navigator.language ?? "en-US", {
    dateStyle: "full",
    timeZone: "utc",
  } as any).format(date);

export const makeReadableDateStringIntlDateOnlyUtc = (date: Date) =>
  Intl.DateTimeFormat(navigator.language ?? "en-US", {
    dateStyle: "long",
    timeZone: "utc",
  } as any).format(date);

export const makeReadableDateStringUTC = (date: Date) => {
  const y = `${date.getUTCFullYear()}`;
  const m = months[date.getUTCMonth()];
  const d =
    date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : `${date.getUTCDate()}`;

  return `${days[date.getUTCDay()]} ${d} ${m} ${y}`;
};

export const makeReadableDateTimeString = (date: Date) => {
  const y = `${date.getFullYear()}`;
  const m = months[date.getMonth()];
  const d = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  const hh =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const mm =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  const ss =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`;

  return `${days[date.getDay()]} ${d} ${m} ${y} ${hh}:${mm}:${ss} ${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }`;
};

export const formatDateTimeIntl = (params: {
  dateStyle?: any;
  timeStyle?: any;
  date?: any;
}) =>
  Intl.DateTimeFormat("en-US", {
    dateStyle: params.dateStyle,
    timeStyle: params.timeStyle,
  }).format(new Date(params.date));

export const makeDateTimeString = (date: Date) => {
  const hh =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;

  const mm =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;

  const ss =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`;

  const hhmmss = `${hh}:${mm}:${ss}`;

  return `${makeDateString(date)}T${hhmmss}`;
};

export const compareJobDateColorstring = (
  closed: boolean,
  date: Date,
  deadline: string
) => {
  const currentTime = date.getTime();
  const deadlineTime = new Date(deadline).getTime();

  if (closed) {
    return green[100];
  } else {
    if (currentTime > deadlineTime) {
      return red[100];
    } else {
      return "white";
    }
  }
};

export const fetchItems = async (
  baseUrl: string,
  apiKey: string
): Promise<Array<ExtItem> | null> => {
  try {
    const response = await fetch(`${baseUrl}/ext-items`, {
      headers: {
        authorization: apiKey ?? "",
      },
    });

    if (response.status !== 200) throw "Error fetching api items";
    return (await response.json()) as Array<ExtItem>;
  } catch (e) {
    return null;
  }
};

export const fetchApps = async (apiKey: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ext-apps`, {
      headers: {
        authorization: apiKey ?? "",
      },
    });

    if (response.status !== 200) throw "Error fetching api apps";
    return (await response.json()) as ExtApp[];
  } catch (e) {
    return null;
  }
};

export const compareDateColor = (
  date: Date,
  deadline: DeadlineDetail | undefined
) => {
  const currentTime = date.getTime();
  const planTime = new Date(deadline?.plan ? deadline.plan : "").getTime();
  const actualTime = new Date(
    deadline?.actual ? deadline.actual : ""
  ).getTime();

  const millisDay = 86400000; // One day in millis

  if (deadline !== undefined) {
    // Deadline is null (unlikely but possible)
    if (deadline.plan === null) {
      // Plan does not exist
      return "black";
    } else if (deadline.plan !== null && deadline.actual !== null) {
      // Plan and Actual exists (complete)
      if (actualTime > planTime) {
        // Complete but overdue
        return orange[200];
      } else {
        // Complete and not not overdue
        return green[100];
      }
    } else if (deadline.plan !== null) {
      // Planned but not completed

      if (currentTime > planTime) {
        // Plan overdues current time
        return red[200];
      } else if (planTime - currentTime <= millisDay * 2) {
        // 2 days left until deadline
        return yellow[300];
      } else {
        return "white";
      }
    }
  } else {
    return "black";
  }
};

export const CheckRequestStatus = (props: { requestStatus: RequestStatus }) => {
  switch (props.requestStatus) {
    case RequestStatus.Loading:
      return <Spinner animation="border" variant="primary" />;

    default:
      return <></>;
  }
};

export const compareAssignedAndNeededQty = (
  assigned: number,
  needed: number
) => {
  if (assigned < needed) {
    return red[200];
  } else if (assigned > needed) {
    return orange[200];
  } else {
    return green[200];
  }
};

export const getUserFromApiKey = (apiKey: string): User => {
  try {
    const splitApiKey = apiKey ? apiKey.split(".")[1] : "";
    const payload = JSON.parse(atob(splitApiKey));

    return {
      ...initialUser,
      id: parseInt(payload.jti),
      name: payload.sub,
      roles: payload.roles.map((roleName: string) => ({
        ...initialRole,
        name: roleName,
      })),
    };
  } catch (e) {
    return {
      ...initialUser,
    };
  }
};

export const TableCellBordered = styled(TableCell)({
  border: "1px solid black",
});

export const CenteredTableCellBordered = (props: {
  colSpan?: number;
  rowSpan?: number;
  content: string;
  textColor?: string;
}) => {
  return (
    <TableCellBordered
      colSpan={props.colSpan ? props.colSpan : 1}
      rowSpan={props.rowSpan ? props.rowSpan : 1}
      style={{ color: props.textColor ? props.textColor : "black" }}
    >
      <Box display="flex" justifyContent="center">
        {props.content}
      </Box>
    </TableCellBordered>
  );
};

export const priorityColor = (priority: string) => {
  switch (priority) {
    case "High":
      return red[600];

    case "Medium":
      return yellow[600];

    case "Low":
      return green[600];

    default:
      return green[600];
  }
};

export const PriorityMap = (props: { priority: string }) => {
  switch (props.priority) {
    case "High":
      return (
        <div
          className="text-light p-1"
          style={{ display: "inline", backgroundColor: "red" }}
        >
          <strong>High</strong>
        </div>
      );

    case "Medium":
      return (
        <div
          className="text-light p-1"
          style={{ display: "inline", backgroundColor: "darkorange" }}
        >
          <strong>Medium</strong>
        </div>
      );

    case "Low":
      return (
        <div
          className="text-light p-1"
          style={{ display: "inline", backgroundColor: "green" }}
        >
          <strong>Low</strong>
        </div>
      );

    default:
      return (
        <div
          className="text-light p-1"
          style={{ display: "inline", backgroundColor: "black" }}
        >
          <strong>Undefined</strong>
        </div>
      );
  }
};
export const fetchMachines = async (params: { apiKey?: string | null }) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/machines`, {
      headers: { authorization: params.apiKey ?? "" },
    });
    if (response.status !== 200) throw await response.text();

    return (await response.json()) as Machine[];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const fetchWorkOrderRevs = async (params: {
  year?: any | null;
  apiKey?: string | null;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/workorderrevs-find?year=${params.year}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return (await response.json()) as WorkOrderRev[];
  } catch (e) {
    console.log(e);
    return [];
  }
};
export const fetchWorkOrdersProto = async (params: {
  apiKey?: string | null;
  extJobId?: any;
  extJobIds?: any[];
  extUserIds?: any[];
  outstanding?: boolean;
  from?: any;
  to?: any;
  filterProductTreeOnly?: boolean;
  filterProgramTreeOnly?: boolean;
  hasCoords?: boolean;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/tasklists-proto?extJobId=${
        params.extJobId !== null && params.extJobId !== undefined
          ? `${params.extJobId}`
          : ``
      }&outstanding=${params.outstanding ?? ""}&from=${params.from ?? ""}&to=${
        params.to ?? ""
      }&filterProductTreeOnly=${
        params.filterProductTreeOnly ?? false
      }&filterProgramTreeOnly=${
        params.filterProgramTreeOnly ?? false
      }&hasCoords=${params.hasCoords ?? ""}&extJobIds=${
        params.extJobIds
          ? encodeURIComponent(JSON.stringify(params.extJobIds ?? []))
          : ""
      }&extUserIds=${
        params.extUserIds ? encodeURI(JSON.stringify(params.extUserIds)) : []
      }`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return MeetingTaskListsView.fromPartial(await response.json());
  } catch (e) {
    console.log(e);
    return MeetingTaskListsView.fromPartial({});
  }
};

export const fetchWorkOrdersProtoMongoDay = async (params: {
  apiKey?: string | null;
  date?: string | null;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/wos-day?date=${params.date ?? ""}`,
      { headers: { authorization: params.apiKey ?? "" } }
    );

    return MeetingTaskListsView.fromPartial(await response.json());
  } catch (e) {
    return MeetingTaskListsView.fromPartial({ taskLists: [] });
  }
};

export const fetchJobsDataByPoItemIds = async (props: {
  apiKey: string;
  poItemIds: number[];
  poIds?: number[];
}) => {
  try {
    const resp = await fetch(
      `${
        process.env.REACT_APP_PPIC_URL
      }/jobs-po-item-ids?ids=${encodeURIComponent(
        JSON.stringify(props.poItemIds)
      )}&poIds=${encodeURIComponent(JSON.stringify(props.poIds))}`
    );

    const jobs = (await resp.json()) as Job[];
    return jobs;
  } catch (e) {
    return [];
  }
};

export const fetchWorkOrdersProtoMongo = async (params: {
  apiKey?: string | null;
  extJobId?: any;
  extJobIds?: any[];
  extUserIds?: any[];
  extPanelCodeIds?: any[];
  outstanding?: boolean;
  from?: any;
  to?: any;
  filterProductTreeOnly?: boolean;
  filterProgramTreeOnly?: boolean;
  hasCoords?: boolean;
  picOnly?: boolean | null | undefined;
  extProblemCatalogId?: any;
  extPurchaseOrderId?: any;
  extProjectIds?: any[];
  extMaterialRequestId?: any;
  extBomLeveledId?: any;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-mongo?extJobId=${
        params.extJobId !== null && params.extJobId !== undefined
          ? `${params.extJobId}`
          : ``
      }&outstanding=${params.outstanding ?? ""}&from=${params.from ?? ""}&to=${
        params.to ?? ""
      }&filterProductTreeOnly=${
        params.filterProductTreeOnly ?? false
      }&filterProgramTreeOnly=${
        params.filterProgramTreeOnly ?? false
      }&hasCoords=${params.hasCoords ?? ""}&extJobIds=${
        params.extJobIds
          ? encodeURIComponent(JSON.stringify(params.extJobIds ?? []))
          : ""
      }&extUserIds=${
        params.extUserIds
          ? encodeURIComponent(JSON.stringify(params.extUserIds))
          : []
      }&picOnly=${params.picOnly ?? ""}&extPanelCodeIds=${
        params.extPanelCodeIds
          ? encodeURIComponent(JSON.stringify(params.extPanelCodeIds))
          : ""
      }&extProblemCatalogId=${
        params.extProblemCatalogId ? params.extProblemCatalogId : ""
      }&extPurchaseOrderId=${
        params.extPurchaseOrderId ? params.extPurchaseOrderId : ""
      }&extProjectIds=${
        params.extProjectIds
          ? encodeURIComponent(JSON.stringify(params.extProjectIds ?? []))
          : ""
      }&extMaterialRequestId=${
        params.extMaterialRequestId ?? ""
      }&extBomLeveledId=${params.extBomLeveledId ?? ""}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return MeetingTaskListsView.fromPartial(await response.json());
  } catch (e) {
    console.log(e);
    return MeetingTaskListsView.fromPartial({});
  }
};

export const fetchMeetingTaskProto = async (params: {
  apiKey?: string | null;
  id: any;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/meetingtasks-proto/${params.id}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return MeetingMeetingTask.fromPartial(await response.json());
  } catch (e) {
    console.log(e);
    return MeetingMeetingTask.fromPartial({});
  }
};
export const fetchWorkOrderProtoMongo = async (params: {
  apiKey?: string | null;
  id: any;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-mongo/${params.id}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return MeetingTaskListView.fromPartial(await response.json());
  } catch (e) {
    console.log(e);
    return MeetingTaskListView.fromPartial({});
  }
};

export const parseJwt = (
  jwtstring: string
): { sub: string; roles: Array<string>; jti?: string | null } | null => {
  try {
    const payloadstring = jwtstring.split(".")[1];
    const apiKeyObj = JSON.parse(atob(payloadstring));

    if (apiKeyObj.sub && apiKeyObj.roles) {
      return {
        sub: apiKeyObj.sub as string,
        roles: apiKeyObj.roles as Array<string>,
        jti: apiKeyObj.jti,
      };
    } else {
      return null;
    }
  } catch (e) {
    console.error("Error parsing jwt", e);
    return null;
  }
};

export const DoneStatusCheck = (props: {
  date: string;
  doneStatus: WiringWorkOrderDoneStatus | null | undefined;
}) => {
  const dateNow = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  const dateToCheck = new Date(props.date);

  const ViewElement = () => {
    switch (props.doneStatus) {
      case "CANCELLED":
        return <span style={{ color: "purple" }}>CANCELLED</span>;

      case "UNFINISHED":
        return <span style={{ color: "red" }}>UNFINISHED</span>;

      case "FINISHED":
        return <span style={{ color: "green" }}>FINISHED</span>;

      case "ON_PROGRESS":
        return dateNow.getTime() > dateToCheck.getTime() ? (
          <span style={{ color: "red" }}>NOT COMPLETE</span>
        ) : (
          <span style={{ color: "gray" }}>ON_PROGRESS</span>
        );

      default:
        return <span style={{ color: "black" }}>NOT FOUND</span>;
    }
  };

  return (
    <strong>
      <ViewElement />{" "}
    </strong>
  );
};

export const makeLocaleDateTimeString = (date: Date) => {
  return `${date.toDateString()} ${date.toLocaleTimeString()} WIB`;
};

export const planNameColor = (planName: string) => {
  switch (planName) {
    // Mech Doc
    case "Drawing":
      return "magenta";
    case "Program":
      return "magenta";
    case "BOM":
      return "magenta";
    // Elec Doc
    case "Layout":
      return "crimson";
    case "SLD":
      return "crimson";
    case "Zplant":
      return "crimson";
    case "FW/LO":
      return "crimson";
    case "Schema":
      return "crimson";
    case "TL":
      return "crimson";
    case "WL":
      return "crimson";
    case "BOM Elec":
      return "crimson";
    case "Cutout":
      return "crimson";
    case "Nameplate":
      return "crimson";
    case "Mimik":
      return "crimson";
    // Prod Process
    case "P":
      return "darkorange";
    case "B":
      return "darkorange";
    case "W":
      return "goldenrod";
    case "G":
      return "goldenrod";
    case "C":
      return "limegreen";
    case "A":
      return "teal";
    case "Grouping":
      return "blue";
    case "Tubing/Crimping":
      return "blue";
    case "Dinrail":
      return "darkturquoise";
    case "Cable/Support Duct":
      return "darkturquoise";
    case "Pasang Terminal":
      return "darkturquoise";
    case "Cutout Panel":
      return "darkturquoise";
    case "Component":
      return "darkturquoise";
    case "Labeling":
      return "darkturquoise";
    case "Mimik/Nameplate":
      return "darkturquoise";
    case "Potong":
      return "saddlebrown";
    case "Bending":
      return "saddlebrown";
    case "Lubang":
      return "saddlebrown";
    case "Baut":
      return "saddlebrown";
    case "Heatshrink":
      return "saddlebrown";
    case "Pasang":
      return "saddlebrown";
    case "WK":
      return "mediumvioletred";
    case "LR":
      return "mediumvioletred";
    case "Interkoneksi":
      return "mediumvioletred";
    case "Closing":
      return "mediumvioletred";
    case "Panel":
      return "rebeccapurple";
    case "Point to Point":
      return "rebeccapurple";
    case "Test On":
      return "rebeccapurple";
    case "NC Mechanical":
      return "rebeccapurple";
    case "NC Electrical":
      return "rebeccapurple";
    case "Validasi":
      return "rebeccapurple";
    default:
      return "maroon";
  }
};

export const checkMrQtyAvailColor = (
  inventoryCheck: number | null | undefined,
  qtyAvail: number
) => {
  if (inventoryCheck) {
    if (qtyAvail > 0) {
      return "bg-info text-white";
    } else {
      return "bg-danger text-white";
    }
  } else {
    return "bg-dark text-dark";
  }
};

export const fetchInitialData = async (state: IAppState) => {
  try {
    const [
      processTypes,
      rals,
      materialTypes,
      extOrganizations,
      extCustomers,
      extUsers,
      extDepartments,
      projectsIdName,
      projectProductsIdName,
      extCrmCustomers,
      userData,
    ] = await Promise.all([
      fetchProcessTypes(state?.baseUrl ?? "", state?.apiKey ?? ""),
      fetchRals(state?.baseUrl ?? "", state?.apiKey ?? ""),
      fetchMaterialTypes(state?.baseUrl ?? "", state?.apiKey ?? ""),
      // fetchExtOrganizations(state?.baseUrl ?? "", state?.apiKey ?? ""),
      [],
      fetchExtCustomers(state?.baseUrl ?? "", state?.apiKey ?? ""),
      fetchExtUsers(state?.baseUrl ?? "", state?.apiKey ?? ""),
      fetchExtDepartments(state?.baseUrl ?? "", state?.apiKey ?? ""),
      fetchProjectsIdName(state?.baseUrl ?? "", state?.apiKey ?? ""),
      fetchProjectProductsIdName(state?.baseUrl ?? "", state?.apiKey ?? ""),
      fetchExtCrmCustomers({ apiKey: state.apiKey ?? "" }),
      fetchUser({
        apiKey: state.apiKey ?? "",
        userId: parseJwt(state?.apiKey ?? "")?.jti,
      }),
    ]);

    // Process type ordering
    processTypes.sort((a, b) => (a.ordering ?? 0) - (b.ordering ?? 0));

    state.setProcessTypes(processTypes);
    state.setRals(rals);
    state.setMaterialTypes(materialTypes);
    state.setExtOrganizations(extOrganizations);
    state.setExtCustomers(extCustomers);
    state.setExtUsers(extUsers);

    // Find user
    if (parseJwt(state?.apiKey ?? "")?.jti) {
      state.setUser(userData);
    }

    state.setExtDepartments(extDepartments);
    state.setProjectsIdName(projectsIdName ?? []);
    state.setProjectProductsIdName(projectProductsIdName);

    if (extCrmCustomers) {
      state.setExtCrmCustomers(extCrmCustomers);
    }

    // Login gspe app
    try {
      // const response = await fetch(
      //   `${process.env.REACT_APP_BASE_URL}/ext-login`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ username: username, password: password }),
      //   }
      // );

      const gspeAppApiKey = state.gspeAppApiKey ?? "";
      console.log("gspe app api key", gspeAppApiKey);

      const [gspeAppUserData] = await Promise.all([
        fetchMeetingUser({
          apiKey: gspeAppApiKey,
          userId: parseJwt(gspeAppApiKey)?.jti,
        }),
      ]);

      console.log("gspe app user", gspeAppUserData);

      localStorage.setItem("gspeAppApiKey", gspeAppApiKey);
      state?.setGspeAppUser(gspeAppUserData);
    } catch (e) {
      alert(`GSPE app login error: ${e}`);
    }
  } catch (e) {
    console.log("[fetchInitialData] error", e);
  }
};

export const fetchProcessTypes = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/processtypes`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as ProcessType[];
  } catch (e) {
    return [];
  }
};

export const fetchProjectProductsIdName = async (
  baseUrl: string,
  apiKey: string
) => {
  try {
    const response = await fetch(`${baseUrl}/ext-projectproducts-idname`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as {
      id: number | null;
      name: string | null;
    }[];
  } catch (e) {
    return [];
  }
};

export const fetchExtCrmCustomers = async (params: {
  apiKey?: string | null | undefined;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-crm-customers`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    return CrmCustomers.fromPartial(await response.json());
  } catch (e) {
    return null;
  }
};
export const fetchUser = async (params: {
  apiKey?: string | null | undefined;
  userId?: any;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users/${params.userId ?? 0}`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    return (await response.json()) as User;
  } catch (e) {
    return null;
  }
};
export const fetchMeetingUser = async (params: {
  apiKey?: string | null | undefined;
  userId?: any;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/ext-users/${params.userId ?? 0}`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    return (await response.json()) as ExtUser;
  } catch (e) {
    return null;
  }
};

export const fetchDateWorkOrders = async (
  baseUrl: string,
  apiKey: string,
  date: string
) => {
  try {
    const response = await fetch(
      `${baseUrl}/extprojectworkorders-bydate?date=${makeDateString(
        new Date(date)
      )}T00:00:00Z`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );

    return (await response.json()) as ExtProjectWorkOrder[];
  } catch (e) {
    return [];
  }
};

export const fetchPanelTemplateProcessTypeGeneralItemSubProcessWorkers = async (
  baseUrl: string,
  apiKey: string,
  date: string
) => {
  try {
    const response = await fetch(
      `${baseUrl}/paneltemplateprocesstypegeneralitemsubprocessworkers-bydate?date=${makeDateString(
        new Date(date)
      )}T00:00:00Z`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );

    return (await response.json()) as PanelTemplateProcessTypeGeneralItemSubProcessWorker[];
  } catch (e) {
    return [];
  }
};

export const fetchDateWorkOrdersRange = async (
  baseUrl: string,
  apiKey: string,
  start: string,
  end: string
) => {
  try {
    const response = await fetch(
      `${baseUrl}/extprojectworkorders-bydate-range?start=${makeDateString(
        new Date(start)
      )}T00:00:00Z&end=${makeDateString(new Date(end))}T00:00:00Z`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );

    return (await response.json()) as ExtProjectWorkOrderView[];
  } catch (e) {
    return [];
  }
};

export const fetchProjectsIdName = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/ext-projects-idname`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as {
      id: number | null;
      name: string | null;
    }[];
  } catch (e) {
    return [];
  }
};
export const fetchHandoversOuts = async (apiKey: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/handovers-outs`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );

    return PpicHandovers.fromPartial(await response.json());
  } catch (e) {
    return PpicHandovers.fromPartial({});
  }
};

export const fetchProjects = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/projects`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as Project[];
  } catch (e) {
    return [];
  }
};

export const fetchProject = async (params: { id?: any; apiKey?: string }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/projects/${params.id ?? ""}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    return (await response.json()) as MeetingTs.Project;
  } catch (e) {
    return null;
  }
};
export const fetchProjectMasterTemplatesProto = async (props: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/projectmastertemplates`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return MeetingProjectMasterTemplates.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchProjectSimpleProto = async (
  apiKey: string,
  includeProjectMasterTemplate?: boolean
) => {
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_MEETING_URL
      }/projects-simple-proto?includeProjectMasterTemplate=${
        includeProjectMasterTemplate ?? ""
      }`,
      {
        headers: { authorization: apiKey },
      }
    );
    return MeetingProjects.fromPartial(await response.json());
  } catch (e) {
    return null;
  }
};
export const fetchProjectMasterTemplatePresetsProto = async (props: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/projecttemplatepresets`,
      {
        headers: {
          authorization: props?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return MeetingMasterTemplatePresets.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchExtProject = async (
  baseUrl: string,
  apiKey: string,
  id: any
) => {
  try {
    const resp = await fetch(`${baseUrl}/ext-projects/${id}`, {
      headers: { authorization: apiKey ?? "" },
    });

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as ExtProjectSimple;
  } catch (e) {
    console.log("Error", e);
    return null;
  }
};

export const fetchExtUsers = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/ext-users`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as ExtUser[];
  } catch (e) {
    return [];
  }
};

export const fetchExtDepartments = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/ext-departments`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as ExtDepartment[];
  } catch (e) {
    return [];
  }
};

export const fetchPanelTemplates = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/paneltemplates`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as PanelTemplate[];
  } catch (e) {
    return [];
  }
};

export const fetchExtProjectProductPanelTemplatesByExtProjectId = async (
  baseUrl: string,
  apiKey: string,
  extProjectId: any
) => {
  try {
    const resp = await fetch(
      `${baseUrl}/extprojectproductpaneltemplates/byextprojectid?extProjectId=${extProjectId}`,
      {
        headers: { authorization: apiKey },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return (await resp.json()) as ExtProjectProductPanelTemplate[];
  } catch (e) {
    console.log(
      "[fetchExtProjectProductPanelTemplatesByExtProjectId] error",
      e
    );
    return [];
  }
};

export const fetchExtCustomers = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/ext-customers`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as ExtCustomer[];
  } catch (e) {
    return [];
  }
};

export const fetchPpicAssistances = async (params: {
  apiKey: string;
  date?: string;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/manpowerassistances?date=${
        params.date ?? ""
      }`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    return PpicManpowerAssistances.fromPartial(await response.json());
  } catch (e) {
    return null;
  }
};

export const fetchPanelTemplate = async (
  baseUrl: string,
  apiKey: string,
  id: any
) => {
  try {
    const response = await fetch(`${baseUrl}/paneltemplates/${id}`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as PanelTemplate;
  } catch (e) {
    return null;
  }
};

export const fetchPanelTemplateMechanicalProcessTypeBlacklists = async (
  baseUrl: string,
  apiKey: string,
  panelTemplateId: any
) => {
  try {
    const response = await fetch(
      `${baseUrl}/paneltemplates/${panelTemplateId}/paneltemplatemechanicalprocesstypeblacklists`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return (await response.json()) as PanelTemplateMechanicalProcessTypeBlacklist[];
  } catch (e) {
    return [];
  }
};

export const fetchPanelTemplateProcessTypeGeneralBlacklists = async (
  baseUrl: string,
  apiKey: string,
  panelTemplateId: any
) => {
  try {
    const response = await fetch(
      `${baseUrl}/paneltemplates/${panelTemplateId}/paneltemplateprocesstypegeneralblacklists`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return (await response.json()) as PanelTemplateProcessTypeGeneralBlacklist[];
  } catch (e) {
    return [];
  }
};

export const fetchPanelTemplateProcessTypeGeneralItemBlacklists = async (
  baseUrl: string,
  apiKey: string,
  panelTemplateId: any
) => {
  try {
    const response = await fetch(
      `${baseUrl}/paneltemplates/${panelTemplateId}/paneltemplateprocesstypegeneralitemblacklists`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return (await response.json()) as PanelTemplateProcessTypeGeneralItemBlacklist[];
  } catch (e) {
    return [];
  }
};

export const fetchPanelTemplateProcessTypeGeneralItemSubProcessBlacklists =
  async (baseUrl: string, apiKey: string, panelTemplateId: any) => {
    try {
      const response = await fetch(
        `${baseUrl}/paneltemplates/${panelTemplateId}/paneltemplateprocesstypegeneralitemsubprocessblacklists`,
        {
          headers: { authorization: apiKey ?? "" },
        }
      );
      if (response.status !== 200) throw await response.text();

      return (await response.json()) as PanelTemplateProcessTypeGeneralItemSubProcessBlacklist[];
    } catch (e) {
      return [];
    }
  };

export const fetchPanelTemplateMechanicalProcessTypes = async (
  baseUrl: string,
  apiKey: string,
  panelTemplateId: any
) => {
  try {
    const response = await fetch(
      `${baseUrl}/paneltemplates/${panelTemplateId}/paneltemplatemechanicalprocesstypes`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return (await response.json()) as PanelTemplateMechanicalProcessType[];
  } catch (e) {
    return [];
  }
};

export const fetchPanelTemplateProcessTypeGenerals = async (
  baseUrl: string,
  apiKey: string,
  panelTemplateId: any
) => {
  try {
    const response = await fetch(
      `${baseUrl}/paneltemplates/${panelTemplateId}/paneltemplateprocesstypegenerals`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return (await response.json()) as PanelTemplateProcessTypeGeneral[];
  } catch (e) {
    return [];
  }
};

export const fetchPanelTemplateProcessTypeGeneralItems = async (
  baseUrl: string,
  apiKey: string,
  panelTemplateId: any
) => {
  try {
    const response = await fetch(
      `${baseUrl}/paneltemplates/${panelTemplateId}/paneltemplateprocesstypegeneralitems`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();

    return (await response.json()) as PanelTemplateProcessTypeGeneralItem[];
  } catch (e) {
    return [];
  }
};

export const fetchPanelTemplateProcessTypeGeneralItemSubProcesses = async (
  baseUrl: string,
  apiKey: string,
  panelTemplateId: any
) => {
  try {
    const response = await fetch(
      `${baseUrl}/paneltemplates/${panelTemplateId}/paneltemplateprocesstypegeneralitemsubprocesses`,
      {
        headers: { authorization: apiKey ?? "" },
      }
    );

    if (response.status !== 200) throw await response.text();

    return (await response.json()) as PanelTemplateProcessTypeGeneralItemSubProcess[];
  } catch (e) {
    return [];
  }
};

export const fetchProcessTypeGeneralViews = async (
  baseUrl: string,
  apiKey: string
) => {
  try {
    const response = await fetch(`${baseUrl}/processtypegenerals-view`, {
      headers: { authorization: apiKey ?? "" },
    });

    if (response.status !== 200) throw await response.text();

    return (await response.json()) as ProcessTypeGeneralView[];
  } catch (e) {
    return [];
  }
};

export const fetchRals = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/rals`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as Ral[];
  } catch (e) {
    return [];
  }
};

export const fetchExtOrganizations = async (
  baseUrl: string,
  apiKey: string
) => {
  try {
    const response = await fetch(`${baseUrl}/ext-organizations`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as ExtOrganization[];
  } catch (e) {
    return [];
  }
};

export const fetchMaterialTypes = async (baseUrl: string, apiKey: string) => {
  try {
    const response = await fetch(`${baseUrl}/materialtypes`, {
      headers: { authorization: apiKey ?? "" },
    });

    return (await response.json()) as MaterialType[];
  } catch (e) {
    return [];
  }
};

export const checkLoadColor = (percentage: number) => {
  if (percentage > 40) {
    return blue[300];
  } else if (percentage > 35) {
    return green[300];
  } else if (percentage > 30) {
    return yellow[300];
  } else {
    return red[300];
  }
};

// 0 - 5 red
// 5 - 7.5 yellow
// > 7.5 green

// 0 1 2 3 4 5 6 7 8
// -----------
//    red
//           -----
//           yellow
//               -----
//                green
export const checkLoadColorDay = (percentage: number) => {
  if (percentage < 5) {
    return red[300];
  } else if (percentage < 7.5) {
    return yellow[300];
  } else {
    return green[300];
  }
};

export const getPanelTemplateProcessTypeGeneralItemTime = (
  processTypeGeneralItemView: ProcessTypeGeneralItemView,
  panelTemplateProcessTypeGeneralItemSubProcesses: PanelTemplateProcessTypeGeneralItemSubProcess[]
) => {
  return processTypeGeneralItemView.processTypeGeneralItemSubProcesses.reduce(
    (acc, processTypeGeneralItemSubProcess) =>
      acc +
      (panelTemplateProcessTypeGeneralItemSubProcesses.find(
        (panelTemplateProcessTypeGeneralItemSubProcessX) =>
          panelTemplateProcessTypeGeneralItemSubProcessX
            .processTypeGeneralItemSubProcess?.id ===
          processTypeGeneralItemSubProcess.processTypeGeneralItemSubProcess?.id
      )?.timeMins ?? 0),
    0
  );
};

export const getPanelTemplateProcessTypeGeneralTime = (
  processTypeGeneralView: ProcessTypeGeneralView,
  panelTemplateProcessTypeGeneralItemSubProcesses: PanelTemplateProcessTypeGeneralItemSubProcess[]
) => {
  return processTypeGeneralView.processTypeGeneralItems.reduce(
    (acc, processTypeGeneralItemView) =>
      acc +
      getPanelTemplateProcessTypeGeneralItemTime(
        processTypeGeneralItemView,
        panelTemplateProcessTypeGeneralItemSubProcesses
      ),
    0
  );
};

export interface ExtPurchaseRequestPostBody {
  pr_number?: string | null;
  request_from?: string | null;
  purpose?: string | null;
  purpose_remark?: string | null;
  request_mode?: number | null;
  pr_date?: string | null;
  pr_target?: string | null;
  created_by?: string | null;
}
export const fetchFepDocumentsSimple = async (url: {
  baseUrl: string;
  apiKey: string;
}) => {
  try {
    const resp = await fetch(`${url.baseUrl}/fepdocuments-simple`, {
      headers: { authorization: url.apiKey },
    });

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as FepDocument[];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchFepLeveleds = async (params: { apiKey?: string | null }) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/fepleveled`, {
      headers: { authorization: params.apiKey ?? "" },
    });

    const fepLeveleds: FepLeveled[] = await res.json();
    console.log("fep leveled data: ", fepLeveleds);

    return fepLeveleds;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const fetchExtInventory = async (params: {
  apiKey?: string | null;
  all?: any;
}) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-inventory?all=${
        params.all !== undefined ? params.all : ""
      }`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (res.status !== 200) throw await res.text();

    const extInventory: ExtInventory[] = await res.json();
    console.log("ext inventory data: ", extInventory);

    return extInventory;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const fetchExtInventoryRsvMr = async (params: {
  apiKey?: string | null;
  all?: any;
}) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-inventory-rsv-mr?all=${
        params.all !== undefined ? params.all : ""
      }`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (res.status !== 200) throw await res.text();

    const extInventory: ExtInventory[] = await res.json();
    console.log("ext inventory rsv mr data: ", extInventory);

    return extInventory;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const fetchAutoMRWarehouseFilters = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/automrwarehousefilters`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (res.status !== 200) throw await res.text();

    return PpicAutoMRWarehouseFilters.fromPartial(await res.json());
  } catch (e) {
    console.log(e);
    return PpicAutoMRWarehouseFilters.fromPartial({});
  }
};

export const fetchBomLeveleds = async (params: {
  apiKey?: string | null;
  recursive?: any;
}) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bomleveled?recursive=${
        params.recursive ?? ""
      }`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    const bomLeveleds: BomLeveledRecursive[] = await res.json();
    console.log("bom leveled data: ", bomLeveleds);

    return bomLeveleds;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const checkBomDocumentTypeColor = (bomType?: BomType | null) => {
  switch (bomType) {
    case "Set":
      return blue[100];

    case "Module":
      return green[100];

    case "Submodule":
      return orange[100];

    default:
      return "";
  }
};

export const fetchJobBom = async (params: { id: number; apiKey: string }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/job-bom-detail/${params.id}`,
      {
        headers: { authorization: params.apiKey },
      }
    );

    return await PpicBomLeveled.fromPartial(await response.json());
  } catch (e) {
    return null;
  }
};

export const fetchJobBomLeveleds = async (params: {
  apiKey: string;
  jobId?: any;
  bomId?: any;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobbomleveleds?bomId=${
        params.bomId ?? ""
      }&jobId=${params.jobId ?? ""}&concise=true`,
      {
        headers: { authorization: params.apiKey },
      }
    );

    return (await response.json()) as JobBomLeveled[];
  } catch (e) {
    return null;
  }
};

export const fetchJob = async (params: { id: number; apiKey: string }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobs/${params.id}/full?type=jobInput&compose=true`,
      {
        headers: { authorization: params.apiKey },
      }
    );

    const job = (await response.json()) as JobMapped;

    // Map jobTypes to jobTypeSelections
    const newJobTypeSelections = job.job?.jobTypes
      ?.map((jobType) => {
        switch (jobType.name) {
          case "Cabinet":
            return JobTypeSelection.Cabinet;

          case "Wiring":
            return JobTypeSelection.Wiring;

          default:
            return null;
        }
      })
      .filter((jobType): jobType is JobTypeSelection => jobType !== null);

    // Map jobPriority to JobPriority
    const jobPriority = (() => {
      switch (job.job?.priority) {
        case "Low":
          return JobPriority.Low;

        case "Medium":
          return JobPriority.Medium;

        case "High":
          return JobPriority.High;

        default:
          return JobPriority.Low;
      }
    })();

    // setState({
    //   ...state,
    //   jobPriority: jobPriority,
    //   jobTypeSelections: newJobTypeSelections,
    //   requestStatus: RequestStatus.Success,
    //   job: job,
    // });

    return {
      jobPriority: jobPriority,
      jobTypeSelections: newJobTypeSelections,
      job: job,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchJobsIdName = async (params: { apiKey: string }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobs-idname`,
      {
        headers: { authorization: params.apiKey },
      }
    );

    return (await response.json()) as Job[];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const fetchWiringProcessTypes = async (params: { apiKey: string }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/wiringprocesstypes`,
      {
        headers: { authorization: params.apiKey ? params.apiKey : "" },
      }
    );

    if (response.status !== 200) throw "Failed fetching wiring process types";

    const wiringProcessTypesData: Array<WiringProcessType> =
      await response.json();

    return wiringProcessTypesData;
  } catch (e) {
    return null;
  }
};

export const fetchJobTypes = async (params: { apiKey: string }) => {
  try {
    const jobTypesResponse = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobtypes`,
      {
        headers: { authorization: params.apiKey ? params.apiKey : "" },
      }
    );

    const jobTypesData: JobType[] = await jobTypesResponse.json();
    console.log("Job types data: ", jobTypesData);

    return jobTypesData;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchExtPurchaseRequestItemsOutstanding = async (params: {
  apiKey: string;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-purchase-requests-items-outstanding`,
      {
        headers: { authorization: params.apiKey ? params.apiKey : "" },
      }
    );

    const extPrItemsOutstanding: ExtPrItemOutstanding[] = await resp.json();
    console.log("[pr items outstanding]: ", extPrItemsOutstanding);

    return extPrItemsOutstanding;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const fetchForProcesses = async (params: { apiKey: string }) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/forprocesses`, {
      headers: { authorization: params.apiKey ? params.apiKey : "" },
    });

    const forProcessesData: ForProcess[] = await resp.json();
    console.log("for processes data: ", forProcessesData);

    return forProcessesData;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export interface ExtTsCrmPurchaseOrder {
  id?: number | null;
  purchaseOrderNumber?: string | null;
  account?: { id?: number | null; name?: string | null };
}

export const fetchExtTsCrmPurchaseOrders = async () => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_CRM_URL}/api/external/purchase-orders`
    );

    const extTsCrmPurchaseOrders: ExtTsCrmPurchaseOrder[] = await resp.json();
    console.log("[extTsCrmPurchaseOrders]: ", extTsCrmPurchaseOrders);

    return extTsCrmPurchaseOrders;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export interface ExtTsCrmPurchaseOrder {
  id?: number | null;
  purchaseOrderNumber?: string | null;
  account?: { id?: number | null; name?: string | null };
}

export const fetchExtCrmPurchaseOrdersProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_CRM_URL}/api/external/purchase-orders/job/with/product?include=true`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    const pos = await resp.json();
    console.log(
      "pos",
      pos,
      CrmPurchaseOrders.fromPartial({ purchaseOrders: pos })
    );

    return CrmPurchaseOrders.fromPartial({ purchaseOrders: pos });
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchExtCrmPurchaseOrdersProtoSimple = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_CRM_URL}/api/external/purchase-orders/ppic`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    const pos = await resp.json();
    console.log(
      "pos",
      pos,
      CrmPurchaseOrders.fromPartial({ purchaseOrders: pos })
    );

    return CrmPurchaseOrders.fromPartial({ purchaseOrders: pos });
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchExtCrmBposProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_CRM_URL}/api/external/bpo/job/with/product?include=true`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    const pos = await resp.json();
    console.log("bpos", pos, CrmBPOs.fromPartial({ purchaseOrders: pos }));

    return CrmBPOs.fromPartial({ purchaseOrders: pos });
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const flattenBom = (params: {
  bom?: BomLeveledRecursive | null;
  multiplier?: number | null | undefined;
}): { bom: BomLeveledRecursive | null; multiplier?: number | null }[] => {
  const multiplier =
    (params.bom?.bomLeveled?.qty ?? 1) * (params.multiplier ?? 1);
  return params.bom
    ? [
        {
          bom: params.bom,
          multiplier: multiplier,
        },
        ...(params.bom.children
          ? params.bom.children
              .map((b) => flattenBom({ bom: b, multiplier: multiplier }))
              .flat()
          : []),
      ]
    : [];
};
export const createBomListFlattened = (
  jobBomLeveleds: JobBomLeveledView[],
  jobBomLeveledsMapped: BomLeveledRecursive[]
) => {
  const bomsFlattened =
    jobBomLeveledsMapped
      ?.map((b) => ({
        bom: b.bomLeveled,
        list: flattenBom({
          bom: b,
          multiplier: !b.bomLeveled?.type
            ? jobBomLeveleds.find(
                (jb) => jb.jobBomLeveled?.bomLeveled?.id === b.bomLeveled?.id
              )?.jobBomLeveled?.qty ?? 1
            : 1,
        }),
      }))
      .flat() ?? [];

  return bomsFlattened
    .map((b) => b?.list.map((bx) => ({ bom: bx, parent: b.bom })) ?? [])
    .flat();
};

export const mapExtItemString = (e?: ExtItem | null) =>
  `(${e?.partNum && e?.partNum !== "" ? e.partNum : "[NO PN]"} - ${
    e?.mfr && e.mfr !== "" ? e.mfr : "[NO MFR]"
  }) ${e?.partName && e.partName !== "" ? e.partName : "[NO PARTNAME]"} : ${
    e?.partDesc && e.partDesc !== "" ? e.partDesc : "[NO PARTDESC]"
  } `;

export const fetchUsers = async (props: { apiKey?: string | null }) => {
  try {
    const response = await fetch(
      encodeURI(`${process.env.REACT_APP_BASE_URL}/users`),
      {
        headers: { authorization: props.apiKey ? props.apiKey : "" },
      }
    );
    return (await response.json()) as User[];
  } catch (e) {
    return [];
  }
};

export const fetchIntegrations = async (props: { apiKey?: string | null }) => {
  try {
    const response = await fetch(
      encodeURI(`${process.env.REACT_APP_BASE_URL}/integrations-view`),
      {
        headers: { authorization: props.apiKey ? props.apiKey : "" },
      }
    );
    return (await response.json()) as IntegrationView[];
  } catch (e) {
    return [];
  }
};

export const fetchIntegrationModulesProto = async (props: {
  apiKey?: string | null;
}) => {
  try {
    const response = await fetch(
      encodeURI(`${process.env.REACT_APP_BASE_URL}/integrationmodules-proto`),
      {
        headers: { authorization: props.apiKey ? props.apiKey : "" },
      }
    );
    return PpicIntegrationModules.fromJSON(await response.json());
  } catch (e) {
    return null;
  }
};

export const fetchSimplifiedProcessTypesProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/simplifiedprocesstypes-proto`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    return PpicSimplifiedProcessTypes.fromJSON(await response.json());
  } catch (e) {
    return null;
  }
};
export const fetchSimplifiedProcessTypeMultipliersProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/simplifiedprocesstypemultipliers-proto`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    return PpicSimplifiedProcessTypeMultipliers.fromJSON(await response.json());
  } catch (e) {
    return null;
  }
};

export const fetchIntegration = async (props: {
  apiKey?: string | null;
  id: any;
}) => {
  try {
    const response = await fetch(
      encodeURI(
        `${process.env.REACT_APP_BASE_URL}/integrations-view/${props.id}`
      ),
      {
        headers: { authorization: props.apiKey ? props.apiKey : "" },
      }
    );
    return (await response.json()) as IntegrationView | null | undefined;
  } catch (e) {
    return null;
  }
};

export const filterNotHiddenAndSort = <T extends BaseModel>(
  i: T[] | null | undefined
) =>
  i
    ?.filter((ix) => !ix.hidden)
    .sort((a, b) => (a.ordering ?? 0) - (b.ordering ?? 0)) ?? [];

export const getItemFullDescription = (i?: ExtItem | null) =>
  `${i?.mfr} ${i?.partNum}: ${i?.partName} - ${i?.partDesc}`;

export const getItemFullDescriptionProto = (
  i?: WarehouseItem | null,
  params?: { showId?: boolean }
) =>
  `${params?.showId ? `(#${i?.id})` : ``} ${i?.mfr} ${i?.partNum}: ${
    i?.partName
  } - ${i?.partDesc}`;

export const compareDateStringUtcColor = (props: {
  current?: string | null;
  date?: string | null;
}) => {
  try {
    const current = new Date(props.current ?? "").getTime();
    const date = new Date(props.date ?? "").getTime();
    const dayRange = (date - current) / 86400000;

    if (dayRange <= 0) {
      return "lightcoral";
    } else if (dayRange < 3) {
      return "yellow";
    } else if (dayRange < 7) {
      return "lightsteelblue";
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
};

export const extractProtoMeetingTaskTargetDate = (
  m?: MeetingMeetingTask | null
) =>
  (m?.meetingTaskTargetDates.length ?? 0) > 0
    ? m?.meetingTaskTargetDates?.[m.meetingTaskTargetDates.length - 1]
    : undefined;

export const fetchMachinePrograms = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/machineprograms-proto`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicMachinePrograms.fromPartial(await resp.json());
  } catch (e) {
    return undefined;
  }
};

export const fetchMachineProgramsNonProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/machineprograms`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return (await resp.json()) as MachineProgram[];
  } catch (e) {
    return [];
  }
};

export const fetchSimplifiedProcessTypes = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/simplifiedprocesstypes`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return (await resp.json()) as SimplifiedProcessType[];
  } catch (e) {
    return [];
  }
};

export const fetchJobsProtoSimple = async (params: {
  apiKey?: string | null;
  withProducts?: boolean | null;
  withRemarks?: boolean | null;
  withDeptTemplates?: boolean | null;
  withSerialNumbers?: boolean | null;
  withPurchaseOrders?: boolean | null;
  all?: boolean | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobs-proto-simple?withProducts=${
        params.withProducts ?? false
      }&withRemarks=${params.withRemarks ?? ""}&withDeptTemplates=${
        params.withDeptTemplates ?? ""
      }&withSerialNumbers=${
        params.withSerialNumbers ?? ""
      }&withPurchaseOrders=${params.withPurchaseOrders ?? ""}&all=${
        params.all ?? ""
      }`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicJobs.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchSkillsProto = async (params: { apiKey?: string | null }) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/skills`, {
      headers: {
        authorization: params.apiKey ?? "",
      },
    });
    if (resp.status !== 200) throw await resp.text();
    return PpicSkills.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};
export const fetchSkillProto = async (params: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/skills/${params.id}`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicSkill.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchJobsDataProto = async (params: {
  apiKey?: string | null;
  closed?: boolean;
  category?: JobDeptCategory | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobs-proto?closed=${
        params.closed !== null && params.closed !== undefined
          ? params.closed
          : ""
      }&category=${params.category ?? ""}`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return PpicJobs.fromPartial(await resp.json());
  } catch (e) {
    return PpicJobs.fromPartial({});
  }
};
export const fetchBomLeveledsRecursiveProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bomleveleds-proto`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return PpicBomLeveleds.fromPartial(await resp.json());
  } catch (e) {
    return PpicBomLeveleds.fromPartial({});
  }
};
export const fetchBomLeveledsProtoSimple = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bomleveleds-proto-simple`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return PpicBomLeveleds.fromPartial(await resp.json());
  } catch (e) {
    return PpicBomLeveleds.fromPartial({});
  }
};

export const fetchMeetingTaskComments = async (params: {
  apiKey?: string | null;
  extPanelCodeId?: string | null;
  extSubProcessId?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/meetingtaskcomments-filtered?extPanelCodeId=${params.extPanelCodeId}&extSubProcessId=${params.extSubProcessId}`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return MeetingMeetingTaskComments.fromPartial(await resp.json());
  } catch (e) {
    return MeetingMeetingTaskComments.fromPartial({ comments: [] });
  }
};
export const fetchJobsDataProtoCompose = async (params: {
  apiKey?: string | null;
  closed?: boolean;
  individualEntityId?: any;
  category?: any;
  priority?: any;
  id?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobs-proto?closed=${
        params.closed !== null && params.closed !== undefined
          ? params.closed
          : ""
      }&individualEntityId=${
        params.individualEntityId ?? ""
      }&compose=true&category=${params.category ?? ""}&priority=${
        params?.priority ?? ""
      }&id=${params.id ?? ""}`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return PpicJobOverviews.fromPartial(await resp.json());
  } catch (e) {
    return PpicJobOverviews.fromPartial({});
  }
};

export const fetchPanelCodesProtoSimple = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/panelcodes-proto-simple`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicPanelCodes.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};
export const fetchHandoverProto = async (params: {
  id?: any;
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/handovers-proto/${params.id}`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicHandover.fromPartial(await resp.json());
  } catch (e) {
    return PpicHandover.fromPartial({});
  }
};
export const fetchHandoversProto = async (params: {
  apiKey?: string | null;
  from?: any;
  to?: any;
  all?: boolean;
  type?: PpicHandoverType | null;
  status?: PpicHandover_Status | null;
  dateFilterType?: ByCreatedDateType | null;
  dateFilterFrom?: any;
  dateFilterTo?: any;
  extJobId?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/handovers-proto?from=${
        params.from ?? ""
      }&to=${params.to ?? ""}&all=${params.all ?? ""}&status=${
        params.status ?? ""
      }&type=${params.type ?? ""}&dateFilterType=${
        params.dateFilterType ?? ""
      }&dateFilterFrom=${params.dateFilterFrom ?? ""}&dateFilterTo=${
        params.dateFilterTo ?? ""
      }&extJobId=${params.extJobId ?? ""}`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicHandovers.fromPartial(await resp.json());
  } catch (e) {
    return PpicHandovers.fromPartial({});
  }
};

export const fetchRcemDepartmentsProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/rcemdepartments-proto`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicRcemDepartments.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};
export const fetchRcemTasklistMongoKpi = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/tasklistmongo-find-kpi`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return MeetingTaskListsView.fromPartial(await resp.json());
  } catch (e) {
    return MeetingTaskListsView.fromPartial({ taskLists: [] });
  }
};

export const fetchRcemProcessSkillsProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/rcemprocessskills`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicRcemProcessSkills.fromPartial(await resp.json());
  } catch (e) {
    return PpicRcemProcessSkills.fromPartial({ rcemProcessSkills: [] });
  }
};
export const fetchRcemSubProcessSkillsProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/rcemsubprocessskills`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicRcemSubProcessSkills.fromPartial(await resp.json());
  } catch (e) {
    return PpicRcemSubProcessSkills.fromPartial({ rcemSubProcessSkills: [] });
  }
};

export const fetchBomLeveledsProtoDetailedJob = async (params: {
  apiKey?: string | null;
  jobId?: any;
  includePrice?: any;
  types?: BomItemType[];
  includedPanelCodes?: any[];
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bomleveleds-proto-detailed-job/${
        params.jobId ?? ""
      }?types=${
        params.types ? encodeURIComponent(JSON.stringify(params.types)) : ""
      }&includePrice=${params.includePrice ?? ""}&includedPanelCodes=${
        params.includedPanelCodes
          ? encodeURIComponent(JSON.stringify(params.includedPanelCodes))
          : ""
      }`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicBomLeveled.fromPartial(await resp.json());
  } catch (e) {
    return PpicBomLeveled.fromPartial({ children: [] });
  }
};

export const fetchWarehouseItemsProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/ext-items`, {
      headers: {
        authorization: params.apiKey ?? "",
      },
    });
    if (resp.status !== 200) throw await resp.text();
    return WarehouseItems.fromPartial({ items: await resp.json() });
  } catch (e) {
    return WarehouseItems.fromPartial({ items: [] });
  }
};

export const fetchMachinesProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/machines-proto`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicMachines.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};
export const fetchPartNumberMatchesProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/partnumbermatches-proto`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicPartNumberMatches.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchPartNumberMatcheGroupsProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/partnumbermatchgroups`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicPartNumberMatchGroups.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchPartNumberCustomerMatchesProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/partnumbercustomermatches-proto`,
      {
        headers: {
          authorization: params.apiKey ?? "",
        },
      }
    );
    if (resp.status !== 200) throw await resp.text();
    return PpicPartNumberCustomerMatches.fromPartial(await resp.json());
  } catch (e) {
    return null;
  }
};

export const fetchCustomers = async (params: { apiKey?: string | null }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/individualentities`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw "Error fetching customers.";
    return (await response.json()) as IndividualEntity[];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchCustomersProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/individualentities-proto`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();
    return PpicIndividualEntities.fromPartial(await response.json());
  } catch (e) {
    console.error(e);
    return PpicIndividualEntities.fromPartial({});
  }
};

export const fetchPanelCodeDepartmentTemplateItemsProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/panelcodedepartmenttemplateitems-simple`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();
    return PpicPanelCodeDepartmentTemplateItems.fromPartial(
      await response.json()
    );
  } catch (e) {
    console.error(e);
    return PpicPanelCodeDepartmentTemplateItems.fromPartial({});
  }
};
export const fetchMeetingSites = async (params: { apiKey?: string | null }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/sites-proto`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();
    return MeetingSites.fromPartial(await response.json());
  } catch (e) {
    console.error(e);
    return MeetingSites.fromPartial({});
  }
};
export const fetchKpiApiProto = async (params: {
  apiKey?: string | null;
  from: any;
  to: any;
  attendance?: boolean;
  wo?: boolean;
  userIds?: number[];
  deptIds?: string[];
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/kpi-api?from=${params.from}&to=${
        params.to
      }&attendance=${params.attendance ?? ""}&wo=${
        params.wo ?? ""
      }&extUserIds=${encodeURIComponent(
        JSON.stringify(params.userIds ?? [])
      )}&extDepartmentId=${encodeURIComponent(
        JSON.stringify(params.deptIds ?? [])
      )}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );
    if (response.status !== 200) throw await response.text();
    return MeetingKpi.fromPartial(await response.json());
  } catch (e) {
    console.error(e);
    return MeetingKpi.fromPartial({});
  }
};

export const getWorkOrderTimeMinutes = (
  tL: MeetingTaskList | null | undefined,
  rcemDepartments: PpicRcemDepartments | null | undefined,
  integrationModules: PpicIntegrationModules | null | undefined,
  machinePrograms: PpicMachinePrograms | null | undefined,
  departmentTemplates: PpicDepartmentTemplates | null | undefined,
  panelCodeDepartmentTemplateItems:
    | PpicPanelCodeDepartmentTemplateItems
    | null
    | undefined,
  extUserId: any,
  curDate: any,
  instant?: boolean
) => {
  const filteredMeetingTasksByDate = curDate
    ? tL?.meetingTasks.filter((mt) => {
        return (
          (extUserId
            ? mt.meetingTaskInCharges.find(
                (ic) => `${ic.extUserId}` === `${extUserId}`
              )
            : true) &&
          (() => {
            try {
              return (
                new Date(curDate ?? "").getTime() <=
                  new Date(
                    extractProtoMeetingTaskTargetDate(mt)?.date ?? ""
                  ).getTime() &&
                new Date(curDate ?? "").getTime() >=
                  new Date(mt.start ?? "").getTime()
              );
            } catch (e) {
              return false;
            }
          })()
        );
      }) ?? []
    : tL?.meetingTasks ?? [];

  const filteredMeetingTasksByUser = extUserId
    ? filteredMeetingTasksByDate.filter((mt) =>
        mt.meetingTaskInCharges.find(
          (ic) => `${ic.extUserId}` === `${extUserId}`
        )
      )
    : filteredMeetingTasksByDate;

  if (instant) {
    return {
      setTimeHours: 0,
      submoduleTimeHours: 0,
      moduleTimeHours: 0,
      programTimeHours: 0,
      totalActualHours: 0,
      totalHours: extUserId
        ? tL?.totalTimeHours ?? 0
        : filteredMeetingTasksByUser.reduce((acc, mt) => {
            const foundUserTask = mt.meetingTaskInCharges.find(
              (ic) => `${ic.extUserId}` === `${extUserId}`
            );

            if (foundUserTask) {
              return acc + (foundUserTask.totalTimeHoursTask ?? 0);
            }

            return acc;
          }, 0) ?? 0,
    };
  }

  const subProcessTimeHours =
    filteredMeetingTasksByDate.reduce(
      (acc, mt) =>
        acc +
        (rcemDepartments?.departments
          .flatMap((d) => d.processes ?? [])
          .flatMap((p) => p.subProcesses ?? [])
          .find(
            (sp) =>
              `${sp.masterJavaBaseModel?.id}` ===
              `${
                departmentTemplates?.templates
                  .flatMap((dt) => dt.items ?? [])
                  .find(
                    (dti) =>
                      `${dti.masterJavaBaseModel?.id}` ===
                      `${mt?.extSubProcessId}`
                  )?.extRcemSubProcessId
              }`
          )?.timeProcess ?? 0),
      0.0
    ) ?? 0.0;

  const rcemTimeHours =
    filteredMeetingTasksByDate.reduce(
      (acc, mt) =>
        acc +
        (rcemDepartments?.departments
          .flatMap((d) => d.processes ?? [])
          .flatMap((p) => p.subProcesses ?? [])
          .find(
            (sp) =>
              `${sp.masterJavaBaseModel?.id}` === `${mt?.extRcemSubProcessId}`
          )?.timeProcess ?? 0),
      0.0
    ) ?? 0.0;

  const panelCodeDepartmentTemplateItemsTime =
    filteredMeetingTasksByDate.reduce(
      (acc, mt) =>
        acc +
        (mt.extPanelCodeDepartmentTemplateItemId &&
        mt.extPanelCodeDepartmentTemplateItemId !== "0"
          ? rcemDepartments?.departments
              .flatMap((d) => d.processes ?? [])
              .flatMap((p) => p.subProcesses ?? [])
              .find(
                (sp) =>
                  `${sp.masterJavaBaseModel?.id}` ===
                  `${
                    panelCodeDepartmentTemplateItems?.departmentTemplateItems.find(
                      (pcdti) =>
                        `${pcdti.masterJavaBaseModel?.id}` ===
                        `${mt.extPanelCodeDepartmentTemplateItemId}`
                    )?.departmentTemplateItem?.extRcemSubProcessId
                  }`
              )?.timeProcess ?? 0
          : 0.0),
      0.0
    ) ?? 0.0;

  const subProcessTimeHoursActual =
    filteredMeetingTasksByDate
      .filter(
        (mt) =>
          // If user exists, filter tasks only with the user
          (extUserId
            ? mt.meetingTaskInCharges.find(
                (mtic) => `${mtic.extUserId}` === `${extUserId}`
              )
            : true) && mt.status === "COMPLETED"
      )
      .reduce(
        (acc, mt) =>
          acc +
          (rcemDepartments?.departments
            .flatMap((d) => d.processes ?? [])
            .flatMap((p) => p.subProcesses ?? [])
            .find(
              (sp) =>
                `${sp.masterJavaBaseModel?.id}` ===
                `${
                  departmentTemplates?.templates
                    .flatMap((dt) => dt.items ?? [])
                    .find(
                      (dti) =>
                        `${dti.masterJavaBaseModel?.id}` ===
                        `${mt?.extSubProcessId}`
                    )?.extRcemSubProcessId
                }`
            )?.timeProcess ?? 0),
        0.0
      ) ?? 0.0;

  const setTimeHours =
    (integrationModules?.modules.find(
      (m) => `${tL?.extSetId}` === `${m.masterJavaBaseModel?.id}`
    )?.timeProcessMins ?? 0) / 60;

  const moduleTimeHours =
    (integrationModules?.modules.find(
      (m) => `${tL?.extModuleId}` === `${m.masterJavaBaseModel?.id}`
    )?.timeProcessMins ?? 0) / 60;

  const submoduleTimeHours =
    (integrationModules?.modules.find(
      (m) => `${tL?.extSubModuleId}` === `${m.masterJavaBaseModel?.id}`
    )?.timeProcessMins ?? 0) / 60;

  const programTimeHours =
    (machinePrograms?.machinePrograms.find(
      (mp) => `${tL?.extProgramId}` === `${mp.masterJavaBaseModel?.id}`
    )?.timeProcessMins ?? 0) / 60;

  const subProcessesTimeHours =
    filteredMeetingTasksByDate
      // Filter by user:
      // if extUserId is included, only the task which has the user id as inCharge is accumulated.
      ?.filter((mt) =>
        extUserId
          ? mt.meetingTaskInCharges.find(
              (mtic) => `${mtic.extUserId}` === `${extUserId}`
            )
          : true
      )
      ?.map((mt) =>
        // Find by time:
        //  1. Match MeetingTask->extSubProcessId with departmentTemplateItem->id
        //  2. Extract extRcemSubProcessId from departmentTemplateItem
        //  3. Match RcemDepartments->rcemProcesses->rcemSubProcesses with MeetingTask->extSubProcessId
        //  4. Extract rcemSubProcess time
        {
          const foundDti = departmentTemplates?.templates
            .map((t) => t.items ?? [])
            .flat()
            .find(
              (dti) =>
                `${dti.masterJavaBaseModel?.id}` === `${mt.extSubProcessId}`
            );

          const foundRcem = rcemDepartments?.departments
            .map((d) => d.processes ?? [])
            .flat()
            .map((p) => p.subProcesses ?? [])
            .flat()
            .find(
              (sp) =>
                `${sp.masterJavaBaseModel?.id}` ===
                `${foundDti?.extRcemSubProcessId}`
            );

          return foundRcem;
        }
      )
      .reduce((acc, sp) => (acc ?? 0) + (sp?.timeProcess ?? 0), 0.0) ?? 0;

  const meetingTaskDuration =
    (filteredMeetingTasksByDate.reduce(
      (acc, mt) => acc + (mt.durationMins ?? 0),
      0
    ) ?? 0) / 60;

  const productTimeActualHours = tL?.meetingTasks.find(
    (mt) => mt.status !== "COMPLETED"
  )
    ? 0
    : (tL?.extIntegrationModuleQtyActual ?? 0) *
      (setTimeHours + moduleTimeHours + submoduleTimeHours);

  const programTimeActualHours = tL?.meetingTasks.find(
    (mt) => mt.status !== "COMPLETED"
  )
    ? 0
    : (tL?.extProgramRunQtyActual ?? 0) * programTimeHours;

  const durationMinsActualHours =
    (filteredMeetingTasksByDate
      .filter((mt) => mt.status === "COMPLETED")
      .reduce((acc, mt) => acc + (mt.durationMins ?? 0), 0.0) ?? 0) / 60.0;

  const totalActualHours =
    productTimeActualHours +
    programTimeActualHours +
    durationMinsActualHours +
    subProcessTimeHoursActual;

  return {
    subProcessTimeHours: subProcessTimeHours,
    setTimeHours: setTimeHours * (tL?.extIntegrationModuleQty ?? 0),
    moduleTimeHours: moduleTimeHours * (tL?.extIntegrationModuleQty ?? 0),
    submoduleTimeHours: submoduleTimeHours * (tL?.extIntegrationModuleQty ?? 0),
    programTimeHours: programTimeHours * (tL?.extProgramRunQty ?? 0),
    subProcessesTimeHours: subProcessesTimeHours,
    rcemTimeHours: rcemTimeHours,
    subProcessTimeHoursActual: subProcessTimeHoursActual,
    meetingTaskDuration: meetingTaskDuration,
    productTimeActualHours: productTimeActualHours,
    programTimeActualHours: programTimeActualHours,
    totalActualHours: totalActualHours,
    durationMinsActualHours: durationMinsActualHours,
    panelCodeDepartmentTemplateItemsTime: panelCodeDepartmentTemplateItemsTime,
    totalHours:
      setTimeHours * (tL?.extIntegrationModuleQty ?? 0) +
      moduleTimeHours * (tL?.extIntegrationModuleQty ?? 0) +
      submoduleTimeHours * (tL?.extIntegrationModuleQty ?? 0) +
      programTimeHours * (tL?.extProgramRunQty ?? 0) +
      subProcessesTimeHours +
      meetingTaskDuration +
      rcemTimeHours +
      panelCodeDepartmentTemplateItemsTime,
  };
};

export const filterMeetingTasks = (
  mt: MeetingMeetingTask[] | undefined,
  extUserId?: any | null | undefined,
  date?: any | null | undefined,
  ctx?: IAppState | null
) => {
  try {
    return (
      mt?.filter((mt) => {
        const privilegeCondId =
          ctx &&
          mt.masterJavaBaseModel?.id &&
          mt.masterJavaBaseModel.id !== "" &&
          mt.masterJavaBaseModel.id !== "0";

        const privilegeUserId =
          (ctx?.gspeAppUser as any)?.isHead ||
          ctx?.gspeAppUser?.username === "admin" ||
          mt.meetingTaskInCharges.find(
            (ic) => `${ic.extUserId}` === `${ctx?.gspeAppUser?.id}`
          ) ||
          `${mt.taskList?.extInChargeId}` === `${ctx?.gspeAppUser?.id}`;

        console.log("mt", mt, ctx?.gspeAppUser);

        return (
          // Fitler by user id
          (extUserId
            ? mt.meetingTaskInCharges.find(
                (c) => `${c.extUserId}` === `${extUserId}`
              )
            : true) &&
          // Filter by date
          (date
            ? new Date(date ?? "").getTime() <=
                new Date(
                  extractProtoMeetingTaskTargetDate(mt)?.date ?? ""
                ).getTime() &&
              new Date(date ?? "").getTime() >=
                new Date(mt.start ?? "").getTime()
            : true) &&
          // Filter by privilege
          (privilegeCondId ? privilegeUserId : true)
        );
      }) ?? []
    );
  } catch (_) {
    return [];
  }
};

export const TaskListInfo = (props: {
  crmCustomers: CrmCustomers | null | undefined;
  tL: MeetingTaskListView | null | undefined;
  extUsers: ExtUser[];
  jobsProto: PpicJobs;
  machines: Machine[];
  processTypes: PpicSimplifiedProcessTypes;
  rcemDepartments: PpicRcemDepartments | null | undefined;
  integrationModules: PpicIntegrationModules | null | undefined;
  machinePrograms: PpicMachinePrograms | null | undefined;
  departmentTemplates: PpicDepartmentTemplates | null | undefined;
  panelCodeDepartmentTemplateItems:
    | PpicPanelCodeDepartmentTemplateItems
    | null
    | undefined;
  exUserId?: any;
  exUserIds?: any;
  date?: any;
  customers?: ExtCustomer[] | null | undefined;
  instantTime?: boolean;
  ctx?: IAppState | null;
}) => {
  const woTime = getWorkOrderTimeMinutes(
    props.tL?.taskList,
    props.rcemDepartments,
    props.integrationModules,
    props.machinePrograms,
    props.departmentTemplates,
    props.panelCodeDepartmentTemplateItems,
    props.exUserId,
    props.date,
    props.instantTime
  );
  const filteredMeetingTasks = filterMeetingTasks(
    props.tL?.taskList?.meetingTasks,
    props.exUserId,
    props.date,
    props.ctx
  );

  const [, refresh] = useState(false);
  const showLogs = useRef(false);

  const render = () => {
    refresh((n) => !n);
  };

  const woTimeHoursParsed = props.instantTime
    ? props.tL?.taskList?.totalTimeHours
    : woTime.totalHours;

  const woTimeHoursParsedFinal =
    (woTimeHoursParsed ?? 0) < 0 ? 0 : woTimeHoursParsed;

  return (
    <>
      <div>
        {/* Essentials */}
        <div className="d-flex justify-content-between mt-1 bg-light shadow shadow-md">
          <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
            #{props.tL?.taskList?.masterJavaBaseModel?.id}
          </div>
          <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
            <strong>{woTimeHoursParsedFinal?.toFixed(1)} hrs</strong>
          </div>
          <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
            SPV:{" "}
            {props.tL?.taskList?.extInChargeId &&
            props.tL?.taskList?.extInChargeId !== "0" ? (
              <>
                {" "}
                {
                  props.extUsers?.find(
                    (u) => `${u.id}` === `${props.tL?.taskList?.extInChargeId}`
                  )?.name
                }
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
            <small>
              {[
                ...new Set(
                  props.tL?.taskList?.meetingTasks
                    .flatMap((mt) => mt.meetingTaskInCharges)
                    .map((c) => c.extUserId)
                ),
              ]
                .map(
                  (id) =>
                    props.extUsers.find((u) => `${u.id}` === `${id}`)?.username
                )
                .join(",")}
            </small>
          </div>

          <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
            From:{" "}
            {(() => {
              const starts = props.tL?.taskList?.meetingTasks.map(
                (mt) => mt.start
              );

              starts?.sort((a, b) => a?.localeCompare(b ?? "") ?? 0);

              return starts?.[0];
            })()}
          </div>
          <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
            To:{" "}
            {(() => {
              const starts = props.tL?.taskList?.meetingTasks
                .flatMap((mt) =>
                  mt.meetingTaskTargetDates
                    .filter((mttd) => mttd.date)
                    .slice(-1)
                )
                .map((mt) => mt.date);

              starts?.sort((a, b) => a?.localeCompare(b ?? "") ?? 0);

              return starts?.slice(-1)?.[0];
            })()}
          </div>
          {props.tL?.taskList?.extProcessTypeId &&
          props.tL?.taskList?.extProcessTypeId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Process Type:{" "}
              {
                props.processTypes?.processTypes?.find(
                  (pt) =>
                    `${pt?.masterJavaBaseModel?.id}` ===
                    `${props.tL?.taskList?.extProcessTypeId}`
                )?.name
              }
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Module,set,submodule,program */}
        <div className="d-flex justify-content-between mt-1 bg-light shadow shadow-md">
          {props.tL?.taskList?.extSetId &&
          props.tL?.taskList?.extSetId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Set:{" "}
              {
                props.integrationModules?.modules?.find(
                  (m) =>
                    `${m.masterJavaBaseModel?.id}` ===
                    `${props.tL?.taskList?.extSetId}`
                )?.name
              }{" "}
              <strong>
                {(
                  woTime.setTimeHours /
                  (props.tL?.taskList?.extIntegrationModuleQty ?? 1)
                ).toFixed(2)}{" "}
                hrs: {woTime.setTimeHours.toFixed(2)} hrs
              </strong>
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extModuleId &&
          props.tL?.taskList?.extModuleId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Module:{" "}
              {
                props.integrationModules?.modules?.find(
                  (m) =>
                    `${m.masterJavaBaseModel?.id}` ===
                    `${props.tL?.taskList?.extModuleId}`
                )?.name
              }{" "}
              <strong>
                {(
                  woTime.moduleTimeHours /
                  (props.tL?.taskList?.extIntegrationModuleQty ?? 1)
                ).toFixed(2)}{" "}
                hrs: {woTime.moduleTimeHours.toFixed(2)} hrs
              </strong>
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extSubModuleId &&
          props.tL?.taskList?.extSubModuleId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Submodule:{" "}
              {
                props.integrationModules?.modules?.find(
                  (m) =>
                    `${m.masterJavaBaseModel?.id}` ===
                    `${props.tL?.taskList?.extSubModuleId}`
                )?.name
              }{" "}
              <strong>
                {(
                  woTime.submoduleTimeHours /
                  (props.tL?.taskList?.extIntegrationModuleQty ?? 1)
                ).toFixed(2)}{" "}
                hrs: {woTime.submoduleTimeHours.toFixed(2)} hrs
              </strong>
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extIntegrationModuleQty &&
          props.tL?.taskList?.extIntegrationModuleQty !== 0 ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Module qty done/needed:{" "}
              {props.tL?.taskList?.extIntegrationModuleQtyActual ?? 0}/
              {props.tL?.taskList?.extIntegrationModuleQty ?? 0}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extProgramId &&
          props.tL?.taskList?.extProgramId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Program:{" "}
              {
                props.machinePrograms?.machinePrograms?.find(
                  (mp) =>
                    `${mp.masterJavaBaseModel?.id}` ===
                    `${props.tL?.taskList?.extProgramId}`
                )?.name
              }{" "}
              <strong>{woTime.programTimeHours.toFixed(2)} hrs</strong>
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extProgramRunQty &&
          props.tL?.taskList?.extProgramRunQty !== 0 ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Program qty done/needed:{" "}
              {props.tL?.taskList?.extProgramRunQtyActual ?? 0}/
              {props.tL?.taskList?.extProgramRunQty ?? 0}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Job, panelcode, customer, machine */}
        <div className="d-flex justify-content-between mt-1 bg-light shadow shadow-md">
          {props.tL?.taskList?.extCustomerId &&
          props.tL?.taskList?.extCustomerId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Customer:{" "}
              {props.crmCustomers?.customers.find(
                (cx) => `${cx.value}` === `${props.tL?.taskList?.extCustomerId}`
              )?.text ??
                `No cust: Customer ID ${props.tL?.taskList?.extCustomerId}`}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extManufacturingCustomerId &&
          props.tL?.taskList?.extManufacturingCustomerId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Customer:{" "}
              {(() => {
                const foundCust = props.customers?.find(
                  (cx) =>
                    `${cx.id}` ===
                    `${props.tL?.taskList?.extManufacturingCustomerId}`
                );

                if (!foundCust) {
                  return `No cust: Customer ID ${props.tL?.taskList?.extManufacturingCustomerId}`;
                }

                return `${foundCust.name} (#${foundCust.id})`;
              })()}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extJobId &&
          props.tL?.taskList?.extJobId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Job:{" "}
              {
                props.jobsProto?.jobs?.find(
                  (j) =>
                    `${j?.masterJavaBaseModel?.id}` ===
                    `${props.tL?.taskList?.extJobId}`
                )?.name
              }
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extMachineId &&
          props.tL?.taskList?.extMachineId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Machine:{" "}
              {
                props.machines?.find(
                  (m) => `${m?.id}` === `${props.tL?.taskList?.extMachineId}`
                )?.name
              }
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extMrId &&
          props.tL?.taskList?.extMrId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              MR: {props.tL?.taskList?.extMrId}
            </div>
          ) : (
            <></>
          )}

          {/* <div>PC: {tL.taskList?.extPanelCodeId}</div> */}
          {props.tL?.taskList?.extPanelCodeId &&
          props.tL?.taskList?.extPanelCodeId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Panel Code:{" "}
              {(() => {
                const fpc = props.jobsProto?.jobs
                  ?.map((j) => j?.panelCodes ?? [])
                  ?.flat()
                  ?.find(
                    (pc) =>
                      `${pc?.masterJavaBaseModel?.id}` ===
                      `${props.tL?.taskList?.extPanelCodeId}`
                  );

                return `${fpc?.type}: ${fpc?.name}`;
              })()}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extPurchaseOrderId &&
          props.tL?.taskList?.extPurchaseOrderId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              PO: {props.tL?.taskList?.extPurchaseOrderId}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extRcemSubProcessId &&
          props.tL?.taskList?.extRcemSubProcessId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              RCEM Subproc: {props.tL?.taskList?.extRcemSubProcessId}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extSerialNumber &&
          props.tL?.taskList?.extSerialNumber !== "" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              SN: {props.tL?.taskList?.extSerialNumber}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extSubProcessCommonId &&
          props.tL?.taskList?.extSubProcessCommonId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Sub process common: {props.tL?.taskList?.extSubProcessCommonId}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extSupplierId &&
          props.tL?.taskList?.extSupplierId !== "0" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              Supplier: {props.tL?.taskList?.extSupplierId}
            </div>
          ) : (
            <></>
          )}

          {props.tL?.taskList?.extWoInChargeCompletedDate &&
          props.tL?.taskList?.extWoInChargeCompletedDate !== "" ? (
            <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
              PIC completed date:{" "}
              {(() => {
                try {
                  return Intl.DateTimeFormat("en-US", {
                    dateStyle: "medium",
                    timeStyle: "medium",
                    hourCycle: "h23",
                  } as any).format(
                    new Date(
                      props.tL?.taskList?.extWoInChargeCompletedDate ?? ""
                    )
                  );
                } catch (e) {
                  return "";
                }
              })()}
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* Tasks shown */}

        <div className="d-flex justify-content-between mt-1 bg-light shadow shadow-md">
          <div className="border border-dark px-1 flex-grow-1 rounded rounded-md">
            <strong>
              Tasks shown: {filteredMeetingTasks.length ?? 0} of{" "}
              {props.tL?.taskList?.meetingTasks.length ?? 0}
            </strong>
          </div>
        </div>
      </div>

      <ol>
        {filteredMeetingTasks.map((mt) => {
          return (
            <>
              <li>
                <div>
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    <small>
                      [
                      {mt.meetingTaskInCharges
                        .map(
                          (mtic) =>
                            `${
                              props.extUsers.find(
                                (u) => `${u.id}` === `${mtic.extUserId}`
                              )?.username
                            }${
                              // mtic.totalTimeHours && mtic.totalTimeHours !== 0
                              //   ? `${mtic.totalTimeHours.toFixed(1)}`
                              //   : ``
                              ""
                            }${mtic.isQc ? ` (QC)` : ``}${
                              mtic.isQa ? ` (QA)` : ``
                            }`
                        )
                        .join(";")}
                      ] ({mt.start ?? "No start"} to{" "}
                      {extractProtoMeetingTaskTargetDate(mt)?.date ??
                        "No target"}
                      ) [
                      <span
                        className={`font-weight-bold ${(() => {
                          switch (mt.status) {
                            case "OUTSTANDING":
                              return `text-danger`;
                            case "COMPLETED":
                              return `text-success`;
                            default:
                              return "";
                          }
                        })()}`}
                      >
                        {mt.status}
                      </span>
                      ], [
                      <span
                        className={`font-weight-bold ${(() => {
                          if (mt.extPicCompletedDate) {
                            return `text-success`;
                          } else {
                            return "text-danger";
                          }
                        })()}`}
                      >
                        PIC{" "}
                        {mt.extPicCompletedDate ? "COMPLETED" : "OUTSTANDING"}
                      </span>
                      ] sp:{" "}
                      {
                        props.rcemDepartments?.departments
                          .flatMap((d) => d.processes ?? [])
                          .flatMap((p) => p.subProcesses ?? [])
                          .find(
                            (sp) =>
                              `${
                                props.departmentTemplates?.templates
                                  .flatMap((dt) => dt.items ?? [])
                                  .find(
                                    (dti) =>
                                      `${dti.masterJavaBaseModel?.id}` ===
                                      `${mt.extSubProcessId}`
                                  )?.extRcemSubProcessId
                              }` === `${sp.masterJavaBaseModel?.id}`
                          )?.timeProcess
                      }
                      h, mins: {mt.durationMins}m
                      {/* {"<< "} {mt.extSubProcessId}{" "}
                    {mt.extSubProcessCommonId} {" >>"} */}
                    </small>{" "}
                    <small>{mt.description}</small>
                  </div>

                  {mt.extPanelCodeDepartmentTemplateItemId &&
                  mt.extPanelCodeDepartmentTemplateItemId !== "0" &&
                  mt.extPanelCodeDepartmentTemplateItemId !== "" ? (
                    <>
                      {(() => {
                        const foundDTI =
                          props.panelCodeDepartmentTemplateItems?.departmentTemplateItems.find(
                            (pcdti) =>
                              `${pcdti.masterJavaBaseModel?.id}` ===
                              `${mt.extPanelCodeDepartmentTemplateItemId}`
                          );
                        const foundDeptTemplateItem =
                          props.departmentTemplates?.templates
                            .map((t) =>
                              t.items.map((i) => ({ template: t, item: i }))
                            )
                            .flat()
                            .find(
                              (dti) =>
                                `${dti.item.masterJavaBaseModel?.id}` ===
                                `${foundDTI?.departmentTemplateItem?.masterJavaBaseModel?.id}`
                            );

                        return (
                          <>
                            [{foundDeptTemplateItem?.template.name}:
                            {foundDeptTemplateItem?.item.name}]
                          </>
                        );
                      })()}
                    </>
                  ) : (
                    <></>
                  )}

                  <div>
                    {mt.meetingTaskComments.map((c, j) => {
                      return (
                        <>
                          <div>
                            <div>
                              <small>
                                <strong>
                                  {j + 1}. By:{" "}
                                  {props?.extUsers.find(
                                    (u) => `${u.id}` === `${c.extUserId}`
                                  )?.username ?? "Admin Manufacturing"}{" "}
                                  (
                                  {(() => {
                                    try {
                                      return Intl.DateTimeFormat("en-US", {
                                        dateStyle: "short",
                                        timeStyle: "short",
                                        hourCycle: "h23",
                                      } as any).format(
                                        new Date(
                                          c.masterJavaBaseModel?.createdAt ?? ""
                                        )
                                      );
                                    } catch (e) {
                                      return "invalid date";
                                    }
                                  })()}
                                  )
                                </strong>
                              </small>
                            </div>
                            <div>
                              <small>{c.comment}</small>
                              {/* <textarea
                                className="form-control form-control-sm"
                                defaultValue={c.comment}
                                placeholder="Comment..."
                                key={`mt-${mt.masterJavaBaseModel?.uuid}-${mt.masterJavaBaseModel?.id}-${j}`}
                              /> */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div>
                    {mt.extSubProcessId && mt.extSubProcessId !== "0" ? (
                      <div>
                        Sub process:{" "}
                        {(() => {
                          const found = props.rcemDepartments?.departments
                            .map((d) =>
                              d.processes
                                .map((p) =>
                                  p.subProcesses.map((s) => ({
                                    dept: d,
                                    process: p,
                                    subProcess: s,
                                  }))
                                )
                                .flat()
                            )
                            .flat()
                            .find(
                              (d) =>
                                `${d.subProcess.masterJavaBaseModel?.id}` ===
                                `${mt.extSubProcessId}`
                            );

                          if (found) {
                            return (
                              <>
                                {found.dept.name}: {found.process.name}:{" "}
                                {found.subProcess.name}
                              </>
                            );
                          } else {
                            return <></>;
                          }
                        })()}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </li>
            </>
          );
        })}
      </ol>

      <div>
        <div>
          <button
            className="btn btn-sm btn-outline-primary px-1 py-0"
            onClick={() => {
              showLogs.current = !showLogs.current;
              render();
            }}
          >
            Toggle Logs
          </button>
        </div>
        {showLogs.current ? (
          <>
            <ol>
              {props.tL?.taskList?.logs.map((tL) => {
                return (
                  <>
                    <li>
                      <div>
                        <div>
                          <small>
                            <strong>
                              {" "}
                              Changer:{" "}
                              {
                                props.extUsers.find(
                                  (u) => `${tL.extUserId}` === `${u.id}`
                                )?.username
                              }{" "}
                              [
                              {meetingTaskListChangeLog_MeetingTaskListChangeLogTypeFromJSON(
                                tL.type
                              )}
                              ]{" "}
                              {formatDateTimeIntl({
                                date: tL.masterJavaBaseModel?.createdAt,
                                dateStyle: "medium",
                                timeStyle: "short",
                              })}{" "}
                              | {tL.before}
                              {" => "}
                              {tL.after}
                            </strong>
                          </small>
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
            </ol>
          </>
        ) : (
          <></>
        )}
      </div>

      <hr className="border border-dark" />
    </>
  );
};

export const mapSkillLevelToColor = (
  sl?: PpicSkillWorker_SkillLevel | null
) => {
  switch (sl) {
    case PpicSkillWorker_SkillLevel.BASIC:
      return "lightgreen";
    case PpicSkillWorker_SkillLevel.INTERMEDIATE:
      return "gold";
    case PpicSkillWorker_SkillLevel.ADVANCED:
      return "lightsalmon";
    case PpicSkillWorker_SkillLevel.EXPERT:
      return "gray";

    default:
      return "";
  }
};

export const filterWorkOrderByDate = (
  tL: MeetingTaskListsView | null | undefined,
  curDate: string,
  u: ExtUser | null | undefined,
  lessThanDate?: boolean
) =>
  tL?.taskLists?.filter((tL) =>
    // Exclude SPV of time (filter if meetingTask has meetingTaskInCharges)
    // (u ? `${tL?.taskList?.extInChargeId}` !== `${u?.id}` : true) &&
    // Meeting tasks filter
    tL?.taskList?.meetingTasks?.find(
      (mt) =>
        // Filter by pic
        (u
          ? mt?.meetingTaskInCharges?.find(
              (mtic) => `${mtic.extUserId}` === `${u?.id}`
            )
          : // || `${tL?.taskList?.extInChargeId}` === `${u?.id}`
            true) &&
        // // Filter by date
        (() => {
          try {
            const targetDate =
              extractProtoMeetingTaskTargetDate(mt)?.date ?? "";

            const startDate = mt.start ?? "";

            return lessThanDate
              ? new Date(startDate).getTime() < new Date(curDate).getTime()
              : new Date(curDate).getTime() >= new Date(startDate).getTime() &&
                  new Date(curDate).getTime() <= new Date(targetDate).getTime();
          } catch (e) {
            return false;
          }
        })()
    )
  );

export const filterWorkOrderByDateRange = (
  tL: MeetingTaskListsView | null | undefined,
  from: string,
  to: string,

  u: ExtUser | null | undefined
) =>
  tL?.taskLists?.filter((tL) =>
    // Exclude SPV of time (filter if meetingTask has meetingTaskInCharges)
    // (u ? `${tL?.taskList?.extInChargeId}` !== `${u?.id}` : true) &&
    // Meeting tasks filter
    tL?.taskList?.meetingTasks?.find(
      (mt) =>
        // Filter by pic
        (u
          ? mt?.meetingTaskInCharges?.find(
              (mtic) => `${mtic.extUserId}` === `${u?.id}`
            )
          : // || `${tL?.taskList?.extInChargeId}` === `${u?.id}`
            true) &&
        // // Filter by date
        (() => {
          try {
            const targetDate =
              extractProtoMeetingTaskTargetDate(mt)?.date ?? "";

            const startDate = mt.start ?? "";

            return (
              new Date(to).getTime() >= new Date(startDate).getTime() &&
              new Date(from).getTime() <= new Date(targetDate).getTime()
            );
          } catch (e) {
            return false;
          }
        })()
    )
  );

export const checkUnapproved = (
  materialRequests: MaterialRequest[],
  inventoryId: any
) => {
  const materialRequestsFiltered = materialRequests.filter(
    (mr) =>
      // Not cancelled
      (mr.cancellationNote === null ||
        mr.cancellationNote === undefined ||
        mr.cancellationNote === "") &&
      // And not approved
      mr.status === 0 &&
      // Search MR w/item
      mr.materialRequestItems?.find(
        (mri) => `${mri.extInventoryId}` === `${inventoryId}`
      )
  );

  return {
    mrs: materialRequestsFiltered,
    qty: materialRequestsFiltered
      .map((mr) => mr.materialRequestItems ?? [])
      .flat()
      .filter((mri) => `${mri.extInventoryId}` === `${inventoryId}`)
      .reduce((acc, mri) => acc + (mri.qty ?? 0), 0),
  };
};

export const flattenBomLeveled = (bom: {
  bom: BomLeveledRecursive | null;
  level: number[];
}): (
  | {
      bom: BomLeveledRecursive | null;
      level: number[];
    }
  | undefined
)[] => [
  { bom: bom.bom, level: bom.level },
  ...(bom?.bom?.children
    ?.map((b, i) => flattenBomLeveled({ bom: b, level: [...bom.level, i + 1] }))
    ?.flat() ?? []),
];

export const flattenBomLeveledToList = (bomLeveled: BomLeveledRecursive) =>
  bomLeveled.children
    .map((b, i) => [
      {
        bom: b,
        level: [i + 1],
      },
      ...(b?.children
        .map((bi, j) => flattenBomLeveled({ bom: bi, level: [i + 1, j + 1] }))
        .flat() ?? []),
    ])
    .flat()
    .filter((b) => b)
    .map((b) => b as { bom: BomLeveledRecursive | null; level: number[] });

export const flattenBomLeveledMultiplier = (bom: {
  bom: BomLeveledRecursive | null;
  level: number[];
  multiplier: number;
}): (
  | {
      bom: BomLeveledRecursive | null;
      level: number[];
      multiplier: number;
    }
  | undefined
)[] => [
  {
    bom: bom.bom,
    level: bom.level,
    multiplier: (bom.bom?.bomLeveled?.qty ?? 1) * bom.multiplier,
  },
  ...(bom?.bom?.children
    ?.map((b, i) =>
      flattenBomLeveledMultiplier({
        bom: b,
        level: [...bom.level, i + 1],
        multiplier: (b?.bomLeveled?.qty ?? 1) * bom.multiplier,
      })
    )
    ?.flat() ?? []),
];

export const flattenBomLeveledToListMultiplier = (
  bomLeveled: BomLeveledRecursive
) =>
  bomLeveled.children
    .map((b, i) => [
      {
        bom: b,
        level: [i + 1],
        multiplier: b?.bomLeveled?.qty ?? 0,
      },
      ...(b?.children
        .map((bi, j) =>
          flattenBomLeveledMultiplier({
            bom: bi,
            level: [i + 1, j + 1],
            multiplier: bi?.bomLeveled?.qty ?? 1,
          })
        )
        .flat() ?? []),
    ])
    .flat()
    .filter((b) => b)
    .map(
      (b) =>
        b as {
          bom: BomLeveledRecursive | null;
          level: number[];
          multiplier: number;
        }
    );

export const fetchBomLeveled = async (param: {
  apiKey?: string | null;
  id: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bomleveled/${param.id}`,
      {
        headers: {
          authorization: param?.apiKey ?? "",
        },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return (await resp.json()) as BomLeveledRecursive;
  } catch (e) {
    console.log("[failed getting bomleveled]", e);
    return null;
  }
};

export const fetchExtReserveLists = async (param: {
  apiKey?: string | null;
  delete?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-reserve-list?delete=${
        param.delete ?? ""
      }`,
      {
        headers: { authorization: param.apiKey ?? "" },
      }
    );
    return (await resp.json()) as ExtReserveList[];
  } catch (e) {
    return [];
  }
};

export const fetchExtPNDouble = async (param: {
  apiKey?: string | null;
  delete?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-pn-double`,
      {
        headers: { authorization: param.apiKey ?? "" },
      }
    );
    return (await resp.json()) as any[];
  } catch (e) {
    return [];
  }
};

export const fetchSsoUsers = async (param: { apiKey?: string | null }) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_SSO_URL}/sso-users`, {
      headers: { authorization: param.apiKey ?? "" },
    });
    return SsoUserEntitiesData.fromPartial(await resp.json());
  } catch (e) {
    return SsoUserEntitiesData.fromPartial({});
  }
};

export const jobOverviewHeaders = [
  { name: "#", sortable: false },
  { name: "Customer", sortable: true },
  { name: "Job", sortable: true },
  { name: "Product", sortable: false },

  { name: "Qty", sortable: false },

  { name: "Deadline", sortable: true },
  { name: "Status", sortable: false },
  { name: "Last QA", sortable: false },
  { name: "Priority", sortable: true },
  // { name: "No. of WOs", sortable: false },
  // { name: "No. of Tasks", sortable: false },
  { name: "Last Status", sortable: false },
  { name: "Last Plan", sortable: false },
  { name: "Man Days", sortable: false },
  // { name: "Comments", sortable: false },
  { name: "MR Simulation", sortable: false },
  { name: "Shortage List", sortable: false },
  { name: "Job Comments", sortable: false },
];
export const fetchJobOverviewHiddenCols = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/extdepartmenthiddenjoboverviewcols`,
      { headers: { authorization: params.apiKey ?? "" } }
    );

    if (resp.status !== 200) throw await resp.text();
    return PpicExtDepartmentHiddenJobOverviewCols.fromPartial(
      await resp.json()
    );
  } catch (e) {
    return PpicExtDepartmentHiddenJobOverviewCols.fromPartial({});
  }
};

export interface PrInfoReturnBody {
  jobItemUnique: {
    item: WarehouseItem | undefined;
    qty: number;
  };
  filteredInStock: number;
  filteredFreeStock: number;
  inPoSupplier: number;
  remaining: number;
  inPr: number;
}

export const getPurchaseRequestInfo = (
  jobItemsUnique: { item: WarehouseItem | undefined; qty: number }[],
  inventory: ExtInventory[],
  extPurchaseOrderSupplier: PurchasingSupplierPurchaseOrders,
  extPurchaseRequests: PurchasingPurchaseRequestItemsOutstanding,
  jobId: any
): PrInfoReturnBody[] => {
  return jobItemsUnique.map((ji, i) => {
    const filteredInventory = inventory.filter(
      (i) => `${i.products?.id}` === `${ji.item?.id}`
    );
    const filteredFreeStock = filteredInventory.reduce(
      (acc, inv) => (acc ?? 0) + (inv.qtyBalance ?? 0),
      0
    );
    const filteredInStock = filteredInventory.reduce(
      (acc, inv) => (acc ?? 0) + (inv.qty ?? 0),
      0
    );

    const inPoSupplier = extPurchaseOrderSupplier.purchaseOrders.filter(
      (pox) => `${pox.productId}` === `${ji.item?.id}`
    );
    const inPoSupplierQty = inPoSupplier.reduce(
      (acc, po) => acc + (po.qtyPos ?? 0) - (po.qtyDelivered ?? 0),
      0
    );
    const inPr = extPurchaseRequests.purchaseRequestItems
      .filter(
        (pr) =>
          `${pr.jobId}` === `${jobId}` && `${pr.productId}` === `${ji.item?.id}`
      )
      .reduce((acc, pr) => acc + (pr.qtyPr ?? 0), 0);
    // const remainingView = remaining < 0 ? 0 : remaining;

    const totalAvailable = inPoSupplierQty + filteredInStock;
    const remaining = (ji.qty ?? 0) - totalAvailable - inPr;

    return {
      jobItemUnique: ji,
      filteredFreeStock: filteredFreeStock,
      filteredInStock: filteredInStock,
      inPoSupplier: inPoSupplierQty,
      remaining: remaining,
      inPr: inPr,
    };
  });
};
export const fetchExtPurchaseRequests = async (params: {
  apiKey?: string | null;
  from?: any;
  to?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-purchase-requests?from=${
        params.from ?? ""
      }&to=${params.to ?? ""}`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    const purchaseRequests = (await resp.json()) as ExtPurchaseRequest[];
    purchaseRequests.reverse();

    return purchaseRequests;
  } catch (e) {
    return [];
  }
};

export const fetchExtPurchaseRequestsItems = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-purchase-requests-items`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    const purchaseRequests =
      PurchasingPurchaseRequestItemsOutstanding.fromPartial(await resp.json());
    purchaseRequests.purchaseRequestItems.reverse();

    return purchaseRequests;
  } catch (e) {
    return PurchasingPurchaseRequestItemsOutstanding.fromPartial({});
  }
};

export const fetchMachineProgramMachinesProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/machineprogrammachines`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return PpicMachineProgramMachines.fromPartial(await resp.json());
  } catch (e) {
    return PpicMachineProgramMachines.fromPartial({});
  }
};

export const searchItems = (items: WarehouseItems | null, searchItem: string) =>
  (items?.items ?? [])?.filter(
    (i) =>
      searchItem !== "" &&
      (() => {
        const searchWordResult = searchItem
          .toLowerCase()
          .split(" ")
          .map((w) =>
            `${i.mfr}${i.partDesc}${i.partName}${i.partNum}`
              .toLowerCase()
              .includes(w)
          );

        return (
          searchWordResult.filter((r) => r).length === searchWordResult.length
        );
      })()
  );
export const mapIntegrationModulesToWorkOrders = (
  iL: JobIntegrationListView | null,
  modules: IntegrationModuleView[],
  job: Job,
  pc: PanelCode | null | undefined
) => {
  let woFlattened: MeetingTaskList[] = [];

  const flattenWo = (
    im: IntegrationModuleView | null | undefined,
    multiplier: number
  ) => {
    console.log("[integrationmodule]:", im);
    woFlattened = [
      ...woFlattened,
      MeetingTaskList.fromPartial({
        masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
          uuid: uuidv4(),
        }),
        extJobId: job?.id ? `${job.id}` : undefined,
        extSetId:
          im?.integrationModule?.type === "SET"
            ? `${im.integrationModule.id}`
            : undefined,
        extModuleId:
          im?.integrationModule?.type === "MODULE"
            ? `${im.integrationModule.id}`
            : undefined,
        extSubModuleId:
          im?.integrationModule?.type === "SUBMODULE"
            ? `${im.integrationModule.id}`
            : undefined,

        extIntegrationModuleQty:
          (iL?.jobIntegrationList?.panelCode?.qty ?? 0) * multiplier,

        extPanelCodeId: iL?.jobIntegrationList?.panelCode?.id
          ? `${iL?.jobIntegrationList?.panelCode?.id}`
          : undefined,

        meetingTasks: [
          MeetingMeetingTask.fromPartial({
            description: `Integrate ${im?.integrationModule?.type}: ${im?.integrationModule?.name}`,
            masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
              uuid: uuidv4(),
            }),
            meetingTaskInCharges: [
              ...(im?.integrationModule?.type === "SET" && pc?.extDefaultQcId
                ? [
                    MeetingMeetingTaskInCharge.fromPartial({
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                      extUserId: `${pc?.extDefaultQcId}`,
                      isQc: true,
                    }),
                  ]
                : []),
              ...(im?.integrationModule?.type === "SET" &&
              pc?.extDefaultQaId !== 0
                ? [
                    MeetingMeetingTaskInCharge.fromPartial({
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                      extUserId: `${pc?.extDefaultQaId}`,
                      isQa: true,
                    }),
                  ]
                : []),
            ],
          }),
        ],
      }),
    ];

    im?.integrationModules?.forEach((imx) => {
      flattenWo(imx, (imx.integrationModule?.qty ?? 0) * multiplier);
    });
  };

  modules.forEach((im) => {
    console.log("[pre integratiomodule]", im);
    flattenWo(im, im.integrationModule?.qty ?? 0);
  });

  return woFlattened;
};

// Use defaultJobIntegrationList: If you want to map from jobIntegrationList (root is job)
// Use integrations: If you want to map from integration (root is product tree)
export const flattenModules = (
  jobIntegrationList?: JobIntegrationListView[] | null,
  integrations?: IntegrationView[] | null,
  integrationModules?: IntegrationModuleView[] | null
) => {
  let modules: {
    integration: Integration | null;
    module: IntegrationModuleView;
  }[] = [];

  const flattenModulesRecursive = (
    i: IntegrationModuleView | null,
    integration: Integration | null
  ) => {
    if (i) {
      modules = [...modules, { integration: integration, module: i }];
    }

    i?.integrationModules?.forEach((im) => {
      flattenModulesRecursive(im, integration);
    });
  };

  // Map job integration list
  jobIntegrationList?.forEach((iL) => {
    iL.integrationRecursive?.integrationModules?.map((m) => {
      flattenModulesRecursive(m, iL.integrationRecursive?.integration ?? null);
    });
  });

  // Map integrations
  integrations?.forEach((i) => {
    i?.integrationModules?.map((m) => {
      flattenModulesRecursive(m, i.integration);
    });
  });
  // Map integration modules
  integrationModules?.map((m) => {
    flattenModulesRecursive(m, null);
  });

  return modules;
};

export const progressColor = (done: number, needed: number) => {
  if (done <= 0) {
    return "lightsalmon";
  } else if (done < needed) {
    return "gold";
  } else {
    return "lightgreen";
  }
};

export interface UserLocation {
  extUserId?: number | null;
  lat?: number | null;
  lon?: number | null;

  createdAt?: string | null;
}
export const fetchLastLocationData = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_LOCATION_URL}/locationrecords-users`,
      { headers: { authorization: params.apiKey ?? "" } }
    );
    return (await resp.json()) as UserLocation[];
  } catch (e) {
    return null;
  }
};

export const fetchTaskListTemplates = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/tasklisttemplates-proto`,
      { headers: { authorization: params.apiKey ?? "" } }
    );

    if (resp.status !== 200) throw await resp.text();

    return MeetingMeetingTaskListTemplates.fromPartial(await resp.json());
  } catch (e) {
    return MeetingMeetingTaskListTemplates.fromPartial({ templates: [] });
  }
};
export const fetchTaskListTemplate = async (params: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/tasklisttemplates-proto/${params.id}`,
      { headers: { authorization: params.apiKey ?? "" } }
    );

    if (resp.status !== 200) throw await resp.text();

    return MeetingMeetingTaskListTemplate.fromPartial(await resp.json());
  } catch (e) {
    return MeetingMeetingTaskListTemplate.fromPartial({});
  }
};

export const haversine = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres

  return Math.abs(d);
};

export const fetchJobFull = async (params: { apiKey: any; id: any }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/jobs/${params.id}/full?type=jobInput&compose=true`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    const job = (await response.json()) as JobMapped;

    // Map jobTypes to jobTypeSelections
    const newJobTypeSelections = job.job?.jobTypes
      ?.map((jobType) => {
        switch (jobType.name) {
          case "Cabinet":
            return JobTypeSelection.Cabinet;

          case "Wiring":
            return JobTypeSelection.Wiring;

          default:
            return null;
        }
      })
      .filter((jobType): jobType is JobTypeSelection => jobType !== null);

    // Map jobPriority to JobPriority
    const jobPriority = (() => {
      switch (job.job?.priority) {
        case "Low":
          return JobPriority.Low;

        case "Medium":
          return JobPriority.Medium;

        case "High":
          return JobPriority.High;

        default:
          return JobPriority.Low;
      }
    })();
    return {
      jobPriority: jobPriority,
      jobTypeSelections: newJobTypeSelections,
      job: job,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchAttendanceData = async (params: { date: Date }) => {
  try {
    const resp = await fetch(
      `${
        process.env.REACT_APP_LOCATION_URL
      }/locationrecords-checkin?from=${new Date(
        `${makeDateString(params.date)}T00:00`
      ).toISOString()}&to=${new Date(
        `${makeDateString(params.date)}T23:59`
      ).toISOString()}&taskDate=${makeDateString(params.date)}`
    );
    return (await resp.json()) as UserFirstCheckIn[];
  } catch (e) {
    return [];
  }
};

export const fetchAttendanceKpiData = async (params: { range: KpiRange }) => {
  try {
    const foundKpiRangeDetail = aliases.find((a) => a.range === params.range);

    // if (!foundKpiRange) {

    const paramsRange =
      params.range >= 0
        ? 86400000 * (1 + Math.abs(params.range))
        : 86400000 * -(1 + Math.abs(params.range));

    const dateA = new Date(
      `${makeDateString(new Date())}T00:00:00`
    ).toISOString();

    const dateB = new Date(
      `${makeDateString(new Date(new Date().getTime() + paramsRange))}T00:00:00`
    ).toISOString();

    console.log("date a:", dateA, "date b:", dateB);

    const from = params.range >= 0 ? dateA : dateB;
    const to = params.range >= 0 ? dateB : dateA;

    const resp = await fetch(
      `${process.env.REACT_APP_LOCATION_URL}/locationrecords-kpi?from=${from}&to=${to}`
    );
    return MeetingKpi.fromPartial(await resp.json());
  } catch (e) {
    return MeetingKpi.fromPartial({});
  }
};

export const fetchAttendanceNotes = async (params: {
  apiKey: any;
  from?: any;
  to?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/attendancenotes?from=${
        params.from ?? ""
      }&to=${params.to ?? ""}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as AttendanceNote[];
  } catch (e) {
    console.error(e);
    return [];
  }
};
export const fetchAttendanceNoteTypes = async (params: { apiKey: any }) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/attendancenotetypes`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as AttendanceNoteType[];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const padNumber = (n: any, padLength: number) =>
  `${n}`.length < padLength
    ? `${[...Array(padLength - `${n}`.length)].map((_) => `0`).join("")}${n}`
    : `${n}`;

export const getPanelCodeCompleteSerialNumber = (
  panelCode: PanelCode | null | undefined,
  pcsn: PanelCodeSerialNumber | null | undefined
) =>
  `${(() => {
    switch (panelCode?.panelCodeType) {
      case "CabinetOnly":
        return `600Y`;
      case "WiringOnly":
        return `700W`;
      case "CabinetWiring":
        return `800X`;
      case "Other":
        return `900Z`;
      default:
        return `100A`;
    }
  })()}${padNumber(pcsn?.sequentialSerialNumber ?? 0, 4)}`;

export const getPanelCodeCompleteSerialNumberProto = (
  panelCode: PpicPanelCode | null | undefined,
  pcsn: PpicPanelCodeSerialNumber | null | undefined
) =>
  `${(() => {
    switch (panelCode?.panelCodeType) {
      case "CabinetOnly":
        return `600Y`;
      case "WiringOnly":
        return `700W`;
      case "CabinetWiring":
        return `800X`;
      case "Other":
        return `900Z`;
      default:
        return `100A`;
    }
  })()}${padNumber(pcsn?.sequentialSerialNumber ?? 0, 4)}`;

export const getWarehouseItemDescription = (
  i: WarehouseItem | null | undefined
) => `${i?.partNum}: ${i?.partName}: ${i?.partDesc} `;

export const fetchRemoteControlPresets = async (params: { apiKey: any }) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/remotecontrolpresets`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return PpicRemoteControlPresets.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return PpicRemoteControlPresets.fromPartial({ presets: [] });
  }
};

export const fetchPanelCodeDepartmentTemplatePresetsProto = async (params: {
  apiKey: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/panelcodedepartmenttemplatepresets-proto`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return PpicPanelCodeDepartmentTemplatePresets.fromPartial(
      await resp.json()
    );
  } catch (e) {
    console.error(e);
    return PpicPanelCodeDepartmentTemplatePresets.fromPartial({ presets: [] });
  }
};

export const fetchJobInfoProto = async (params: { apiKey: any }) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/job-info`, {
      headers: { authorization: params.apiKey ?? "" },
    });

    if (resp.status !== 200) throw await resp.text();

    return PpicJobInfoList.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return PpicJobInfoList.fromPartial({ info: [] });
  }
};

export const fetchKpiDeptRes = async (params: {
  apiKey: any;
  deptId?: any;
  bypassCache?: any;
  todayRef?: string;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/kpi-dept-res?deptId=${
        params.deptId ?? ""
      }&bypassCache=${params.bypassCache ?? ""}&todayRef=${
        params.todayRef ?? ""
      }`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return KpiDeptCache.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return null;
  }
};
export const fetchMacroCategoriesProto = async (params: { apiKey: any }) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/macrocategories`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return PpicMacroCategories.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return PpicMacroCategories.fromPartial({ categories: [] });
  }
};

export const makeDateTimeLocal = (d?: Date | null) => ``;

export const remoteControlPresetToPayload = (p: PpicRemoteControlPreset) =>
  PpicRemoteControlPayload.fromPartial({
    screen: p.screen,
    deptId: p.extDepartmentId,
    jobId: p.jobId,
    extUserId: p.extUserId,
    workOrderType: p.workOrderType,
    skipDate: p.skipDate,
    presetRecursive: p.presetRecursive,
    picOnly: p.picOnly,
    quadScreen: p.quadScreen,
    jdpPresetId: p.jdpPresetId,
    extDeptVersusId: p.extDeptVersusId,
    extProblemCatalogId: p.extProblemCatalogId,
    quadScreenV2: p.quadScreenV2,
    refresh: p.refresh,
    filterTaskDateOnly: p.filterTaskDateOnly,
    woGraph: p.woGraph,
  });
export const remoteControlPayloadToPreset = (p: PpicRemoteControlPayload) =>
  PpicRemoteControlPreset.fromPartial({
    screen: p.screen,
    extDepartmentId: p.deptId,
    jobId: p.jobId,
    extUserId: p.extUserId,
    workOrderType: p.workOrderType,
    skipDate: p.skipDate,
    presetRecursive: p.presetRecursive,
    picOnly: p.picOnly,
    quadScreen: p.quadScreen,
    jdpPresetId: p.jdpPresetId,
    extDeptVersusId: p.extDeptVersusId,
    extProblemCatalogId: p.extProblemCatalogId,
    quadScreenV2: p.quadScreenV2,
    refresh: p.refresh,
    filterTaskDateOnly: p.filterTaskDateOnly,
    woGraph: p.woGraph,
  });
export const picOnlySelect = [
  { label: "PIC", value: true },
  { label: "SPV", value: false },
  { label: "Both", value: null },
];

export const sendRemoteByName = (
  remoteControlPresets: PpicRemoteControlPresets,
  n: string | undefined
) => {
  console.log(
    "send remote by name",
    remoteControlPresets,
    n,
    remoteControlPresets.presets.filter((p) =>
      n && n !== "" ? p.name?.toLowerCase() === n.toLowerCase() : true
    )
  );
  if (n) {
    remoteControlPresets.presets
      .filter((p) =>
        n && n !== "" ? p.name?.toLowerCase() === n.toLowerCase() : true
      )
      .forEach((p) => {
        const client = new PahoMQTT.Client(
          `${process.env.REACT_APP_FRONTEND_MQTT_WS_URL}/mqtt`,

          `client-${p.masterJavaBaseModel?.uuid}-${new Date().getTime()}`
        );
        console.log("user id:", p?.userId);
        client.connect({
          onSuccess: () => {
            const message = new PahoMQTT.Message(
              JSON.stringify(remoteControlPresetToPayload(p))
            );

            console.log("mSG:", remoteControlPresetToPayload(p));

            message.destinationName = `remote/${p.userId}`;
            client.send(message);
          },
        });
      });
  }
};
export const getSkillLevelIcon = (l: PpicSkillWorker_SkillLevel) => {
  const num = ppicSkillWorker_SkillLevelToNumber(l);
  const color = (() => {
    switch (num) {
      case 3:
        return "black";
      case 2:
        return "red";
      case 1:
        return "orange";
      case 0:
        return "green";
    }
  })();

  return (
    <>
      <div>
        <div className="d-flex" style={{ color: color }}>
          {[...Array(num + 1)].map((_, i) => "⬤")}
        </div>
      </div>
    </>
  );
};

export const getUserDetail = (u?: ExtUser | null) =>
  `${u?.username}: ${u?.name}: ${u?.departmentName}`;

export const jobDeptCategories = [
  { label: "PPC", value: "PPC" },
  { label: "General", value: "OTHERS" },
  { label: "Internal", value: "INTERNAL" },
  { label: "Product Only", value: "PRODUCT_ONLY" },
] as { label: string; value: JobDeptCategory }[];

export const jobClosedStatuses = [
  { label: "All", value: null },
  { label: "Closed", value: true },
  { label: "Outstanding", value: false },
];

export const fetchHolidaysProto = async (params: { apiKey: any }) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/holidays`, {
      headers: { authorization: params.apiKey ?? "" },
    });

    if (resp.status !== 200) throw await resp.text();

    return PpicHolidays.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return PpicHolidays.fromPartial({ holidays: [] });
  }
};

export const fetchProblemCatalogs = async (params: { apiKey: any }) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_MEETING_URL}/problemcatalogs`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return MeetingProblemCatalogs.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return MeetingProblemCatalogs.fromPartial({ catalogs: [] });
  }
};

export interface OSRMObject {
  routes?: OSRMRoute[];
}

export interface OSRMRoute {
  legs?: OSRMLeg[];
}

export interface OSRMLeg {
  steps?: OSRMStep[];
}
export interface OSRMStep {
  maneuver?: OSRMManeuver;
}

export interface OSRMManeuver {
  location?: number[];
}

export const fetchLocationRecordsDateRange = async (params: {
  from: string | null | undefined;
  to: string | null | undefined;
  apiKey: string | null | undefined;
}) => {
  try {
    const resp = await fetch(
      `${
        process.env.REACT_APP_LOCATION_URL
      }/locationrecords-daterange?sinceDate=${new Date(
        `${params.from}`
      ).toISOString()}&untilDate=${new Date(`${params.to}`).toISOString()}`,
      { headers: { authoriation: params.apiKey ?? "" } }
    );

    return (await resp.json()) as LocationRecord[];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const timeDiff = (params: { future: string; past: string }) => {
  try {
    const diffSecsMicro =
      new Date(params.future).getTime() - new Date(params.past).getTime();

    const diffSecs = Math.floor(diffSecsMicro / 1000);
    const diffMins = Math.floor(diffSecsMicro / 60000);
    const diffHours = Math.floor(diffSecsMicro / 3600000);
    const diffDays = Math.floor(diffSecsMicro / 86400000);
    const diffWeek = Math.floor(diffSecsMicro / 7 / 86400000);
    const diffMonth = Math.floor(diffSecsMicro / 28 / 86400000);
    const diffYear = Math.floor(diffSecsMicro / 365 / 86400000);

    console.log(
      diffYear,
      diffMonth,
      diffWeek,
      diffDays,
      diffHours,
      diffMins,
      diffSecs
    );

    if (diffYear !== 0 && diffYear > 0) {
      return `${diffYear}y`;
    } else if (diffMonth !== 0 && diffMonth > 0) {
      return `${diffMonth}mo`;
    } else if (diffWeek !== 0 && diffWeek > 0) {
      return `${diffWeek}w`;
    } else if (diffDays !== 0 && diffDays > 0) {
      return `${diffDays}d`;
    } else if (diffHours !== 0 && diffHours > 0) {
      return `${diffHours}h`;
    } else if (diffMins !== 0 && diffMins > 0) {
      return `${diffMins}m`;
    } else if (diffSecs !== 0 && diffSecs > 0) {
      return `${diffSecs}s`;
    } else {
      return `0`;
    }
  } catch (e) {
    return "0";
  }
};

export const skipDateHop = (currentDateRef: Date, skip: number) => {
  let currentDateRefNow = new Date(currentDateRef);
  let hop = 0;

  if (skip >= 0) {
    [...Array(skip)].forEach((_, i) => {
      let skipOk = false;

      while (!skipOk) {
        currentDateRefNow = new Date(currentDateRefNow.getTime() + 86400000);
        hop++;

        console.log("skip ref no", i, currentDateRefNow);

        // Sunday and saturday, skip
        if (
          currentDateRefNow.getDay() !== 0 &&
          currentDateRefNow.getDay() !== 6
        ) {
          skipOk = true;
        }
      }
    });
  } else {
    currentDateRefNow = new Date(currentDateRefNow.getTime() + 86400000 * skip);
    hop += skip;
  }

  return { date: currentDateRefNow, hop: hop };
};

export const parseNominatimAddress = (d: any) =>
  d?.address?.village
    ? d?.address?.village
    : d?.address?.suburb
    ? d?.address?.suburb
    : d?.address?.city_district
    ? d?.address?.city_district
    : d?.address?.city
    ? d?.address?.city
    : d?.display_name;
export const findKpiUser = (
  kpiRangeData: {
    range: KpiRange;
    kpi: MeetingKpi;
    loading: boolean;
  }[],
  r: KpiRange,
  deptId: any,
  ctx: IAppState | null
) => {
  const foundKpi = kpiRangeData.find((k) => `${k.range}` === `${r}`);

  return {
    foundKpi: foundKpi,
    data: foundKpi?.kpi.users.filter(
      (u) =>
        `${
          ctx?.extUsers.find((ux) => `${ux.id}` === `${u.user?.gormModel?.id}`)
            ?.departmentId
        }` === `${deptId}`
    ),
  };
};

export const fetchExtCrmDjos = async (params: { apiKey?: string | null }) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/ext-crm-djos`, {
      headers: { authorization: params?.apiKey ?? "" },
    });

    if (resp.status !== 200) throw await resp.text();
    return CrmDJOs.fromPartial(await resp.json());
  } catch (e) {
    return CrmDJOs.fromPartial({ djos: [] });
  }
};

export const fetchExtAuthserverEmployeeShifts = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_AUTHSERVER_URL}/employeeshifts`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return AuthserverUserShifts.fromPartial(await resp.json());
  } catch (e) {
    return AuthserverUserShifts.fromPartial({ shifts: [] });
  }
};

export const fetchExtAuthserverEmployeeShiftTemplates = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_AUTHSERVER_URL}/employeeshifttemplates`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();
    return AuthserverShiftTemplates.fromPartial(await resp.json());
  } catch (e) {
    return AuthserverShiftTemplates.fromPartial({ templates: [] });
  }
};

export const extractAttendanceKpiMonthData = (
  ctx: IAppState | null,
  kpiAttendanceRanges: {
    range: KpiRange;
    kpi: MeetingKpi;
  }[],
  r: KpiRange,
  deptId: string | null | undefined
) => {
  const foundAttendanceRangeKpi = kpiAttendanceRanges.find(
    (rx) => rx.range === r
  );

  const attendanceKpiUsers = foundAttendanceRangeKpi?.kpi.users.filter(
    (u) =>
      `${
        ctx?.extUsers.find((ux) => `${ux.id}` === `${u.user?.gormModel?.id}`)
          ?.departmentId
      }` === `${deptId}`
  );

  const attendanceKpi = attendanceKpiUsers?.reduce(
    (acc, u) => acc + (u.attendance?.checkIns ?? 0),
    0
  );

  const hrs = ((attendanceKpi ?? 0) * 8) / (1 + Math.abs(r));

  return {
    foundAttendanceRangeKpi,
    attendanceKpiUsers,
    attendanceKpi,
    hrs,
  };
};

export const getChromaColor = (num: number) =>
  chroma.scale(["lightsalmon", "yellow", "lightgreen"])(num).hex();

export const ExtDepartmentLastPlan = (props: {
  lastPlan: MeetingLastPlanDate;
}) => {
  // const [lastPlan, setLastPlan] = useState(MeetingLastPlanDate.fromPartial({}));

  // const fetchLastPlan = async () => {
  //   try {
  //     const resp = await fetch(
  //       `${process.env.REACT_APP_MEETING_URL}/last-plan?extDepartmentId=${
  //         props.extDepartmentId ?? ""
  //       }`
  //     );

  //     if (resp.status !== 200) throw await resp.text();

  //     setLastPlan(MeetingLastPlanDate.fromPartial(await resp.json()));
  //   } catch (e) {}
  // };
  // useEffect(() => {
  //   fetchLastPlan();
  // }, []);

  return (
    <>
      <div>
        {props.lastPlan?.date ? (
          <>
            <small>
              <strong>
                {(() => {
                  try {
                    return Intl.DateTimeFormat(navigator.language ?? "id-ID", {
                      dateStyle: "long",
                    } as any).format(new Date(props.lastPlan.date ?? ""));
                  } catch (e) {}
                })()}
              </strong>
            </small>
          </>
        ) : (
          <>
            {" "}
            <div className="spinner-border spinner-border-sm"></div>
          </>
        )}
      </div>
    </>
  );
};

export const getProblemCatalogFullName = (
  depts: ExtDepartment[],
  c: MeetingProblemCatalog
) =>
  `${depts.find((d) => `${d.id}` === `${c?.extDepartmentId}`)?.name}: ${
    c?.problemType ? meetingProblemTypeToJSON(c?.problemType) : ""
  } ${c?.problemIntIdentifier}: ${
    depts.find((d) => `${d.id}` === `${c?.extDepartmentVersusId}`)?.name
  }`;

export const deptKpiPastAliases = [
  {
    alias: "TO",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_TODAY,
  },
  {
    alias: "LD",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_LAST_DAY,
  },
  {
    alias: "LW",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_LAST_WEEK,
  },
  {
    alias: "LM",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_LAST_MONGH,
  },
  {
    alias: "LQ",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_LAST_QUARTER,
  },
];

export const kpiDeptFutureAliases = [
  {
    alias: "TO",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_TODAY,
  },
  {
    alias: "ND",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_DAY,
  },
  {
    alias: "CW",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_CURRENT_WEEK,
  },
  {
    alias: "NW",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_WEEK,
  },

  {
    alias: "CM",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_CURRENT_MONTH,
  },

  {
    alias: "NM",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_MONTH,
  },
  {
    alias: "NM+1",
    range: KpiDeptCacheCategoryRangeEnum.RANGE_NEXT_MONTH_PLUS_ONE,
  },
];

export const kpiPastDeptCategoryAliases = [
  {
    alias: "Alpha",
    category: KpiDeptCacheCategoryEnum.CATEG_ALPHA,
    type: "double",
  },
  {
    alias: "MPAssist",
    category: KpiDeptCacheCategoryEnum.CATEG_MANPOWER_ASSIST,
    type: "int",
  },
  {
    alias: "Avail MP",
    category: KpiDeptCacheCategoryEnum.CATEG_AVAIL_MP,
    type: "double",
  },
  {
    alias: "WO Hist",
    category: KpiDeptCacheCategoryEnum.CATEG_WO_HISTORY,
    type: "int",
  },
  {
    alias: "Cap.Actual",
    category: KpiDeptCacheCategoryEnum.CATEG_CAP_ACTUAL,
    type: "double",
  },
  {
    alias: "Cap.Plan",
    category: KpiDeptCacheCategoryEnum.CATEG_CAP_PLAN,
    type: "double",
  },
  {
    alias: "Ready to Assign",
    category: KpiDeptCacheCategoryEnum.CATEG_READY_TO_ASSIGN,
    type: "int",
  },

  {
    alias: "Overdue",
    category: KpiDeptCacheCategoryEnum.CATEG_OVERDUE,
    type: "int",
  },
  {
    alias: "Quality",
    category: KpiDeptCacheCategoryEnum.CATEG_QUALITY_PROB,
    type: "int",
  },
  {
    alias: "Cost",
    category: KpiDeptCacheCategoryEnum.CATEG_COST_PROB,
    type: "int",
  },
];

export const kpiFutureDeptCategoryAliases = [
  {
    alias: "Cap.Plan",
    category: KpiDeptCacheCategoryEnum.CATEG_CAP_PLAN,
    type: "double",
  },
  {
    alias: "Overdue",
    category: KpiDeptCacheCategoryEnum.CATEG_OVERDUE,
    type: "int",
  },
  {
    alias: "Quality",
    category: KpiDeptCacheCategoryEnum.CATEG_QUALITY_PROB,
    type: "int",
  },
  {
    alias: "Cost",
    category: KpiDeptCacheCategoryEnum.CATEG_COST_PROB,
    type: "int",
  },
  // {
  //   alias: "J.Outs",
  //   category: KpiDeptCacheCategoryEnum.CATEG_JOB_OUTS,
  //   type: "int",
  // },
  {
    alias: "WO Out.",
    category: KpiDeptCacheCategoryEnum.CATEG_WO_OUTS,
    type: "int",
  },
  {
    alias: "Int.",
    category: KpiDeptCacheCategoryEnum.CATEG_INT_ISSUES,
    type: "int",
  },
  {
    alias: "Ext.",
    category: KpiDeptCacheCategoryEnum.CATEG_EXT_ISSUES,
    type: "int",
  },

  {
    alias: "J.Today",
    category: KpiDeptCacheCategoryEnum.CATEG_JOB_TODAY,
    type: "string",
  },
  {
    alias: "J.Tmrw",
    category: KpiDeptCacheCategoryEnum.CATEG_JOB_TOMORROW,
    type: "string",
  },
  {
    alias: "L.Plan",
    category: KpiDeptCacheCategoryEnum.CATEG_LAST_PLAN,
    type: "string",
  },
];

export const MeetingTaskDetailById = (props: {
  apiKey: any;
  id: any;
  users: ExtUser[];
  jobsProto?: PpicJobs | null | undefined;
  problemCatalog?: MeetingProblemCatalog;
  meetingTaskProblemCatalog?: MeetingMeetingTaskProblemCatalog;
}) => {
  const [meetingTask, setMeetingTask] = useState<MeetingMeetingTask | null>(
    null
  );
  const [taskList, setTaskList] = useState<MeetingTaskListView | null>(null);

  useEffect(() => {
    getMeetingTaskData();
  }, []);

  const getMeetingTaskData = async () => {
    try {
      const resp = await fetchMeetingTaskProto({
        apiKey: props.apiKey ?? "",
        id: props?.id ?? "",
      });

      setMeetingTask(resp);

      getTaskListData({ id: resp.taskListId });
    } catch (e) {}
  };

  const getTaskListData = async (propsx: { id: any }) => {
    try {
      const resp = await fetchWorkOrderProtoMongo({
        apiKey: props.apiKey ?? "",
        id: propsx?.id ?? "",
      });

      setTaskList(resp);
    } catch (e) {}
  };

  const foundJob = props.jobsProto?.jobs.find(
    (j) =>
      `${j.masterJavaBaseModel?.id}` === `${taskList?.taskList?.extJobId}` ||
      j.panelCodes.find(
        (c) =>
          `${c.masterJavaBaseModel?.id}` ===
          `${taskList?.taskList?.extPanelCodeId}`
      )
  );
  const foundPanelCode = props.jobsProto?.jobs
    .flatMap((j) => j.panelCodes)
    .find(
      (c) =>
        `${c.masterJavaBaseModel?.id}` ===
        `${taskList?.taskList?.extPanelCodeId}`
    );

  return (
    <>
      {meetingTask ? (
        meetingTask.start &&
        meetingTask.meetingTaskTargetDates.slice(-1)?.[0].date ? (
          <>
            <li>
              <div>
                <div>
                  <small>
                    {meetingTask?.description ?? ""} [
                    {meetingTask?.meetingTaskInCharges
                      .map(
                        (c) =>
                          props.users.find(
                            (u) => `${u.id}` === `${c.extUserId}`
                          )?.username
                      )
                      .join(",")}
                    , {meetingTask.start} -{" "}
                    {meetingTask.meetingTaskTargetDates.slice(-1)?.[0]?.date}]
                  </small>
                </div>
              </div>

              <div className="d-flex justify-content-start">
                {foundJob ? (
                  <>
                    <small>
                      Job: <strong>{foundJob.name}</strong>
                    </small>
                  </>
                ) : (
                  <></>
                )}
                {foundPanelCode ? (
                  <>
                    <small>
                      , Panel code:{" "}
                      <strong>
                        {foundPanelCode.type}:{foundPanelCode.name}:
                        {foundPanelCode.qty} unit(s)
                      </strong>
                    </small>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <small>
                  #{props.meetingTaskProblemCatalog?.meetingTaskId}, (Created{" "}
                  {(() => {
                    try {
                      const d = new Date(
                        props.problemCatalog?.masterJavaBaseModel?.createdAt ??
                          ""
                      );

                      return `${Intl.DateTimeFormat("en-US", {
                        dateStyle: "long",
                      } as any).format(d)} ${d.getHours()}:${d.getMinutes()} `;
                    } catch (e) {
                      return "";
                    }
                  })()}
                  ) , Remark:{" "}
                  <strong
                    className={`${
                      props.meetingTaskProblemCatalog?.remark &&
                      props.meetingTaskProblemCatalog?.remark !== ""
                        ? ``
                        : `text-danger`
                    }`}
                  >
                    {" "}
                    {props.meetingTaskProblemCatalog?.remark &&
                    props.meetingTaskProblemCatalog?.remark !== ""
                      ? props.meetingTaskProblemCatalog?.remark
                      : "No remark"}
                  </strong>
                  , PIC:{" "}
                  <strong>
                    {
                      props.users.find(
                        (u) =>
                          `${u.id}` ===
                          `${props.meetingTaskProblemCatalog?.extUserId}`
                      )?.username
                    }
                  </strong>
                  , target:{" "}
                  <strong>{props.meetingTaskProblemCatalog?.targetDate}</strong>
                </small>
              </div>
            </li>
          </>
        ) : (
          <></>
        )
      ) : (
        <>
          {" "}
          <div className="spinner-border spinner-border-sm"></div>
        </>
      )}
    </>
  );
};

export const pertaliteFuelBillConfig = {
  max: 150,
  bills: [
    {
      from: 0,
      to: 50,
      billIdr: 80000,
    },
    {
      from: 50,
      to: 100,
      billIdr: 160000,
    },
    {
      from: 100,
      to: 150,
      billIdr: 240000,
    },
  ],
};

export const extractItemDesc = (
  i: WarehouseItem | null | undefined,
  options: { skipLowercase?: boolean }
) => {
  const s = `#${i?.id} ${i?.mfr} ${i?.partNum} ${i?.partName} ${i?.partDesc}`;

  if (options.skipLowercase) {
    return s;
  }
  return s.toLowerCase();
};

export const calculateProjectProgress = (props: {
  project: MeetingProject;
  templates: MeetingProjectMasterTemplates;
  jobs: Job[];
  presets: MeetingMasterTemplatePresets;
}) => {
  const masterTemplates = props.project.products.flatMap((p) =>
    p.masterTemplates.map((m) => ({
      product: p,
      masterTemplate: m,
    }))
  );

  const templatesFiltered = props.project.products
    .map((p) => {
      return (
        props.templates?.templates.filter((t) => {
          if (
            !t.extDepartmentTemplateId &&
            p.masterTemplates.find(
              (tx) =>
                `${tx.masterTemplateId}` === `${t.masterJavaBaseModel?.id}`
            )?.date
          ) {
            return true;
          }

          return props.jobs
            ?.flatMap((j) => j.panelCodes)
            .find(
              (c) =>
                `${c?.extCrmPurchaseOrderItemId}` ===
                `${p.extCrmPurchaseOrderItemId}`
            )
            ?.panelCodeDepartmentTemplateItems?.find(
              (i) =>
                `${i.departmentTemplateItem?.id}` ===
                `${t.extDepartmentTemplateId}`
            );
        }) ?? []
      );
    })
    .flat();

  console.log(
    `project ${props.project.name} products: ${props.project.products.length}, master templates ${templatesFiltered.length} (${props.templates?.templates.length})`
  );

  const templatesFiltered2 = props.project.products
    .map((p) =>
      p.masterTemplates.map((t) => ({
        product: p,
        masterTemplate: t,
      }))
    )
    .flat()
    .filter((t) => {
      const foundProductTemplate = props.templates?.templates.find(
        (tx) =>
          `${tx.masterJavaBaseModel?.id}` ===
          `${t.masterTemplate.masterTemplateId}`
      );

      if (!foundProductTemplate?.extDepartmentTemplateId) {
        return true;
      }

      const foundJobPanelCode = props.jobs
        ?.flatMap((j) => j.panelCodes?.map((c) => ({ job: j, panelCode: c })))
        .find(
          (c) =>
            `${c?.panelCode.extCrmPurchaseOrderItemId}` ===
            `${t.product.extCrmPurchaseOrderItemId}`
        )
        ?.panelCode.panelCodeDepartmentTemplateItems?.find(
          (i) =>
            `${i.departmentTemplateItem?.id}` ===
            `${foundProductTemplate?.extDepartmentTemplateId}`
        );

      return foundJobPanelCode;
    });

  console.log(
    `project ${props.project.name}: ${templatesFiltered2.length}`,
    templatesFiltered2
  );

  const currentWeekProgress =
    masterTemplates.filter((t) => t.masterTemplate.completed).length /
    (templatesFiltered.length && templatesFiltered.length !== 0
      ? templatesFiltered.length
      : 1);

  const productPresetInfo = props.project.products.map((pr, i) => {
    const foundPreset = props.presets.presets.find(
      (prx) =>
        `${prx.masterJavaBaseModel?.id}` === `${pr.projectTemplatePresetId}`
    );

    const templateItems = foundPreset
      ? props.templates?.templates.filter(
          (t) =>
            !foundPreset.items.find(
              (p) => `${p.masterTemplateId}` === `${t.masterJavaBaseModel?.id}`
            )?.excluded
        )
      : props.templates?.templates;

    console.log(
      `project ${props.project.name} ${pr.name}, template ${pr.projectTemplatePresetId} ${templateItems?.length}`
    );

    return { product: pr, templateItems: templateItems };
  });

  const productPresetInfoFiltered = productPresetInfo.map((i) => {
    return {
      ...i,
      templateItems: i.templateItems?.filter((ix) => {
        // return false;
        // If there's department ID, get from job
        if (ix.extDepartmentTemplateId) {
          const foundProductTemplate = props.templates?.templates.find(
            (tx) =>
              `${tx.masterJavaBaseModel?.id}` ===
              `${ix.masterJavaBaseModel?.id}`
          );

          if (!foundProductTemplate?.extDepartmentTemplateId) {
            return true;
          }

          const foundPCDTI = props.jobs
            ?.flatMap((j) =>
              j.panelCodes?.map((c) => ({ job: j, panelCode: c }))
            )
            .find(
              (c) =>
                `${c?.panelCode.extCrmPurchaseOrderItemId}` ===
                `${i.product.extCrmPurchaseOrderItemId}`
            )
            ?.panelCode.panelCodeDepartmentTemplateItems?.find(
              (i) =>
                `${i.departmentTemplateItem?.id}` ===
                `${foundProductTemplate?.extDepartmentTemplateId}`
            );

          return foundPCDTI?.completedDate ? true : false;
        }

        // If there's no department ID, get from product masterTemplate info
        if (!ix.extDepartmentTemplateId) {
          // return false;
          return (
            i.product.masterTemplates.find(
              (t) => `${t.masterTemplateId}` === `${ix.masterJavaBaseModel?.id}`
            )?.completed ?? false
          );
        }

        return false;
      }),
    };
  });

  const totalProductPresetInfoItems = productPresetInfo.flatMap(
    (i) => i.templateItems
  ).length;

  console.log(
    `${props.project.name} filtered`,
    productPresetInfoFiltered.flatMap((i) => i.templateItems).length
  );

  const totalProductPresetInfoItemsFiltered = productPresetInfoFiltered.flatMap(
    (i) => i.templateItems
  ).length;

  const progress =
    totalProductPresetInfoItemsFiltered / totalProductPresetInfoItems;

  return {
    progress: progress,
    items: totalProductPresetInfoItems,
    itemsFiltered: totalProductPresetInfoItemsFiltered,
  };
};

export const getProjectDeadlineChromaColorScale = (props: {
  date?: string | null | undefined;
  deadline?: string | null | undefined;
}) => {
  try {
    if (!props.date || !props.deadline) {
      return 0;
    }

    const date = new Date(props.date);
    const target = new Date(props.deadline);

    if (date.getTime() > target.getTime()) {
      return 0;
    }

    // Interval is 1 month

    return (target.getTime() / 86400000 - date.getTime() / 86400000) / 30;
  } catch (e) {
    return 0;
  }
};

export const fetchPrItemDetail = async (params: { prId: any }) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-purchase-request-items/${
        params.prId ?? 0
      }`
    );

    if (resp.status !== 200) throw await resp.text();

    return (await resp.json()) as PrItemDetail[];
  } catch (e) {
    return [];
  }
};

export const taskHasModule = (tL: MeetingTaskListView | null | undefined) =>
  tL
    ? (tL.taskList?.extSetId &&
        tL.taskList?.extSetId !== "" &&
        tL.taskList?.extSetId !== "0") ||
      (tL.taskList?.extModuleId &&
        tL.taskList?.extModuleId !== "" &&
        tL.taskList?.extModuleId !== "0") ||
      (tL.taskList?.extSubModuleId &&
        tL.taskList?.extSubModuleId !== "" &&
        tL.taskList?.extSubModuleId !== "0")
    : false;

export const dateToLocale = (d: Date) =>
  `${makeDateString(d)}T${makeTimeString(d)}`;

export const fetchItemsReq = async (params?: { apiKey?: string | null }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-items-req`,
      {
        headers: {
          authorization: params?.apiKey ?? "",
        },
      }
    );

    return (await response.json()) as ExtItemReq[];
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchMiddlewareTransactionTypes = async (params?: {
  apiKey?: string | null;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_MIDDLEWARE_URL}/api/get-transaction-type`,
      {
        headers: {
          authorization: params?.apiKey ?? "",
        },
      }
    );

    return (await response.json()) as {
      id?: number | null;
      name?: string | null;
    }[];
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchDeliveryNotes = async (params?: {
  apiKey?: string | null;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/deliverynotes`,
      {
        headers: {
          authorization: params?.apiKey ?? "",
        },
      }
    );

    return PpicDeliveryNotes.fromPartial(await response.json());
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchDeliveryNote = async (params?: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/deliverynotes/${params?.id}`,
      {
        headers: {
          authorization: params?.apiKey ?? "",
        },
      }
    );

    return PpicDeliveryNote.fromPartial(await response.json());
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchExtBoqs = async (params?: { apiKey?: string | null }) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ext-boqs`, {
      headers: {
        authorization: params?.apiKey ?? "",
      },
    });

    return CrmBoqs.fromPartial(await response.json());
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchExtBoqItems = async (params?: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-boq-items/${params?.id ?? ""}`,
      {
        headers: {
          authorization: params?.apiKey ?? "",
        },
      }
    );

    return CrmBoqItems.fromPartial(await response.json());
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const fetchBomLeveledsRecursive = async (params?: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bomleveled?recursive=false`,
      { headers: { authorization: params?.apiKey ?? "" } }
    );

    return (await resp.json()) as BomLeveledRecursive[];
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const bomDifferenceComparatorResult = (params: {
  bom1: BomLeveledRecursive;
  bom2: BomLeveledRecursive;
}) => {
  return { items: [] };
};

export const BomDifferenceComparatorView = () => {
  return (
    <>
      <div></div>
    </>
  );
};

export const fetchMaterialRequestsSimple = async (params: {
  apiKey: string;
}) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/materialrequests-simple`,
      {
        headers: { authorization: params.apiKey },
      }
    );

    return PpicMaterialRequestsSimple.fromPartial(await response.json());
  } catch (e) {
    return PpicMaterialRequestsSimple.fromPartial({});
  }
};

export const fetchAllPrByJob = async (params: { jobId: any; apiKey?: any }) => {
  try {
    // allPrLoading.current = true;
    // render();
    const d = await fetchExtPurchaseRequests({
      apiKey: params?.apiKey ?? "",
    });

    return d.filter((d) => `${d.jobId}` === `${params.jobId}`);
  } catch (e) {
    return [];
  }
};

export const fetchDepartmentGroupsProto = async (params?: {
  apiKey?: string;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_AUTHSERVER_URL}/departmentgroups`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) {
      throw await resp.text();
    }

    return AuthserverDepartmentGroups.fromPartial(await resp.json());
  } catch (e) {
    return AuthserverDepartmentGroups.fromPartial({ groups: [] });
  }
};

export const fetchNewJobNotificationsProto = async (params?: {
  apiKey?: string;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/newjobnotifications`,
      {
        headers: { authorization: params?.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) {
      throw await resp.text();
    }

    return PpicNewJobNotifications.fromPartial(await resp.json());
  } catch (e) {
    return PpicNewJobNotifications.fromPartial({ notifications: [] });
  }
};

export const categoryPartNums = [
  { value: 0, label: "Material" },
  { value: 1, label: "Consumable" },
  { value: 2, label: "Finished Goods" },
  { value: 3, label: "Service" },
  { value: 4, label: "Supply" },
];

export const categoryPartNumsMR = [
  { value: 0, label: "Material" },
  { value: 1, label: "Consumable" },
  { value: 4, label: "Supply" },
];

export const fetchCrmOutstandingPOs = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-crm-outstanding-purchase-orders`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    return (await await resp.json()) as CrmPOOutstandings;
  } catch (e) {
    console.error(e);
    return null;
  }
};

// Define types for CorrectionList and CurIdrList
type CorrectionList = {
  from: string | null;
  to: string;
};

type CurIdrList = {
  cur: string;
  multiplier: number;
};

// Define the correction list
const correctionList: CorrectionList[] = [
  { from: "RP", to: "IDR" },
  { from: "US$", to: "USD" },
  { from: "0", to: "IDR" },
  { from: "S$", to: "SGD" },
  { from: null, to: "IDR" },
  { from: ".0000", to: "IDR" },
  { from: "YEN", to: "JPY" },
  { from: "R", to: "IDR" },
  { from: "Curr", to: "IDR" },
  { from: "IDR", to: "IDR" },
  { from: "USD", to: "USD" },
  { from: "SGD", to: "SGD" },
  { from: "JPY", to: "JPY" },
  { from: "AUD", to: "AUD" },
  { from: "EUR", to: "EUR" },
];

// Define the currency multiplier list
export const defaultCurrMultiplierListToIdr: CurIdrList[] = [
  { cur: "IDR", multiplier: 1.0 },
  { cur: "SGD", multiplier: 11600.0 },
  { cur: "AUD", multiplier: 10400.0 },
  { cur: "JPY", multiplier: 100.0 },
  { cur: "EUR", multiplier: 16900.0 },
  { cur: "USD", multiplier: 15500.0 },
];

// Function to find the currency multiplier
export const findMultiplier = (input: string): number =>
  defaultCurrMultiplierListToIdr.find((item) => item.cur === input)
    ?.multiplier ?? 0.0;

// Function to correct the currency based on input
export const itemCurrencyCorrection = (input: string): string => "IDR";
//   {
//   const correction = correctionList.find((item) => item.from === input);
//   return correction ? correction.to : "IDR";
// };

export interface ItemLastPriceBody {
  extItemId: number | null;
  originalCurrency: string | null | undefined;
  correctedCurrency: string;
  originalPrice: number | null | undefined;
  priceIdr: number;
}

export const fetchItemLastPrice = async (params: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_WAREHOUSE_URL}/api/items/last-price/${
        params.id ?? ""
      }`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) throw await resp.text();

    const itemPrice = (await resp.json()) as {
      curr?: string | null;
      unitPrice?: number | null;
    };

    const correctedCurrency = itemCurrencyCorrection(itemPrice.curr ?? "");
    const foundMultiplier = findMultiplier(correctedCurrency);

    return {
      originalCurrency: itemPrice.curr,
      correctedCurrency: correctedCurrency,
      originalPrice: itemPrice.unitPrice,
      priceIdr: foundMultiplier * (itemPrice.unitPrice ?? 0),
      extItemId: params.id,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchExtItemLastPrice = async (params: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-item-price/${params.id ?? ""}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) {
      throw await resp.text();
    }

    return PpicBomLeveled.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchBomPrice = async (params: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bom-detail/${params.id ?? ""}/price`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) {
      throw await resp.text();
    }

    return await resp.text();
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchMRByJobProduct = async (params: {
  apiKey?: string | null;
  extItemId?: any;
  jobId?: any;
}) => {
  try {
    const resp = await fetch(
      `${
        process.env.REACT_APP_BASE_URL
      }/materialrequests-by-job-product?extItemId=${
        params.extItemId ?? ""
      }&jobId=${params.jobId ?? ""}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) {
      throw await resp.text();
    }

    return (await resp.json()) as MaterialRequest[];
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchBomLeveledGroupsProto = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bomleveledgroups-proto`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) {
      throw await resp.text();
    }

    return PpicBomLeveledGroups.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchBomLeveledGroupProto = async (params: {
  apiKey?: string | null;
  id?: any;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/bomleveledgroups-proto/${params.id}`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) {
      throw await resp.text();
    }

    return PpicBomLeveledGroup.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchMRAuthorizationDefaults = async (params: {
  apiKey?: string | null;
}) => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/mrauthorizationdefaults`,
      {
        headers: { authorization: params.apiKey ?? "" },
      }
    );

    if (resp.status !== 200) {
      throw await resp.text();
    }

    return PpicMRAuthorizationDefaults.fromPartial(await resp.json());
  } catch (e) {
    console.error(e);
    return null;
  }
};

const prStatuses = [
  { value: 0, label: "Outstanding" },
  { value: 1, label: "Cancelled" },
  { value: 2, label: "Approved" },
  { value: 3, label: "PO" },
];

export const parsePurchaseRequestStatus = (status: any) =>
  prStatuses.find((s) => `${s.value}` === `${status}`);
