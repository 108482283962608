import murmur from "murmurhash-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Link } from "react-router-dom";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  fetchExtCrmDjos,
  fetchExtCrmPurchaseOrdersProto,
  fetchExtCrmPurchaseOrdersProtoSimple,
  fetchJobFull,
  fetchJobsProtoSimple,
  fetchWorkOrdersProtoMongo,
  getWorkOrderTimeMinutes,
  intlFormat,
  jobDeptCategories,
  JobPriority,
  JobTypeSelection,
  makeReadableDateString,
  TaskListInfo,
} from "../../../helpers";
import {
  CrmDJOs,
  CrmPurchaseOrders,
  MasterJavaBaseModel,
  MeetingMeetingTaskInCharge,
  MeetingTaskListsView,
  MeetingTaskListView,
  PpicDepartmentTemplates,
  PpicIntegrationModules,
  PpicJobs,
  PpicMachinePrograms,
  PpicPanelCode,
  PpicPanelCodeDepartmentTemplateItems,
  PpicRcemDepartments,
  PpicSimplifiedProcessType,
  PpicSimplifiedProcessTypes,
} from "../../../masterbigsystem";
import {
  Job,
  JobDeptCategory,
  JobPanelType,
  JobType,
  Machine,
  PanelCode,
  Uom,
  WiringProcessType,
  JobMapped,
  ExtUser,
  ExtDepartment,
} from "../../../models/model";
import {
  initialJob,
  initialJobPanelType,
  initialPanelCode,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import StatefulCrudTableBs from "../../mini-components/StatefulCrudTable/StatefulCrudTableBs";
import chroma from "chroma-js";
import { CloudDownload } from "@material-ui/icons";

const JobPage = (props: any) => {
  const ctx = useContext(AppContext);

  const [crmDjos, setCrmDjos] = useState(CrmDJOs.fromPartial({ djos: [] }));

  const [jobsProtoSimple, setJobsProtoSimple] = useState<
    PpicJobs | null | undefined
  >(null);
  const [cleanupLoading, setCleanupLoading] = useState(false);
  const [state, setState] = useState({
    apiKey: ctx?.apiKey ?? "",
    job: {
      job: { ...initialJob } as Job,
      fepDocuments: [],
      jobBomLeveleds: [],
      jobFepLeveleds: [],
      jobBomLeveledsMapped: [],
      jobMaterialRequests: [],
      jobPurchaseRequests: [],
      jobIntegrationList: [],
    } as JobMapped,
    jobTypes: [] as Array<JobType>,
    uoms: [] as Array<Uom>,
    requestStatus: RequestStatus.NotAsked,
    jobTypeSelections: [JobTypeSelection.Cabinet],
    jobPriority: JobPriority.Low,
    panelTypeQty: 0,
    selectedPanelType: { ...initialJobPanelType } as JobPanelType,
    jobPanelType: { ...initialJobPanelType } as JobPanelType,
    panelCode: { ...initialPanelCode } as PanelCode,
    panelCodeDeleteIds: [] as Array<number>,
    wiringProcessTypes: [] as Array<WiringProcessType>,
  });
  const [purchaseOrders, setPurchaseOrders] = useState<
    CrmPurchaseOrders | null | undefined
  >(null);
  const [hidden, setHidden] = useState<boolean | null>(false);
  const [jobDeptCategory, setJobDeptCategory] =
    useState<JobDeptCategory>("PPC");
  const bomExists = useRef(undefined as boolean | undefined);
  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };
  const [
    panelCodeDepartmentTemplateItemsProto,
    setPanelCodeDepartmentTemplateItemsProto,
  ] = useState(
    PpicPanelCodeDepartmentTemplateItems.fromPartial({
      departmentTemplateItems: [],
    })
  );
  const [departmentTemplatesProto, setDepartmentTemplatesProto] =
    useState<PpicDepartmentTemplates>(
      PpicDepartmentTemplates.fromPartial({ templates: [] })
    );
  const [rcemDepartments, setRcemDepartments] =
    useState<PpicRcemDepartments | null>(null);
  const [machinesDb, setMachinesDb] = useState<Machine[]>([]);
  const [integrationModules, setIntegrationModules] =
    useState<PpicIntegrationModules | null>(
      PpicIntegrationModules.fromPartial({})
    );
  const [machineProgramsProto, setMachineProgramsProto] =
    useState<PpicMachinePrograms>(
      PpicMachinePrograms.fromPartial({ machinePrograms: [] })
    );
  const [showWorkOrderCleanupModal, setShowWorkOrderCleanupModal] =
    useState(false);
  const [showWorkOrderCleanupDetailModal, setShowWorkOrderCleanupDetailModal] =
    useState(false);

  const [extWoCleanupIds, setExtWoCleanupIds] = useState<string[]>([]);

  const [workOrders, setWorkOrders] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const [workOrdersFormer, setWorkOrdersFormer] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );

  const [workOrderCleanupDetail, setWorkOrderCleanupDetail] = useState(
    MeetingTaskListView.fromPartial({})
  );
  const [meetingTaskInChargeDeleteIds, setMeetingTaskInChargeDeleteIds] =
    useState<string[]>([]);
  useEffect(() => {
    fetchPurchaseOrdersData();
    fetchJobsData();
    handleFetchDjoData();
  }, [hidden]);

  const handleFetchDjoData = async () => {
    const fetched = await fetchExtCrmDjos({
      apiKey: ctx?.apiKey ?? "",
    });

    if (fetched) {
      setCrmDjos(fetched);
    }
  };

  const fetchPurchaseOrdersData = async () => {
    setPurchaseOrders(
      await fetchExtCrmPurchaseOrdersProtoSimple({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchJobsData = async () => {
    setJobsProtoSimple(
      await fetchJobsProtoSimple({
        apiKey: ctx?.apiKey ?? "",
        withProducts: true,
      })
    );
  };

  const poNumber = useRef("");
  const dateFrom = useRef(null as string | null);
  const dateTo = useRef(null as string | null);

  const additionalUrl = `&hidden=${hidden ?? ""}&jobDeptCategory=${
    jobDeptCategory ?? ""
  }&bomExists=${bomExists.current ?? ""}&dateFrom=${
    dateFrom.current ?? ""
  }&dateTo=${dateTo.current ?? ""}&extPurchaseOrderNumber=${
    poNumber.current ?? ""
  }`;

  return (
    <>
      <div>
        <div>
          Job entry allowed: {JSON.stringify(ctx?.user?.jobEntryAllowed)}
        </div>
        {ctx?.user?.jobEntryAllowed ? (
          <>
            <div className="d-flex my-2 align-items-end" style={{ zIndex: 1 }}>
              <div className="flex-grow-1">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Closed Status..."
                  options={[
                    { label: "All", value: null },
                    { label: "Outstanding", value: false },
                    { label: "Closed", value: true },
                  ]}
                  onChange={(v) => {
                    const val = v as unknown as { value: boolean | null };

                    setHidden(val.value);
                  }}
                />
              </div>
              <div className="flex-grow-1">
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  placeholder="Job Dept Category..."
                  options={jobDeptCategories}
                  getOptionLabel={(p) => p.label}
                  onChange={(v) => {
                    const val = v as unknown as {
                      value: JobDeptCategory | null;
                    };

                    if (val.value) {
                      setJobDeptCategory(val.value);
                    }
                  }}
                />
              </div>
              <div className="flex-grow-1">
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  placeholder="BOM Exists..."
                  options={[
                    { label: "Outstanding", value: false },
                    { label: "Completed", value: true },
                    { label: "All", value: undefined },
                  ]}
                  // getOptionLabel={(p) => p.label}
                  onChange={(v) => {
                    const val = v as {
                      value: boolean | undefined;
                    };

                    bomExists.current = val.value;
                    render();
                  }}
                />
              </div>
              <div className="flex-grow-1">
                <div>
                  <strong>Date From</strong>
                  <input
                    className="form-control form-control-sm"
                    type="date"
                    onBlur={(e) => {
                      dateFrom.current = e.target.value;
                      render();
                    }}
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <div>
                  <strong>Date to</strong>
                  <input
                    className="form-control form-control-sm"
                    type="date"
                    onBlur={(e) => {
                      dateTo.current = e.target.value;
                      render();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div>
                <small>
                  <strong>Search by PO</strong>
                </small>
                <input
                  className="form-control form-control-sm"
                  placeholder="Search by PO..."
                  onBlur={(e) => {
                    poNumber.current = e.target.value;
                    render();
                  }}
                />
              </div>

              <div>
                <a
                  target="_blank"
                  href={`${
                    process.env.REACT_APP_BASE_URL
                  }/jobs/search/paged?name=&page=0&perPage=0&hidden=${
                    hidden ?? ""
                  }&excel=true&jobDeptCategory=PPC&bomExists=false&dateFrom=${
                    dateFrom.current ?? ""
                  }&dateTo=${dateTo.current ?? ""}`}
                >
                  <button className="btn btn-sm btn-success">
                    <CloudDownload /> BOM Outstanding XLSX
                  </button>
                </a>
              </div>
            </div>

            <div>
              <small>
                <strong>Search by Name</strong>
              </small>
            </div>

            <StatefulCrudTableBs<Job>
              url="jobs"
              additionalUrl={additionalUrl}
              additionalFilter={(j) => {
                // console.log(`${j.name} ${j.jobBomLeveledsData?.map(j=>j.pcodei)}`)
                if (bomExists.current !== undefined) {
                  if (bomExists.current === true) {
                    return (
                      (j.panelCodes?.filter((c) =>
                        j.jobBomLeveledsData?.find(
                          (jbl) => `${jbl.panelCode?.id}` === `${c.id}`
                        )
                      ).length ?? 0) === (j.panelCodes?.length ?? 0)
                    );
                  } else {
                    return (
                      (j.panelCodes?.filter((c) =>
                        j.jobBomLeveledsData?.find(
                          (jbl) => `${jbl.panelCode?.id}` === `${c.id}`
                        )
                      ).length ?? 0) !== (j.panelCodes?.length ?? 0)
                    );
                  }
                }

                return true;
              }}
              head={[
                "Name",
                "Short Name",
                "BOM List",
                "Simple Mode",
                "Cost report",
                `Cleanup WO`,
                "Job Info",
                `DJO`,
                "Sales",
                "Customer",
                "Type",
                "Qty",
                "Priority",
                "Deadline Prod.",
                "Deadline QC",
                "Deadline Deliv.",
                "RAL",
                "Created At",
                "Updated At",
                "BOM Created",
                "BOM Link",
                "Created By",
                "PO",
              ]}
              mapper={(job: Job) => {
                return {
                  id: job.id ?? 0,
                  content: [
                    job?.name && job.name !== "" ? job.name : "No Name",
                    <>
                      <div>
                        <div> {job.shortName}</div>
                        <div>
                          {" "}
                          <div
                            className={`text-light px-1 py-0 ${
                              job.extProjectId ? `bg-success` : `bg-danger`
                            }`}
                          >
                            <strong>Project</strong>{" "}
                          </div>
                        </div>
                      </div>
                    </>,
                    <>
                      <div className="px-2 py-0">
                        <div
                          style={{
                            backgroundColor: chroma
                              .scale(["red", "yellow", "green"])(
                                (job.jobBomLeveledsData?.filter(
                                  (j) => j.panelCode?.id
                                )?.length ?? 0) /
                                  ((job.panelCodes?.length ?? 0) === 0
                                    ? 1
                                    : job.panelCodes?.length ?? 0)
                              )
                              .alpha(0.8)
                              .hex(),
                          }}
                        >
                          <strong>
                            {job.jobBomLeveledsData?.filter(
                              (j) => j.panelCode?.id
                            )?.length ?? 0}
                            /{job.panelCodes?.length ?? 0}
                          </strong>
                        </div>
                        {ctx.gspeAppUser?.roles.find(
                          (r) =>
                            r.name?.toLowerCase().includes("finance") ||
                            r.name?.toLowerCase().includes("admin")
                        ) ||
                        ctx.gspeAppUser?.username
                          ?.toLowerCase()
                          .includes("admin") ? (
                          <>
                            <ol>
                              {job.jobBomLeveledsData?.map((jbl) => {
                                return (
                                  <>
                                    <li>
                                      <div>
                                        <div>
                                          <Link
                                            to={`/bomdocumentshierarchy/${jbl.bomLeveled?.id}`}
                                          >
                                            {jbl.bomLeveled?.name}
                                          </Link>
                                          : IDR{" "}
                                          {Intl.NumberFormat("id-ID", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }).format(
                                            (jbl.bomLeveled?.snapshotPriceIdr ??
                                              0) * (jbl.qty ?? 0)
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>,

                    // <>
                    //   <Link to={`/jobs/${job.id}/materialrequests`}>
                    //     <button className="btn btn-primary btn-sm">Go</button>
                    //   </Link>
                    // </>,
                    <>
                      <Link to={`/jobs/${job.id}/simplemode`}>
                        <button className="btn btn-primary btn-sm">Go</button>
                      </Link>
                    </>,
                    <>
                      <Link to={`/costreport/${job.id}`}>
                        <button className="btn btn-primary btn-sm">Go</button>
                      </Link>
                    </>,
                    <>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={async () => {
                          try {
                            const fetchedJob = await fetchJobFull({
                              apiKey: ctx?.apiKey,
                              id: job.id,
                            });

                            if (fetchedJob?.job) {
                              setState({
                                ...state,
                                job: fetchedJob.job,
                              });
                            }

                            setShowWorkOrderCleanupModal(true);

                            const workOrdersFetchedData =
                              await fetchWorkOrdersProtoMongo({
                                apiKey: ctx?.apiKey ?? "",
                                extJobId: job.id,
                                filterProductTreeOnly: true,
                                filterProgramTreeOnly: true,
                              });
                            setExtWoCleanupIds(
                              workOrdersFetchedData?.taskLists
                                .filter((tL) => {
                                  const hasProductTree =
                                    (tL.taskList?.extSetId &&
                                      tL.taskList.extSetId !== "0" &&
                                      tL.taskList.extSetId !== "") ||
                                    (tL.taskList?.extModuleId &&
                                      tL.taskList.extModuleId !== "0" &&
                                      tL.taskList.extModuleId !== "") ||
                                    (tL.taskList?.extSubModuleId &&
                                      tL.taskList.extSubModuleId !== "0" &&
                                      tL.taskList.extSubModuleId !== "")
                                      ? true
                                      : false;

                                  const hasProgramTree =
                                    tL.taskList?.extProgramId &&
                                    tL.taskList.extProgramId !== "0" &&
                                    tL.taskList.extProgramId !== ""
                                      ? true
                                      : false;

                                  const hasDeptTemplate =
                                    tL.taskList?.meetingTasks.find(
                                      (mt) =>
                                        mt.extPanelCodeDepartmentTemplateItemId &&
                                        mt.extPanelCodeDepartmentTemplateItemId !==
                                          "0" &&
                                        mt.extPanelCodeDepartmentTemplateItemId !==
                                          "" &&
                                        // ensure Dept template found in current job
                                        fetchedJob?.job.job?.panelCodes?.find(
                                          (pc) =>
                                            pc.panelCodeDepartmentTemplateItems?.find(
                                              (pcdti) =>
                                                `${pcdti.id}` ===
                                                `${mt.extPanelCodeDepartmentTemplateItemId}`
                                            )
                                        )
                                    )
                                      ? true
                                      : false;

                                  const multiParam =
                                    hasProductTree ||
                                    hasProgramTree ||
                                    hasDeptTemplate;

                                  const timeParam =
                                    getWorkOrderTimeMinutes(
                                      tL.taskList,
                                      rcemDepartments,
                                      integrationModules,
                                      machineProgramsProto,
                                      departmentTemplatesProto,
                                      panelCodeDepartmentTemplateItemsProto,
                                      null,
                                      null
                                    ).totalHours !== 0;

                                  // If either multiParam or timeParam is satisfied.
                                  return !(multiParam || timeParam);
                                })
                                .map(
                                  (tL) =>
                                    tL.taskList?.masterJavaBaseModel
                                      ?.id as string
                                ) ?? []
                            );
                            setWorkOrders(
                              MeetingTaskListsView.fromPartial(
                                workOrdersFetchedData
                              )
                            );
                            setWorkOrdersFormer(
                              MeetingTaskListsView.fromPartial(
                                workOrdersFetchedData
                              )
                            );
                          } catch (e) {}
                        }}
                      >
                        Cleanup
                      </button>
                    </>,
                    <>
                      <Link to={`/jobs/${job.id}/jobinfo`}>
                        <button className="btn btn-sm btn-primary">View</button>
                      </Link>
                    </>,
                    <>
                      <div className={`${job.extCrmDjoId ? `` : `bg-warning`}`}>
                        {job.extCrmDjoId
                          ? (() => {
                              const foundDjo = crmDjos.djos.find(
                                (d) => `${d.value}` === `${job?.extCrmDjoId}`
                              );

                              return `${foundDjo?.text}: ${foundDjo?.accountName}`;
                            })()
                          : "None"}
                      </div>
                    </>,
                    job.sales ? job.sales.name : "",
                    // job.individualEntity ? job.individualEntity.name : "",
                    [
                      ...new Set(
                        job.jobPurchaseOrders?.map((p) => {
                          const foundPo = purchaseOrders?.purchaseOrders?.find(
                            (po) => `${po.id}` === `${p.extPurchaseOrderId}`
                          );

                          return `${foundPo?.account?.name ?? ""}`;
                        })
                      ),
                    ].join(","),
                    job.jobTypes?.map((jobType) => jobType.name).join(", "),
                    `${job.qty}`,
                    job.priority,
                    intlFormat({
                      date: job.productionDeadline,
                      dateStyle: "medium",
                      // timeStyle:undefined
                    }),
                    intlFormat({
                      date: job.deadline,
                      dateStyle: "medium",
                    }),
                    intlFormat({
                      date: job.deliveryDeadline,
                      dateStyle: "medium",
                    }),

                    job.ral ? job.ral.name : "",
                    job.createdAt
                      ? intlFormat({
                          date: job.createdAt,
                          dateStyle: "medium",
                          timeStyle: "short",
                        })
                      : "",
                    job.updatedAt
                      ? intlFormat({
                          date: job.updatedAt,
                          dateStyle: "medium",
                          timeStyle: "short",
                        })
                      : "",
                    job.jobBomLeveledsData?.slice(-1)?.[0]?.bomLeveled
                      ?.createdAt
                      ? intlFormat({
                          date: job.jobBomLeveledsData?.slice(-1)?.[0]
                            ?.bomLeveled?.createdAt,
                          dateStyle: "medium",
                          timeStyle: "short",
                        })
                      : "",
                    job.jobBomLeveledsData?.slice(-1)?.[0]?.createdAt
                      ? intlFormat({
                          date: job.jobBomLeveledsData?.slice(-1)?.[0]
                            ?.createdAt,
                          dateStyle: "medium",
                          timeStyle: "short",
                        })
                      : "",
                    job.createdBy ? job.createdBy.name : "",
                    <>
                      <small>
                        {job.jobPurchaseOrders
                          ?.map((p) => {
                            const foundPo =
                              purchaseOrders?.purchaseOrders?.find(
                                (po) => `${po.id}` === `${p.extPurchaseOrderId}`
                              );

                            return `${foundPo?.purchaseOrderNumber ?? ""}: ${
                              foundPo?.account?.name ?? ""
                            }`;
                          })
                          .join(",")}
                      </small>
                    </>,
                  ],
                };
              }}
            />
          </>
        ) : (
          <>You are not permitted to access job entry.</>
        )}
        {/* <div>{JSON.stringify(hidden)}</div> */}
      </div>
      <Modal
        size="xl"
        show={showWorkOrderCleanupDetailModal}
        onHide={() => {
          setShowWorkOrderCleanupDetailModal(false);
        }}
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div>
            <TaskListInfo
              customers={ctx?.extCustomers}
              tL={workOrderCleanupDetail}
              crmCustomers={undefined}
              extUsers={ctx?.extUsers ?? []}
              jobsProto={jobsProtoSimple ?? PpicJobs.fromPartial({})}
              machines={[]}
              processTypes={PpicSimplifiedProcessTypes.fromPartial({})}
              rcemDepartments={undefined}
              integrationModules={undefined}
              machinePrograms={undefined}
              departmentTemplates={undefined}
              panelCodeDepartmentTemplateItems={undefined}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        show={showWorkOrderCleanupModal}
        onHide={() => {
          setShowWorkOrderCleanupModal(false);
        }}
      >
        <ModalHeader>
          <div>
            <div></div>
            <h3>WO Cleanup</h3>
            <div>
              <small>
                Automatic cleanup select: (No product tree, no program tree, no
                dept template), or (time process = 0)
              </small>
            </div>
            <div>
              To cleanup: {extWoCleanupIds.length}/{workOrders.taskLists.length}
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Select
                    placeholder="By dept..."
                    options={ctx?.extDepartments}
                    getOptionLabel={(d) => `${d.name}`}
                    onChange={(c) => {
                      const val = c as ExtDepartment;

                      setExtWoCleanupIds(
                        workOrders.taskLists
                          .filter(
                            (tL) =>
                              ctx?.extUsers
                                .filter(
                                  (u) => `${u.departmentId}` === `${val.id}`
                                )
                                .find(
                                  (u) =>
                                    `${u.departmentId}` ===
                                    `${
                                      ctx.extUsers.find(
                                        (u) =>
                                          `${u.id}` ===
                                          `${tL.taskList?.extInChargeId}`
                                      )?.departmentId
                                    }`
                                ) ||
                              tL.taskList?.meetingTasks.find((mt) =>
                                mt.meetingTaskInCharges.find(
                                  (mtic) =>
                                    `${
                                      ctx?.extUsers.find(
                                        (u) => `${u.id}` === `${mtic.extUserId}`
                                      )?.departmentId
                                    }` === `${val.id}`
                                )
                              )
                          )
                          .map(
                            (tL) => tL.taskList?.masterJavaBaseModel?.id ?? ""
                          )
                      );
                    }}
                  />
                </div>
                <div className="flex-grow-1">
                  <Select
                    placeholder="By user..."
                    options={ctx?.extUsers ?? []}
                    getOptionLabel={(u) => `${u.username}`}
                    onChange={(c) => {
                      const val = c as ExtUser;

                      setExtWoCleanupIds(
                        workOrders.taskLists
                          .filter(
                            (tL) =>
                              `${tL.taskList?.extInChargeId}` === `${val.id}` ||
                              tL.taskList?.meetingTasks.find((mt) =>
                                mt.meetingTaskInCharges.find(
                                  (mtic) => `${mtic.extUserId}` === `${val.id}`
                                )
                              )
                          )
                          .map(
                            (tL) => tL.taskList?.masterJavaBaseModel?.id ?? ""
                          )
                      );
                    }}
                  />
                </div>
                <div className="flex-grow-1">
                  <Select
                    placeholder="By product..."
                    options={jobsProtoSimple?.jobs
                      .filter(
                        (j) =>
                          `${j.masterJavaBaseModel?.id}` ===
                          `${state.job.job?.id}`
                      )
                      .flatMap((j) => j.panelCodes)}
                    getOptionLabel={(c) =>
                      `${c.type}:${c.name}:${c.qty} unit(s)`
                    }
                    onChange={(c) => {
                      const val = c as PpicPanelCode;
                      setExtWoCleanupIds(
                        workOrders.taskLists
                          .filter(
                            (tL) =>
                              `${tL.taskList?.extPanelCodeId}` ===
                              `${val.masterJavaBaseModel?.id}`
                          )
                          .map(
                            (tL) => tL.taskList?.masterJavaBaseModel?.id ?? ""
                          )
                      );
                    }}
                  />
                </div>
                <div className="flex-grow-1">
                  <Select
                    placeholder="Remove PIC..."
                    options={[
                      ...new Set(
                        workOrders.taskLists
                          .filter((tL) =>
                            extWoCleanupIds.find(
                              (id) =>
                                `${tL.taskList?.masterJavaBaseModel?.id}` ===
                                `${id}`
                            )
                          )
                          .flatMap((tL) => tL.taskList?.meetingTasks)
                          .flatMap((mt) => mt?.meetingTaskInCharges)
                          .map((c) => c?.extUserId)
                      ),
                    ]
                      .map((id) =>
                        ctx?.extUsers.find((u) => `${u.id}` === `${id}`)
                      )
                      .filter((u) => u)
                      .map((u) => u as ExtUser)}
                    getOptionLabel={(u) =>
                      `${u.username ?? "No uname"}:${u.name ?? "No name"}:${
                        ctx?.extDepartments.find(
                          (d) => `${d.id}` === `${u.departmentId}`
                        )?.name ?? "No dept"
                      }`
                    }
                    onChange={(v) => {
                      const val = v as ExtUser;

                      setMeetingTaskInChargeDeleteIds([
                        ...meetingTaskInChargeDeleteIds,
                        ...workOrders.taskLists
                          .filter((tL) =>
                            extWoCleanupIds.find(
                              (id) =>
                                `${tL.taskList?.masterJavaBaseModel?.id}` ===
                                `${id}`
                            )
                          )
                          .flatMap((tL) => tL.taskList?.meetingTasks)
                          .flatMap((mt) =>
                            mt?.meetingTaskInCharges.filter(
                              (c) => `${c.extUserId}` === `${val.id}`
                            )
                          )
                          .map((c) => c?.masterJavaBaseModel?.id)
                          .filter((id) => id)
                          .map((id) => id as string),
                      ]);

                      setWorkOrders({
                        ...workOrders,
                        taskLists: workOrders.taskLists.map((tL) =>
                          extWoCleanupIds.find(
                            (id) =>
                              `${tL.taskList?.masterJavaBaseModel?.id}` ===
                              `${id}`
                          )
                            ? {
                                ...tL,
                                taskList: tL.taskList
                                  ? {
                                      ...tL.taskList,
                                      meetingTasks:
                                        tL.taskList.meetingTasks.map((mt) =>
                                          mt
                                            ? {
                                                ...mt,
                                                meetingTaskInCharges:
                                                  mt.meetingTaskInCharges.filter(
                                                    (c) =>
                                                      `${c.extUserId}` !==
                                                      `${val.id}`
                                                  ),
                                              }
                                            : mt
                                        ),
                                    }
                                  : tL.taskList,
                              }
                            : tL
                        ),
                      });
                    }}
                  />
                </div>
                <div className="flex-grow-1">
                  <Select
                    placeholder="Add PIC..."
                    options={ctx?.extUsers}
                    getOptionLabel={(u) =>
                      `${u.username ?? "No uname"}:${u.name ?? "No name"}:${
                        ctx?.extDepartments.find(
                          (d) => `${d.id}` === `${u.departmentId}`
                        )?.name ?? "No dept"
                      }`
                    }
                    onChange={(v) => {
                      const val = v as ExtUser;

                      setWorkOrders({
                        ...workOrders,
                        taskLists: workOrders.taskLists.map((tL) =>
                          extWoCleanupIds.find(
                            (id) =>
                              `${tL.taskList?.masterJavaBaseModel?.id}` ===
                              `${id}`
                          )
                            ? {
                                ...tL,
                                taskList: tL.taskList
                                  ? {
                                      ...tL.taskList,
                                      meetingTasks:
                                        tL.taskList.meetingTasks.map((mt) =>
                                          mt
                                            ? {
                                                ...mt,
                                                meetingTaskInCharges:
                                                  !mt.meetingTaskInCharges.find(
                                                    (c) =>
                                                      `${c.extUserId}` ===
                                                      `${val.id}`
                                                  )
                                                    ? [
                                                        ...mt.meetingTaskInCharges,
                                                        MeetingMeetingTaskInCharge.fromPartial(
                                                          {
                                                            masterJavaBaseModel:
                                                              MasterJavaBaseModel.fromPartial(
                                                                { uuid: v4() }
                                                              ),
                                                            extUserId: val.id
                                                              ? `${val.id}`
                                                              : undefined,
                                                          }
                                                        ),
                                                      ]
                                                    : mt.meetingTaskInCharges,
                                              }
                                            : mt
                                        ),
                                    }
                                  : tL.taskList,
                              }
                            : tL
                        ),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="overflow-auto shadow shadow-md border border-dark"
              style={{ height: "75vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                {[
                  <>
                    <div>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          if (extWoCleanupIds.length === 0) {
                            setExtWoCleanupIds(
                              workOrders.taskLists.map(
                                (tL) =>
                                  tL.taskList?.masterJavaBaseModel?.id ?? ""
                              )
                            );
                          } else {
                            setExtWoCleanupIds([]);
                          }
                        }}
                      >
                        Toggle
                      </button>
                    </div>
                  </>,
                  "#",
                  "ID",
                  "Product Tree",
                  "Program Tree",
                  "Dept Template",
                  "Job",
                  "Product",
                  "Time process",
                  "Manpower",
                  "Date",
                  "Details",
                ].map((h) => (
                  <>
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                ))}
                {workOrders?.taskLists.map((tL, i) => {
                  return (
                    <>
                      <tr
                        style={{
                          backgroundColor: extWoCleanupIds.find(
                            (id) =>
                              `${id}` ===
                              `${tL.taskList?.masterJavaBaseModel?.id}`
                          )
                            ? "lightsalmon"
                            : "white",
                        }}
                      >
                        <td className="border border-dark">
                          <div>
                            <button
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => {
                                if (
                                  extWoCleanupIds.find(
                                    (id) =>
                                      `${id}` ===
                                      `${tL.taskList?.masterJavaBaseModel?.id}`
                                  )
                                ) {
                                  setExtWoCleanupIds(
                                    extWoCleanupIds.filter(
                                      (id) =>
                                        `${id}` !==
                                        `${tL.taskList?.masterJavaBaseModel?.id}`
                                    )
                                  );
                                } else {
                                  setExtWoCleanupIds([
                                    ...extWoCleanupIds,
                                    tL.taskList?.masterJavaBaseModel?.id ?? "",
                                  ]);
                                }
                              }}
                            >
                              Select
                            </button>
                          </div>
                        </td>
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          {tL.taskList?.masterJavaBaseModel?.id}
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              (tL.taskList?.extSetId &&
                                tL.taskList.extSetId !== "0" &&
                                tL.taskList.extSetId !== "") ||
                              (tL.taskList?.extModuleId &&
                                tL.taskList.extModuleId !== "0" &&
                                tL.taskList.extModuleId !== "") ||
                              (tL.taskList?.extSubModuleId &&
                                tL.taskList.extSubModuleId !== "0" &&
                                tL.taskList.extSubModuleId !== "")
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              tL.taskList?.extProgramId &&
                              tL.taskList.extProgramId !== "0" &&
                              tL.taskList.extProgramId !== ""
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              tL.taskList?.meetingTasks.find(
                                (mt) =>
                                  mt.extPanelCodeDepartmentTemplateItemId &&
                                  mt.extPanelCodeDepartmentTemplateItemId !==
                                    "0" &&
                                  mt.extPanelCodeDepartmentTemplateItemId !== ""
                              )
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              tL.taskList?.extJobId &&
                              tL.taskList.extJobId !== "0" &&
                              tL.taskList.extJobId !== ""
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              tL.taskList?.extPanelCodeId &&
                              tL.taskList.extPanelCodeId !== "0" &&
                              tL.taskList.extPanelCodeId !== ""
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          {getWorkOrderTimeMinutes(
                            tL.taskList,
                            rcemDepartments,
                            integrationModules,
                            machineProgramsProto,
                            departmentTemplatesProto,
                            panelCodeDepartmentTemplateItemsProto,
                            null,
                            null
                          ).totalHours.toFixed(1)}{" "}
                          h
                        </td>
                        <td className="border border-dark">
                          <small>
                            {ctx?.extUsers.find(
                              (u) =>
                                `${u.id}` === `${tL.taskList?.extInChargeId}`
                            )?.username ??
                              `no spv ${tL.taskList?.extInChargeId ?? ""}`}{" "}
                            |{" "}
                            {[
                              ...new Set(
                                tL.taskList?.meetingTasks
                                  .map((mt) =>
                                    mt.meetingTaskInCharges.map(
                                      (c) => c.extUserId
                                    )
                                  )
                                  .flat()
                              ),
                            ]
                              .map(
                                (i) =>
                                  ctx?.extUsers.find(
                                    (u) => `${u.id}` === `${i}`
                                  )?.username ?? `no name (${i ?? ""})`
                              )
                              .join(",")}
                          </small>
                        </td>
                        <td className="border border-dark">
                          {tL.taskList?.taskStart} to {tL.taskList?.taskEnd}
                        </td>
                        <td className="border border-dark">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setShowWorkOrderCleanupDetailModal(true);
                              if (tL.taskList) {
                                setWorkOrderCleanupDetail(tL);
                              }
                            }}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {cleanupLoading ? (
            <>
              <Spinner animation="border" />
            </>
          ) : (
            <>
              <button
                className="btn btn-sm mx-2 btn-primary"
                onClick={async () => {
                  try {
                    setCleanupLoading(true);

                    const resp = await fetch(
                      `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-save-bulk`,
                      {
                        method: "post",
                        headers: {
                          authorization: ctx?.apiKey ?? "",
                          "content-type": "application/json",
                        },
                        body: JSON.stringify({
                          ...workOrders,
                          taskLists: workOrders.taskLists.filter((tL) => {
                            const foundTL = workOrdersFormer.taskLists.find(
                              (tLX) =>
                                tLX.taskList?.masterJavaBaseModel?.id ===
                                tL.taskList?.masterJavaBaseModel?.id
                            );

                            if (!foundTL) {
                              return false;
                            }

                            return (
                              murmur.murmur3(JSON.stringify(tL)) !==
                              murmur.murmur3(JSON.stringify(foundTL))
                            );
                          }),
                        }),
                      }
                    );

                    await Promise.all(
                      meetingTaskInChargeDeleteIds.map(async (id) => {
                        try {
                          const resp = await fetch(
                            `${process.env.REACT_APP_MEETING_URL}/meetingtaskincharges/${id}/empty`,
                            {
                              method: "delete",
                            }
                          );
                        } catch (e) {}
                      })
                    );

                    window.location.reload();
                  } catch (e) {
                  } finally {
                    setCleanupLoading(false);
                  }
                }}
              >
                Save changes
              </button>
              <button
                className="btn btn-sm btn-primary"
                onClick={async () => {
                  try {
                    setCleanupLoading(true);
                    const resp = await fetch(
                      `${process.env.REACT_APP_MEETING_URL}/tasklists-cleanup`,
                      {
                        method: "post",
                        headers: {
                          authorization: ctx?.apiKey ?? "",
                          "content-type": "application/json",
                        },
                        body: JSON.stringify(extWoCleanupIds),
                      }
                    );

                    window.location.reload();
                  } catch (e) {
                  } finally {
                    setCleanupLoading(false);
                  }
                }}
              >
                Execute
              </button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default JobPage;
