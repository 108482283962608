import { Dialog, TextField, useForkRef } from "@material-ui/core";
import { blue, green, orange } from "@material-ui/core/colors";
import {
  Add,
  Camera,
  CameraRear,
  CloudUpload,
  Delete,
  FileCopy,
  Photo,
  PhotoCamera,
  Save,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { findByAltText } from "@testing-library/dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import { Prompt, useHistory, useParams } from "react-router";
import Select from "react-select";
import uuid, { v4 as uuidv4, v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  ItemLastPriceBody,
  checkBomDocumentTypeColor,
  createBomListFlattened,
  extractItemDesc,
  fetchBomLeveleds,
  fetchBomLeveledsProtoDetailedJob,
  fetchCustomersProto,
  fetchExtBoqItems,
  fetchExtBoqs,
  fetchExtCrmPurchaseOrdersProto,
  fetchExtInventory,
  fetchExtItemLastPrice,
  fetchExtTsCrmPurchaseOrders,
  fetchForProcesses,
  fetchItemLastPrice,
  fetchItems,
  fetchItemsReq,
  fetchJobBom,
  fetchJobBomLeveleds,
  fetchJobsProtoSimple,
  fetchWorkOrdersProtoMongo,
  flattenBomLeveled,
  flattenBomLeveledToList,
  flattenBomLeveledToListMultiplier,
  getItemFullDescription,
  intlFormat,
} from "../../../helpers";
import {
  BomItemType,
  CrmBoq,
  CrmBoqItems,
  CrmBoqs,
  CrmPurchaseOrders,
  MeetingTaskListsView,
  PpicBomDocumentHierarchyXls,
  PpicBomLeveled,
  PpicIndividualEntities,
  PpicIndividualEntity,
  PpicJob,
  PpicJobs,
  PpicPanelCode,
  bomItemTypeFromJSON,
  bomItemTypeToJSON,
} from "../../../masterbigsystem";
import {
  BomLeveled,
  BomLeveledApproval,
  BomLeveledRecursive,
  BomType,
  ExtInventory,
  ExtItem,
  ExtItemReq,
  ExtUser,
  ForProcess,
  JobBomLeveled,
  JobBomLeveledAddBody,
} from "../../../models/model";
import {
  defaultBomLeveled,
  defaultBomleveledIndividualEntities,
  initialBomLeveled,
  initialBomLeveledApproval,
  initialIndividualEntity,
  initialJob,
  initialJobBomLeveled,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

export const materialTypes: BomItemType[] = [
  BomItemType.BOM_MECH,
  BomItemType.BOM_ELEC,
  BomItemType.BOM_MAT_INST,
];

const BomDocumentHierarchyDetail = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const ctx = useContext(AppContext);

  const [forProcesses, setForProcesses] = useState<ForProcess[]>([]);
  const [modePreview, setModePreview] = useState(false);

  const [importLoading, setImportLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const toAddJobBomLeveled = useRef([] as PpicJob[]);
  const toAddProductJobBomLeveled = useRef([] as PpicPanelCode[]);

  const boqConvertModalOpen = useRef(false);
  const boqs = useRef(CrmBoqs.fromPartial({ boqs: [] }));
  const selectedBoq = useRef(null as CrmBoq | null);

  const pos = useRef(CrmPurchaseOrders.fromPartial({}));
  const boqItems = useRef(CrmBoqItems.fromPartial({ items: [] }));

  const [selectedBomCopy, setSelectedBomCopy] = useState<{
    bomLeveled: BomLeveledRecursive | null;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [filteredBomLeveledByType, setFilteredBomLeveledByType] = useState<
    BomLeveled[]
  >([]);
  const [selectBomItemModalOpen, setSelectBomItemModalOpen] = useState(false);
  const [bomLeveledDeletedIds, setBomLeveledDeletedIds] = useState<number[]>(
    []
  );
  const [bomLeveledCustomerDeletedIds, setBomLeveledCustomerDeletedIds] =
    useState<number[]>([]);

  const [bomLeveled, setBomLeveled] = useState<BomLeveledRecursive>({
    bomLeveled: { ...defaultBomLeveled },
    children: [],
    bomLeveledIndividualEntities: [],
  });
  const [multiLevel, setMultiLevel] = useState(true);
  const [selectItemModal, setSelectItemModal] = useState(false);
  const [searchItemFilter, setSearchItemFilter] = useState("");
  const [selectItemBomLeveledUuid, setSelectItemBomLeveledUuid] = useState(
    null as string | null
  );
  const [customers, setCustomers] = useState<PpicIndividualEntities>(
    PpicIndividualEntities.fromPartial({ entities: [] })
  );
  // const [requestStatus, setRequestStatus] = useState<RequestStatus>(
  //   RequestStatus.Loading
  // );

  const [items, setItems] = useState<ExtItem[]>([]);

  const bomFlattened = flattenBomLeveledToList(bomLeveled);
  const itemsReq = useRef([] as ExtItemReq[]);
  const bomFlattenedMultiplier = flattenBomLeveledToListMultiplier(bomLeveled);
  const [, refresh] = useState(false);
  const jobBomLeveleds = useRef([] as JobBomLeveled[]);
  const jobs = useRef(PpicJobs.fromPartial({ jobs: [] }));
  const posLoading = useRef(false);
  const wos = useRef(MeetingTaskListsView.fromPartial({}));

  const fetchJobBomLeveledsData = async () => {
    if (isNaN(parseInt(id))) {
      return;
    }
    const d = await fetchJobBomLeveleds({
      apiKey: ctx?.apiKey ?? "",
      bomId: id,
    });

    if (d) {
      jobBomLeveleds.current = d;
      render();
    }
  };

  const itemPrices = useRef(
    [] as { extItemId?: number | null; detail?: PpicBomLeveled | null }[]
  );

  const fetchBomWorkOrder = async () => {
    if (id) {
      const d = await fetchWorkOrdersProtoMongo({
        apiKey: ctx?.apiKey ?? "",
        extBomLeveledId: id,
      });

      if (d) {
        wos.current = d;
        render();
      }
    }
  };

  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      withProducts: true,
      all: true,
      withPurchaseOrders: true,
    });

    if (d) {
      jobs.current = d;
    }
  };
  const render = () => {
    refresh((n) => !n);
  };
  useEffect(() => {
    handleInit();
  }, []);

  const inventory = useRef([] as ExtInventory[]);

  useEffect(() => {
    if (inventory.current.length > 0) {
      const bomFlattenedFiltered = bomFlattened.filter(
        (b) => b.bom?.bomLeveled?.extItemId
      );

      console.log("bom flattened filtered:", bomFlattenedFiltered);

      bomFlattenedFiltered.forEach(async (b) => {
        if (
          !itemPrices.current.find(
            (p) => `${p.extItemId}` === `${b.bom?.bomLeveled?.extItemId}`
          )
        ) {
          const foundInv = inventory.current.find(
            (i) => `${i.productId}` === `${b.bom?.bomLeveled?.extItemId}`
          );
          const fetchedItemPrice = PpicBomLeveled.fromPartial({
            priceOriginalCurrency: "IDR",
            priceOriginalPrice: foundInv?.priceRp ?? 0,
            priceIdr: foundInv?.priceRp ?? 0,
          });

          console.log(
            "fetched item price",
            b.bom?.bomLeveled?.extItemId,
            fetchedItemPrice
          );

          if (fetchedItemPrice) {
            itemPrices.current = [
              ...itemPrices.current,
              {
                extItemId: b.bom?.bomLeveled?.extItemId,
                detail: fetchedItemPrice,
              },
            ];

            render();
          }
        }
      });
    }
  }, [bomLeveled, inventory.current]);

  const fetchBoqsData = async () => {
    const d = await fetchExtBoqs({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      boqs.current = d;

      render();
    }
  };

  const fetchInvData = async () => {
    const d = await fetchExtInventory({ apiKey: ctx?.apiKey ?? "", all: true });

    if (d) {
      inventory.current = d;

      render();
    }
  };

  const paramPanelCodeID = useRef(null as string | null);

  useEffect(() => {
    if (paramPanelCodeID.current && jobs.current.jobs.length > 0) {
      console.log("condition found", paramPanelCodeID.current);

      const foundJob = jobs.current.jobs.find((j) =>
        j.panelCodes.find(
          (c) =>
            `${c.masterJavaBaseModel?.id}` === `${paramPanelCodeID.current}`
        )
      );
      const foundPanelCode = foundJob?.panelCodes.find(
        (c) => `${c.masterJavaBaseModel?.id}` === `${paramPanelCodeID.current}`
      );

      console.log(foundJob);
      console.log(foundPanelCode);

      if (foundJob) {
        toAddJobBomLeveled.current.push(foundJob);
      }

      if (foundPanelCode) {
        toAddProductJobBomLeveled.current.push(foundPanelCode);
      }

      render();
    }
  }, [jobs.current]);

  const handleInit = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);

      const panelCodeId = urlParams.get("panelCodeId");
      console.log("urlparam", panelCodeId);

      paramPanelCodeID.current = panelCodeId;

      setLoading(true);
      fetchCustomersData();
      fetchItemRequestData();
      fetchJobBomLeveledsData();
      fetchJobsData();
      fetchBoqsData();
      fetchPOsData();
      fetchInvData();
      fetchBomWorkOrder();

      // const resp = await fetch(`${process.env.REACT_APP_BASE_URL}`)
      const [items, forProcessesData] = await Promise.all([
        fetchItems(process.env.REACT_APP_BASE_URL ?? "", ctx?.apiKey ?? ""),
        fetchForProcesses({ apiKey: ctx?.apiKey ?? "" }),
        // fetchBomLeveled
        isNaN(parseInt(id))
          ? (async () => {})()
          : (async () => {
              try {
                const resp = await fetch(
                  `${process.env.REACT_APP_BASE_URL}/bomleveled/${id}`,
                  {
                    headers: {
                      authorization: ctx?.apiKey ?? "",
                    },
                  }
                );

                if (resp.status !== 200) throw await resp.text();
                setBomLeveled(await resp.json());
              } catch (e) {
                console.log("[failed getting bomleveled]", e);
              }
            })(),
      ]);

      if (items) {
        setItems(items);
      }
      setForProcesses(forProcessesData);
    } catch (e) {
      console.log("[handl init error]", e);
    } finally {
      setLoading(false);
    }
  };

  // console.log("[bom leveled]", bomFlattened);

  const fetchCustomersData = async () => {
    setCustomers(await fetchCustomersProto({ apiKey: ctx?.apiKey ?? "" }));
  };

  const checkBomCostExceeds = () => {
    if (
      bomCostExceeds &&
      !window.confirm(
        `Exceeding PO Cost limit detected:\n (IDR ${Intl.NumberFormat("id-ID", {
          minimumFractionDigits: 2,
        }).format(totalBomPrice)} vs IDR ${Intl.NumberFormat("id-ID", {
          minimumFractionDigits: 2,
        }).format(
          (bomCostExceeds.product.unitPrice ?? 0) *
            (bomCostExceeds.product.qty ?? 0)
        )})\n Are you sure you want to save?`
      )
    ) {
      return false;
    }

    return true;
  };

  const fetchPOsData = async () => {
    posLoading.current = true;

    render();
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
      // render();
    }

    posLoading.current = false;
    render();
  };

  const fetchItemRequestData = async () => {
    const d = await fetchItemsReq({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      itemsReq.current = d;
      render();
    }
  };

  const modifyBom = (param: {
    bom?: BomLeveledRecursive | null;
    uuid?: string | null;
    modifier?: (_?: BomLeveledRecursive | null) => BomLeveledRecursive | null;
  }) => {
    if (param.bom?.bomLeveled?.uuid === param.uuid) {
      param.bom!.bomLeveled = param.modifier?.(param.bom)?.bomLeveled ?? null;
      param.bom!.children = param.modifier?.(param.bom)?.children ?? [];
    } else {
      param.bom?.children.forEach((b) => {
        modifyBom({ bom: b, uuid: param.uuid, modifier: param.modifier });
      });
    }
  };

  const modifyBomLeveled = (param: {
    uuid?: string | null;
    modifier?: (_?: BomLeveledRecursive | null) => BomLeveledRecursive | null;
  }) => {
    const newBom = { ...bomLeveled };
    modifyBom({ bom: newBom, uuid: param.uuid, modifier: param.modifier });
    setBomLeveled(newBom);
  };

  const modifyBomRecursive = (param: {
    bom?: BomLeveledRecursive | null;
    uuid?: string | null;
    modifier?: (_?: BomLeveledRecursive | null) => BomLeveledRecursive | null;
    uuidCheckNeeded?: boolean;
  }) => {
    if (param.bom?.bomLeveled?.uuid === param.uuid || !param.uuidCheckNeeded) {
      param.bom!.bomLeveled = param.modifier?.(param.bom)?.bomLeveled ?? null;
      param.bom!.children = param.modifier?.(param.bom)?.children ?? [];

      param.bom?.children.forEach((b) => {
        modifyBomRecursive({
          bom: b,
          uuid: param.uuid,
          modifier: param.modifier,
          uuidCheckNeeded: false,
        });
      });
    } else {
      param.bom?.children.forEach((b) => {
        modifyBomRecursive({
          bom: b,
          uuid: param.uuid,
          modifier: param.modifier,
          uuidCheckNeeded: true,
        });
      });
    }

    // } else {

    // }
  };

  const modifyBomLeveledRecursive = (param: {
    uuid?: string | null;
    modifier?: (_?: BomLeveledRecursive | null) => BomLeveledRecursive | null;
  }) => {
    const newBom = { ...bomLeveled };
    modifyBomRecursive({
      bom: newBom,
      uuid: param.uuid,
      modifier: param.modifier,
      uuidCheckNeeded: true,
    });
    setBomLeveled(newBom);
  };

  const handleSave = async () => {
    // TODO: alert and return if no approval
    if ((bomLeveled.bomLeveled?.approvals?.length ?? 0) === 0) {
      alert("Approval PIC must be filled.");
      return;
    }

    if (
      bomLeveled.bomLeveled?.id &&
      bomLeveled.bomLeveled?.id !== 0 &&
      !window.confirm(
        "⚠️ Save is not recommended, please refrain from saving in the future and use copy feature. Continue?"
      )
    ) {
      return;
    }

    try {
      setLoading(true);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/bomleveled-save`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(bomLeveled),
        }
      );

      await Promise.all([
        ...bomLeveledDeletedIds.map(async (i) => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_BASE_URL}/bomleveled/${i}/empty`,
              {
                method: "delete",
                headers: {
                  authorization: ctx?.apiKey ?? "",
                },
              }
            );
          } catch (e) {
            console.error(e);
          }
        }),
        ...bomLeveledCustomerDeletedIds.map(async (i) => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_BASE_URL}/bomleveledindividualentities/${i}/empty`,
              {
                method: "delete",
                headers: {
                  authorization: ctx?.apiKey ?? "",
                },
              }
            );
          } catch (e) {
            console.error(e);
          }
        }),
      ]);

      if (res.status !== 200) {
        throw await res.text();
      }

      const bom = (await res.json()) as BomLeveled;

      const resRefresh = await fetch(
        `${process.env.REACT_APP_BASE_URL}/bomleveleds-refresh/${bom.id}`,
        {
          headers: {
            authorization: ctx?.apiKey ?? "",
          },
        }
      );
      // add new job bom leveleds
      const resJobBomLeveledAddNew = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs-bom-leveled-add`,
        {
          method: "POST",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            bomLeveledId: bom.id,
            jobIds: toAddJobBomLeveled.current.map((l) =>
              l.masterJavaBaseModel?.id
                ? !isNaN(parseInt(l.masterJavaBaseModel?.id ?? ""))
                  ? parseInt(l.masterJavaBaseModel?.id ?? "")
                  : null
                : null
            ),
            panelCodeIds: toAddProductJobBomLeveled.current.map((l) =>
              l.masterJavaBaseModel?.id
                ? !isNaN(parseInt(l.masterJavaBaseModel?.id ?? ""))
                  ? parseInt(l.masterJavaBaseModel?.id ?? "")
                  : null
                : null
            ),
          } as JobBomLeveledAddBody),
        }
      );

      // Save job bom leveleds
      const resJobBomLeveleds = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobbomleveleds-save-bulk`,
        {
          method: "POST",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(jobBomLeveleds.current),
        }
      );

      history.push("/bomdocumentshierarchy");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleFindBomType = (
    selectedBomCopy?: BomLeveledRecursive | null
  ): BomType | null => {
    switch (selectedBomCopy?.bomLeveled?.type) {
      case "Set":
        return "Module";

      case "Module":
        return "Submodule";

      case "Submodule":
        return "Component";

      case "Component":
        return null;

      default:
        return "Set";
    }
  };

  const bomListFlattened = createBomListFlattened([], [bomLeveled]);
  const bomListFlattenedMapped = [
    ...new Set(
      bomListFlattened
        .filter(
          (i) =>
            i.bom.bom?.bomLeveled?.extItemId !== null &&
            i.bom.bom?.bomLeveled?.extItemId !== undefined
        )
        .map((l) => l.bom.bom?.bomLeveled?.extItemId)
    ),
  ].map((id) => ({
    item: items.find((i) => i.id === id),
    value: bomListFlattened
      .filter((bx) => bx.bom.bom?.bomLeveled?.extItemId === id)
      .reduce((acc, b) => acc + (b.bom.multiplier ?? 0), 0),
  }));

  const totalBomPrice = bomListFlattenedMapped.reduce((acc, b) => {
    const foundItemPrice = itemPrices.current.find(
      (p) => `${p.extItemId}` === `${b.item?.id}`
    );

    return acc + b.value * (foundItemPrice?.detail?.priceIdr ?? 0);
  }, 0.0);

  const bomCostExceeds = toAddJobBomLeveled.current?.[0]?.jobPurchaseOrders
    .flatMap((j) => {
      const foundPO = pos.current.purchaseOrders.find(
        (px) => `${px.id}` === `${j.extPurchaseOrderId}`
      );

      return foundPO?.products.map((p) => ({ product: p, po: foundPO }));
    })
    .find((d) => {
      return (
        totalBomPrice > (d?.product.unitPrice ?? 0) * (d?.product.qty ?? 0)
      );
    });

  const handleFileCopy = async (params: { saveAs?: boolean }) => {
    const ok = checkBomCostExceeds();

    if (!ok) {
      return;
    }

    const name = params.saveAs
      ? `${bomLeveled.bomLeveled?.name} copy`
      : `${bomLeveled.bomLeveled?.name?.split(" REV ")?.[0]} REV ${
          (bomLeveled.bomLeveled?.revision ?? 0) + 1
        }`;

    const prompt = window.prompt("Enter name for new BOM", name);

    if (prompt && prompt !== "") {
      try {
        setLoading(true);

        const updateBomLeveledId = (b: BomLeveledRecursive | null) => {
          if (b) {
            if (b.bomLeveled) {
              b.bomLeveled.uuid = uuidv4();
              b.bomLeveled.id = null;
            }

            b.children.forEach((b) => {
              updateBomLeveledId(b);
            });
          }
        };

        const newBomLeveled = { ...bomLeveled };
        updateBomLeveledId(newBomLeveled);

        console.log("[bom to save]", newBomLeveled);

        if (newBomLeveled.bomLeveled) {
          newBomLeveled.bomLeveled.name = prompt;

          if (!params.saveAs) {
            newBomLeveled.bomLeveled.revision =
              (newBomLeveled.bomLeveled.revision ?? 0) + 1;
            newBomLeveled.bomLeveled.deactivationDate = null;
          } else {
            newBomLeveled.bomLeveled.revision = 0;
          }
        }

        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/bomleveled-save`,
          {
            method: "post",
            headers: {
              "content-type": "application/json",
              authorization: ctx?.apiKey ?? "",
            },
            body: JSON.stringify(newBomLeveled),
          }
        );

        if (res.status !== 200) {
          return await res.text();
        }

        const savedBomLeveled = (await res.json()) as BomLeveled | null;

        if (!params.saveAs) {
          // Deactivate new bom
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/bomleveleds-deactivate/${bomLeveled.bomLeveled?.id}`
          );
        }
        history.push("/bomdocumentshierarchy");
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={boqConvertModalOpen.current}
        onClose={() => {
          boqConvertModalOpen.current = false;
          render();
        }}
        onHide={() => {
          boqConvertModalOpen.current = false;
          render();
        }}
      >
        <ModalHeader>
          <div>
            <h4>BOQ Convert Select</h4>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="m-3">
            <Select
              options={boqs.current.boqs.map((b) => ({
                label: `${b.boqProductName}: ${b.type} (PO ${
                  b.poProductNumber ?? "No PO Product Number"
                } = ${b.poProductName ?? "No PO Product Name"})`,
                value: b,
              }))}
              placeholder="Select BOQ..."
              onChange={async (v) => {
                const val = v as { value: CrmBoq };

                if (val.value.poProductId) {
                  selectedBoq.current = val.value;

                  const d = await fetchExtBoqItems({
                    apiKey: ctx?.apiKey ?? "",
                    id: val.value.poProductId,
                  });

                  if (d) {
                    boqItems.current = d;
                    render();
                  }
                }
              }}
            />

            <div
              className="overflow-auto"
              style={{ height: "45vh", resize: "vertical" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "MFR",
                    "Part Num",
                    "Part Desc",
                    "qty",
                    "UM",
                    // "qty",
                    "Linked?",
                  ].map((h) => {
                    return (
                      <>
                        <th
                          className="bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      </>
                    );
                  })}
                </tr>
                {boqItems.current.items.map((i, i_) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">{i_ + 1}</td>
                        <td className="border border-dark">{i.mfr}</td>
                        <td className="border border-dark">{i.partNum}</td>
                        <td className="border border-dark">{i.partDesc}</td>
                        <td className="border border-dark">{i.qty}</td>
                        <td className="border border-dark">{i.um}</td>
                        <td
                          className={`border border-dark text-light ${
                            i.inventory ? `bg-success` : `bg-danger`
                          }`}
                        >
                          <strong>{i.inventory ? "Yes" : "No"}</strong>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setBomLeveled({
                  ...bomLeveled,
                  children: [
                    {
                      bomLeveled: {
                        ...initialBomLeveled,
                        uuid: v4(),
                        type: "Set",
                        qty: 1,
                        name: `${selectedBoq.current?.boqProductName} ${selectedBoq.current?.type}`,
                        // extItemId:
                      },
                      children: [
                        {
                          bomLeveled: {
                            ...initialBomLeveled,
                            uuid: v4(),
                            type: "Module",
                            qty: 1,
                            name: `${selectedBoq.current?.boqProductName} ${selectedBoq.current?.type}`,
                            // extItemId:
                          },
                          children: [
                            {
                              bomLeveled: {
                                ...initialBomLeveled,
                                uuid: v4(),
                                type: "Submodule",
                                qty: 1,
                                name: `${selectedBoq.current?.boqProductName} ${selectedBoq.current?.type}`,
                                // extItemId:
                              },
                              children: boqItems.current.items
                                .map((i) => {
                                  return {
                                    bomLeveled: {
                                      ...initialBomLeveled,
                                      uuid: v4(),
                                      type: "Component",
                                      qty: i.qty,
                                      name: `${i.mfr ?? ""}: ${
                                        i.partNum ?? ""
                                      }: ${i.partName ?? ""}: ${
                                        i.partDesc ?? ""
                                      }`,
                                      extItemId: i.inventory
                                        ? i.equalProductId
                                        : null,
                                    },
                                  };
                                })
                                .map((i) => i as BomLeveledRecursive),
                              bomLeveledIndividualEntities: [],
                            },
                          ],
                          bomLeveledIndividualEntities: [],
                        },
                      ],
                      bomLeveledIndividualEntities: [],
                    },
                  ],
                });

                boqConvertModalOpen.current = false;
                render();
              }}
            >
              Add to BOM
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <div className="m-3">
        <div className="d-flex align-items-center">
          <h5>BOM Detail</h5>
          {importLoading ? (
            <>
              <Spinner animation="border" />
            </>
          ) : (
            <>
              <div>
                <button
                  className="d-flex btn btn-primary btn-sm"
                  onClick={(e) => {
                    const ok = checkBomCostExceeds();

                    if (!ok) {
                      return;
                    }

                    handleSave();
                  }}
                >
                  <Save />
                  Save
                </button>
              </div>
            </>
          )}

          <div className="mx-2">
            <button
              className="d-flex btn btn-success btn-sm"
              onClick={async (e) => {
                handleFileCopy({ saveAs: false });
              }}
            >
              <FileCopy />
              Revision
            </button>
          </div>

          <div className="mx-2">
            <button
              className="d-flex btn btn-secondary btn-sm"
              onClick={async (e) => {
                handleFileCopy({ saveAs: true });
              }}
            >
              <FileCopy />
              Save As
            </button>
          </div>

          <input
            className="w-100 form-control form-control-sm mx-3"
            defaultValue={bomLeveled?.bomLeveled?.name ?? ""}
            onBlur={(e) => {
              setBomLeveled({
                ...bomLeveled,
                bomLeveled: bomLeveled.bomLeveled
                  ? { ...bomLeveled.bomLeveled, name: e.target.value }
                  : bomLeveled.bomLeveled,
              });
            }}
          />
        </div>

        <div className="d-flex">
          <div>BOM status:</div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setBomLeveled({
                ...bomLeveled,
                bomLeveled: bomLeveled.bomLeveled
                  ? {
                      ...bomLeveled.bomLeveled,
                      deactivationDate: bomLeveled.bomLeveled.deactivationDate
                        ? null
                        : new Date().toISOString(),
                    }
                  : bomLeveled.bomLeveled,
              });
            }}
            className={`px-3 py-2 rounded rounded-md text-light ${
              bomLeveled.bomLeveled?.deactivationDate
                ? `bg-danger`
                : `bg-success`
            }`}
          >
            <strong>
              {bomLeveled.bomLeveled?.deactivationDate
                ? `Inactive (${intlFormat({
                    date: bomLeveled.bomLeveled?.deactivationDate,
                    dateStyle: "long",
                  })})`
                : `Active`}
            </strong>
          </div>
        </div>

        <hr className="border border-dark" />

        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setBomLeveled({
                  ...bomLeveled,
                  children: [
                    ...bomLeveled.children,
                    {
                      bomLeveled: {
                        ...defaultBomLeveled,
                        uuid: uuidv4(),
                        type: "Set",
                      },
                      children: [],
                      bomLeveledIndividualEntities: [],
                    },
                  ],
                });
              }}
            >
              <Add /> New Set
            </button>

            <button
              className="mx-2 btn btn-sm btn-secondary"
              onClick={async () => {
                boqConvertModalOpen.current = true;

                render();
              }}
            >
              <CloudUpload /> BOQ Convert
            </button>

            <button
              className="mx-2 btn btn-sm btn-secondary"
              onClick={async () => {
                setShowDialog(true);

                try {
                  const resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/bomleveled-type-filter?bomType=Set`
                  );

                  if (resp.status !== 200) throw await resp.text();

                  setFilteredBomLeveledByType(await resp.json());
                } catch (e) {
                  console.log("[getting bom not ok]", e);
                }
              }}
            >
              <FileCopy /> Add Set from Existing
            </button>
            <div>
              {importLoading ? (
                <>
                  <Spinner animation="border" />{" "}
                </>
              ) : (
                <>
                  <div className="d-flex">
                    <strong>XLS import</strong>

                    <div className="d-flex">
                      <input
                        type="file"
                        accept=".xls"
                        onChange={async (e) => {
                          const file = e.target.files?.[0];
                          const reader = new FileReader();

                          try {
                            if (file) {
                              reader.readAsDataURL(file);
                              reader.onload = async () => {
                                const data = (reader?.result as string)?.split(
                                  "base64,"
                                )[1];
                                console.log(data);

                                try {
                                  setImportLoading(true);
                                  const resp = await fetch(
                                    `${
                                      process.env.REACT_APP_BASE_URL
                                    }/bom-documents-hierarchy-xls-import?multiLevel=${
                                      multiLevel ?? ""
                                    }`,
                                    {
                                      method: "post",
                                      headers: {
                                        "content-type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        data: data,
                                      } as PpicBomDocumentHierarchyXls),
                                    }
                                  );

                                  if (resp.status !== 200)
                                    throw await resp.text();

                                  const boms = (await resp.json()) as
                                    | BomLeveled[]
                                    | null
                                    | undefined;

                                  const mapRecursiveBom = (
                                    bom: BomLeveled | null | undefined
                                  ): BomLeveledRecursive | null =>
                                    bom
                                      ? {
                                          bomLeveled: bom,
                                          children:
                                            bom?.children?.map((c) =>
                                              mapRecursiveBom(c)
                                            ) ?? [],
                                          bomLeveledIndividualEntities: [],
                                        }
                                      : null;

                                  if (boms) {
                                    console.log("boms", boms);
                                    setBomLeveled({
                                      ...bomLeveled,
                                      children: [
                                        ...bomLeveled.children,
                                        ...(boms?.map((b) =>
                                          mapRecursiveBom(b)
                                        ) ?? []),
                                      ],
                                    });
                                  }
                                } catch (e) {
                                } finally {
                                  setImportLoading(false);
                                }
                              };
                              reader.onerror = (err) => {
                                console.error(err);
                              };
                            }
                          } catch (e) {}
                        }}
                      ></input>
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex">
                Multilevel?
                <input
                  type="checkbox"
                  checked={multiLevel}
                  onClick={() => {
                    setMultiLevel(!multiLevel);
                  }}
                />
              </div>
            </div>

            {loading ? <Spinner animation="border" /> : <></>}
          </div>
          <div>
            <button
              onClick={() => {
                setModePreview(!modePreview);
              }}
              className="btn btn-primary"
            >
              Toggle editor/preview
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="mx-2 flex-grow-1">
            <Select
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={customers.entities ?? []}
              getOptionLabel={(c) => `${c.name}`}
              placeholder="Customer..."
              onChange={(c) => {
                const val = c as PpicIndividualEntity;

                const newIndividualEntity = {
                  ...defaultBomleveledIndividualEntities,
                  individualEntity: {
                    ...initialIndividualEntity,
                    id: val.masterJavaBaseModel?.id
                      ? isNaN(parseInt(val.masterJavaBaseModel.id ?? ""))
                        ? 0
                        : parseInt(val.masterJavaBaseModel.id ?? "")
                      : 0,
                  },
                };

                if (
                  !bomLeveled.bomLeveledIndividualEntities?.find(
                    (blie) =>
                      `${blie.individualEntity?.id}` ===
                      `${val.masterJavaBaseModel?.id}`
                  )
                ) {
                  setBomLeveled({
                    ...bomLeveled,
                    bomLeveledIndividualEntities:
                      bomLeveled.bomLeveledIndividualEntities
                        ? [
                            ...bomLeveled.bomLeveledIndividualEntities,
                            newIndividualEntity,
                          ]
                        : [newIndividualEntity],
                  });
                }
              }}
            />
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setSelectItemModal(true);
                setSelectBomItemModalOpen(true);
              }}
            >
              Select Item
            </button>
            {bomLeveled.bomLeveled?.extItemToCreateId ? (
              <>
                {loading ? (
                  <>Loading item...</>
                ) : (
                  <>
                    (#{bomLeveled.bomLeveled?.extItemToCreateId})
                    {getItemFullDescription(
                      items.find(
                        (i) =>
                          `${i.id}` ===
                          `${bomLeveled.bomLeveled?.extItemToCreateId}`
                      )
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <strong className="text-danger">No Item Selected</strong>
              </>
            )}
          </div>
          <div className="d-flex">
            <div>Mech?</div>
            <input
              type="checkbox"
              checked={bomLeveled.bomLeveled?.mechExists ? true : false}
              onClick={() => {
                setBomLeveled({
                  ...bomLeveled,
                  bomLeveled: bomLeveled.bomLeveled
                    ? {
                        ...bomLeveled.bomLeveled,
                        mechExists: !bomLeveled.bomLeveled.mechExists,
                      }
                    : bomLeveled.bomLeveled,
                });
              }}
            />
            <div>Elec?</div>
            <input
              type="checkbox"
              checked={bomLeveled.bomLeveled?.elecExists ? true : false}
              onClick={() => {
                setBomLeveled({
                  ...bomLeveled,
                  bomLeveled: bomLeveled.bomLeveled
                    ? {
                        ...bomLeveled.bomLeveled,
                        elecExists: !bomLeveled.bomLeveled.elecExists,
                      }
                    : bomLeveled.bomLeveled,
                });
              }}
            />
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>

        {true ? (
          <>
            {" "}
            <div>
              <strong>Add to Job Product</strong>
            </div>
            <div>
              <Select
                placeholder="Job..."
                options={jobs.current.jobs.map((j) => ({
                  label: j.name,
                  value: j,
                }))}
                onChange={async (v) => {
                  const val = v as { value: PpicJob };

                  if (
                    !toAddJobBomLeveled.current.find(
                      (l) =>
                        `${l.masterJavaBaseModel?.id}` ===
                        `${val.value.masterJavaBaseModel?.id}`
                    )
                  ) {
                    toAddJobBomLeveled.current = [val.value];

                    // Fetch PO
                  }

                  render();
                }}
              />
            </div>
            <div>
              <ol>
                {toAddJobBomLeveled.current.map((j) => {
                  const foundJob = jobs.current.jobs.find(
                    (jx) =>
                      `${jx.masterJavaBaseModel?.id}` ===
                      `${j.masterJavaBaseModel?.id}`
                  );
                  return (
                    <>
                      <li>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            toAddJobBomLeveled.current =
                              toAddJobBomLeveled.current.filter(
                                (l) =>
                                  `${l.masterJavaBaseModel?.id}` !==
                                  `${j.masterJavaBaseModel?.id}`
                              );
                            render();
                          }}
                        >
                          <div>{foundJob?.name}</div>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ol>
            </div>
            {/* TODO: look PO value */}
            <div className="d-flex">
              <strong>
                Last BOM Snapshot (
                {bomLeveled.bomLeveled?.snapshotPriceLast ? (
                  intlFormat({
                    date: bomLeveled.bomLeveled.snapshotPriceLast,
                    dateStyle: "medium",
                    timeStyle: "short",
                  })
                ) : (
                  <span className="text-danger">None</span>
                )}
                ): IDR{" "}
                {Intl.NumberFormat("id-ID", {
                  minimumFractionDigits: 2,
                }).format(bomLeveled.bomLeveled?.snapshotPriceIdr ?? 0)}
              </strong>

              <div className="mx-3">
                <button
                  className="btn btn-sm btn-primary px-1 py-0"
                  onClick={() => {
                    if (!window.confirm("Snapshot price now?")) {
                      return;
                    }

                    if (bomLeveled.bomLeveled) {
                      bomLeveled.bomLeveled.snapshotPriceIdr = totalBomPrice;
                      bomLeveled.bomLeveled.snapshotPriceLast =
                        new Date().toISOString();

                      render();
                    }
                  }}
                >
                  <PhotoCamera /> Snapshot Now
                </button>
              </div>
            </div>
            <div>
              <strong>
                Total BOM Price:{" "}
                <span className={`px-2 ${bomCostExceeds ? `bg-warning` : ``}`}>
                  IDR{" "}
                  {Intl.NumberFormat("id-ID", {
                    minimumFractionDigits: 2,
                  }).format(totalBomPrice)}
                </span>
              </strong>
            </div>
            {posLoading.current ? (
              <>
                <div className="spinner-border spinner-border-sm"></div>
              </>
            ) : (
              <>
                {(toAddJobBomLeveled.current.length ?? 0) > 0 ? (
                  <>
                    {toAddJobBomLeveled.current.map((j) => {
                      return (
                        <>
                          <div>
                            {/* <div>{j.name}</div> */}
                            <div>
                              <strong>POs:</strong>
                            </div>
                            <div>
                              <ol>
                                {j.jobPurchaseOrders.map((p) => {
                                  const foundPO =
                                    pos.current.purchaseOrders.find(
                                      (px) =>
                                        `${px.id}` === `${p.extPurchaseOrderId}`
                                    );
                                  return (
                                    <>
                                      {/* {JSON.stringify(foundPO)} */}

                                      <li>
                                        <div>
                                          <div>
                                            {foundPO?.purchaseOrderNumber}
                                          </div>

                                          <div>
                                            <strong>Products:</strong>
                                          </div>
                                          <div>
                                            <ol>
                                              {foundPO?.products.map((pr) => {
                                                const bomPriceExceeds =
                                                  totalBomPrice >
                                                  (pr.unitPrice ?? 0) *
                                                    (pr.qty ?? 0);
                                                return (
                                                  <>
                                                    <li>
                                                      <div>
                                                        <div>
                                                          {pr.partNumber}{" "}
                                                          {pr.partName}, qty{" "}
                                                          {pr.qty} ={" "}
                                                          <strong>
                                                            IDR{" "}
                                                            <span
                                                              className={`px-1 mr-2 ${
                                                                bomPriceExceeds
                                                                  ? `bg-warning`
                                                                  : ``
                                                              }`}
                                                            >
                                                              {Intl.NumberFormat(
                                                                "id-ID",
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                }
                                                              ).format(
                                                                (pr.unitPrice ??
                                                                  0) *
                                                                  (pr.qty ?? 0)
                                                              )}
                                                            </span>
                                                            {bomPriceExceeds ? (
                                                              <>
                                                                <span className="bg-danger text-light px-1">
                                                                  Cost Exceeds
                                                                </span>
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </strong>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  </>
                                                );
                                              })}
                                            </ol>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ol>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            <div>
              <Select
                placeholder="Product..."
                options={toAddJobBomLeveled.current
                  .flatMap((j) =>
                    j.panelCodes.map((c) => ({ job: j, panelCode: c }))
                  )
                  .map((j) => ({
                    label: `${j.panelCode.type}: ${j.panelCode.name}`,
                    value: j.panelCode,
                  }))}
                onChange={(v) => {
                  const val = v as { value: PpicPanelCode };

                  if (
                    !toAddProductJobBomLeveled.current.find(
                      (l) =>
                        `${l.masterJavaBaseModel?.id}` ===
                        `${val.value.masterJavaBaseModel?.id}`
                    )
                  ) {
                    toAddProductJobBomLeveled.current.push(val.value);
                  }

                  render();
                }}
              />
            </div>
            <div>
              <ol>
                {toAddProductJobBomLeveled.current.map((j) => {
                  const foundPanelCode = jobs.current.jobs
                    .flatMap((j) => j.panelCodes)
                    .find(
                      (jx) =>
                        `${jx.masterJavaBaseModel?.id}` ===
                        `${j.masterJavaBaseModel?.id}`
                    );
                  return (
                    <>
                      <li>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            toAddProductJobBomLeveled.current =
                              toAddProductJobBomLeveled.current.filter(
                                (l) =>
                                  `${l.masterJavaBaseModel?.id}` !==
                                  `${j.masterJavaBaseModel?.id}`
                              );
                            render();
                          }}
                        >
                          <div>
                            {foundPanelCode?.type}: {foundPanelCode?.name}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ol>
            </div>
            <div>
              <hr className="border border-dark" />
            </div>
          </>
        ) : (
          <></>
        )}

        {bomListFlattened.find(
          (b) =>
            b.bom.bom?.bomLeveled?.extRequestItemId &&
            b.bom.bom?.bomLeveled?.extRequestItemId !== 0
        ) ? (
          <>
            <div>
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  if (!window.confirm(`Confirm generate item requests?`)) {
                    return;
                  }

                  const itemsPn = bomListFlattened
                    .map((b) => {
                      const reqFound = itemsReq.current.find(
                        (i) =>
                          b.bom.bom?.bomLeveled?.extRequestItemId &&
                          `${i.id}` ===
                            `${b.bom.bom?.bomLeveled?.extRequestItemId}`
                      );

                      return { req: reqFound, bom: b };
                    })
                    .filter((d) => d.req);

                  console.log("itemspn", itemsPn);
                  // console.log("itemsreq", itemsReq.current);

                  const itemsPnNotFound = itemsPn.filter(
                    (i) =>
                      !items.find(
                        (ix) => `${ix.partNum}` === `${i.req?.partNum}`
                      )
                  );

                  const itemsPnFound = itemsPn
                    .map((i) => ({
                      itemPn: i,
                      item: items.find(
                        (ix) => `${ix.partNum}` === `${i.req?.partNum}`
                      ),
                    }))
                    .filter((d) => d.item);

                  alert(
                    `Items found:\n${itemsPnFound
                      .map(
                        (p, i) =>
                          `${i + 1}. ${p.itemPn.req?.partNum}:${
                            p.itemPn.req?.partName
                          }`
                      )
                      .join("\n")}\nItems not found:\n${itemsPnNotFound
                      .map(
                        (p, i) =>
                          `${i + 1}. ${p.req?.partNum}:${p.req?.partName}`
                      )
                      .join("\n")}`
                  );

                  itemsPnFound.forEach((i) => {
                    modifyBomLeveled({
                      uuid: i.itemPn.bom.bom.bom?.bomLeveled?.uuid,
                      modifier: (b) => {
                        if (b) {
                          return {
                            ...b,
                            bomLeveled: b.bomLeveled
                              ? {
                                  ...b.bomLeveled,
                                  extItemId: i.item?.id ?? null,
                                }
                              : b.bomLeveled,
                          };
                        } else {
                          return null;
                        }
                      },
                    });
                  });
                }}
              >
                Generate requested items
              </button>
            </div>
          </>
        ) : (
          <></>
        )}

        <div>
          <ol>
            {bomLeveled.bomLeveledIndividualEntities?.map((blie) => {
              const foundCustomer = customers.entities.find(
                (e) =>
                  `${e.masterJavaBaseModel?.id}` ===
                  `${blie.individualEntity?.id}`
              );
              return (
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setBomLeveledCustomerDeletedIds([
                        ...bomLeveledCustomerDeletedIds,
                        blie.id ?? 0,
                      ]);
                      setBomLeveled({
                        ...bomLeveled,
                        bomLeveledIndividualEntities:
                          bomLeveled.bomLeveledIndividualEntities
                            ? bomLeveled.bomLeveledIndividualEntities.filter(
                                (bliex) =>
                                  `${blie.individualEntity?.id}` !==
                                  `${bliex.individualEntity?.id}`
                              )
                            : bomLeveled.bomLeveledIndividualEntities,
                      });
                    }}
                  >
                    <div>
                      {foundCustomer ? foundCustomer.name : "Invalid customer"}
                    </div>
                  </div>
                </li>
              );
            })}
          </ol>
        </div>

        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <strong>Approval Needed</strong>
        </div>

        <div>
          <Select
            options={ctx?.extUsers.map((u) => ({
              label: `${u.name} (${u.departmentName})`,
              value: u,
            }))}
            placeholder="User.."
            onChange={(v) => {
              const val = v as { value: ExtUser };

              if (val.value.id) {
                if (
                  !bomLeveled.bomLeveled?.approvals?.find(
                    (a) => `${a.extUserId}` === `${val.value.id}`
                  )
                ) {
                  const newBomLeveledApproval = {
                    ...initialBomLeveledApproval,
                    uuid: v4(),
                    extUserId: val.value.id,
                  } as BomLeveledApproval;

                  if (bomLeveled.bomLeveled?.approvals) {
                    bomLeveled.bomLeveled?.approvals.push(
                      newBomLeveledApproval
                    );
                  } else {
                    if (bomLeveled.bomLeveled) {
                      bomLeveled.bomLeveled.approvals = [newBomLeveledApproval];
                    }
                  }

                  render();
                }
              }
            }}
          />
        </div>

        <div>
          <ol>
            {bomLeveled.bomLeveled?.approvals?.map((a) => {
              const foundUser = ctx?.extUsers.find(
                (u) => `${u.id}` === `${a.extUserId}`
              );
              return (
                <>
                  <li>
                    <div>
                      <div>
                        {foundUser?.name ?? ""} (
                        {foundUser?.departmentName ?? ""})
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ol>
        </div>

        <div
          className={`${
            wos.current.taskLists.find((tL) =>
              tL.taskList?.meetingTasks.find(
                (mt) => mt.status === "OUTSTANDING"
              )
            )
              ? `text-danger`
              : `text-success`
          }`}
        >
          {wos.current.taskLists.length > 0 ? (
            <>
              WO:{" "}
              {wos.current.taskLists?.[0]?.taskList?.masterJavaBaseModel?.id}{" "}
              {intlFormat({
                date: wos.current.taskLists?.[0]?.taskList?.taskStart,
                dateStyle: "long",
              })}{" "}
              (
              {wos.current.taskLists?.[0].taskList?.meetingTasks
                .flatMap((mt) => mt.meetingTaskInCharges)
                .map((ic) => {
                  const user = ctx?.extUsers.find(
                    (u) => `${u.id}` === `${ic.extUserId}`
                  );

                  return `${user?.name}`;
                })
                .join(", ")}
              )
            </>
          ) : (
            <></>
          )}
        </div>

        {modePreview ? (
          <>
            <div className="my-1">
              <div
                style={{ height: "75vh", resize: "vertical" }}
                className="overflow-auto border border-dark"
              >
                <table
                  className="table table-bordered table-hover table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <thead>
                    <tr>
                      {[
                        `#`,
                        "ItemID",
                        "MFR",
                        "PN",
                        "Name",
                        "Desc",
                        "Qty needed",
                        "UM",
                      ].map((h) => (
                        <th className="bg-dark text-light sticky-top">{h}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {bomListFlattenedMapped.map((b, i) => {
                      return (
                        <>
                          <tr>
                            <td className="border-secondary border">{i + 1}</td>
                            <td className="border-secondary border">
                              {b.item?.id}
                            </td>

                            <td className="border-secondary border">
                              {b.item?.mfr}
                            </td>
                            <td className="border-secondary border">
                              {b.item?.partNum}
                            </td>
                            <td className="border-secondary border">
                              {b.item?.partName}
                            </td>
                            <td className="border-secondary border">
                              {b.item?.partDesc}
                            </td>
                            <td className="border-secondary border">
                              {b.value}
                            </td>
                            <td className="border-secondary border">
                              {b.item?.defaultUm}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>{" "}
          </>
        ) : (
          <>
            {" "}
            <div className="my-1">
              <div
                style={{ height: "75vh", resize: "vertical" }}
                className="overflow-auto border border-dark"
              >
                <table
                  className="table table-bordered table-hover table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {[
                      `# (total ${bomFlattened.length})`,
                      // "Name",

                      "Type",
                      "Mat Type",

                      "Item",
                      "Qty",
                      "For process",
                      "Actions",
                      "Remark",
                      "PN Request",
                    ].map((h) => (
                      <th
                        className="bg-dark text-light"
                        style={{ top: 0, position: "sticky" }}
                      >
                        {h}
                      </th>
                    ))}
                  </tr>
                  {bomFlattened.map((b) => {
                    return (
                      <tr
                        style={{
                          backgroundColor: checkBomDocumentTypeColor(
                            b?.bom?.bomLeveled?.type
                          ),
                          position:
                            b?.bom?.bomLeveled?.type !== "Component"
                              ? "sticky"
                              : undefined,
                          top:
                            b?.bom?.bomLeveled?.type !== "Component"
                              ? 0
                              : undefined,
                          zIndex:
                            b?.bom?.bomLeveled?.type !== "Component"
                              ? 10
                              : undefined,
                        }}
                      >
                        <td className="border border-dark">
                          <div>
                            <div>
                              {b?.level?.join(".")}{" "}
                              {b?.bom?.bomLeveled?.id &&
                              b?.bom?.bomLeveled?.id !== 0
                                ? `(UID ${b?.bom?.bomLeveled?.id})`
                                : "(unsaved)"}
                            </div>
                            {/* <div>
                          <small>
                            <strong>Unique ID: </strong>
                          </small>
                        </div> */}
                          </div>
                        </td>
                        {/* <td>
                          
                        </td> */}
                        <td className="border border-dark">
                          {b?.bom?.bomLeveled?.type}
                        </td>
                        <td className="border border-dark">
                          <div style={{ minWidth: 150 }}>
                            <Select
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              value={materialTypes
                                .map((m) => ({
                                  label: bomItemTypeToJSON(m),
                                  value: m,
                                }))
                                .find(
                                  (m) =>
                                    bomItemTypeFromJSON(m.value) ===
                                    bomItemTypeFromJSON(
                                      b.bom?.bomLeveled?.materialType
                                    )
                                )}
                              options={materialTypes.map((m) => ({
                                label: bomItemTypeToJSON(m),
                                value: m,
                              }))}
                              placeholder="MatType..."
                              onChange={(c) => {
                                const val = c as { value: BomItemType };
                                modifyBomLeveledRecursive({
                                  uuid: b.bom?.bomLeveled?.uuid,
                                  modifier: (bx) =>
                                    bx
                                      ? {
                                          ...bx,
                                          bomLeveled: bx?.bomLeveled
                                            ? {
                                                ...bx.bomLeveled,
                                                materialType: val.value,
                                              }
                                            : bx?.bomLeveled,
                                        }
                                      : null,
                                });
                              }}
                            />
                          </div>
                          {/* <div>{b.bom?.bomLeveled?.materialType}</div> */}
                        </td>
                        <td
                          className={`border border-dark ${
                            b?.bom?.bomLeveled?.type === "Component" &&
                            !items.find(
                              (i) => i.id === b?.bom?.bomLeveled?.extItemId
                            )
                              ? "bg-danger"
                              : ""
                          }`}
                        >
                          <div>
                            {b.bom?.bomLeveled?.type !== "Component" ? (
                              <>
                                {" "}
                                <input
                                  className="form-control form-control-sm"
                                  key={`bom-item-${b.bom?.bomLeveled?.uuid}`}
                                  defaultValue={b.bom?.bomLeveled?.name ?? ""}
                                  onBlur={(e) => {
                                    modifyBomLeveled({
                                      uuid: b.bom?.bomLeveled?.uuid,
                                      modifier: (bx) =>
                                        bx
                                          ? {
                                              ...bx,
                                              bomLeveled: bx?.bomLeveled
                                                ? {
                                                    ...bx.bomLeveled,
                                                    name: e.target.value ?? "",
                                                  }
                                                : bx?.bomLeveled,
                                            }
                                          : null,
                                    });
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          {
                            <div className="d-flex">
                              <button
                                onClick={() => {
                                  setSelectItemModal(true);
                                  setSelectItemBomLeveledUuid(
                                    b.bom?.bomLeveled?.uuid ?? ""
                                  );
                                }}
                                className="btn btn-sm btn-primary"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => {
                                  if (!window.confirm("Really clear item?")) {
                                    return;
                                  }

                                  modifyBomLeveled({
                                    uuid: b.bom?.bomLeveled?.uuid,
                                    modifier: (bx) =>
                                      bx
                                        ? {
                                            ...bx,
                                            bomLeveled: bx?.bomLeveled
                                              ? {
                                                  ...bx.bomLeveled,
                                                  extItemId: null,
                                                }
                                              : bx?.bomLeveled,
                                          }
                                        : null,
                                  });
                                }}
                                className="btn btn-sm btn-danger"
                              >
                                Clear
                              </button>
                              {(() => {
                                const foundItem = items.find(
                                  (ix) => ix.id === b.bom?.bomLeveled?.extItemId
                                );

                                return foundItem ? (
                                  <div>
                                    {`(${foundItem.partNum} ${foundItem.mfr}) ${foundItem.partName} - ${foundItem.partDesc}`}
                                  </div>
                                ) : (
                                  <></>
                                );
                              })()}
                            </div>
                          }

                          <div
                            // style={{ cursor: "pointer" }}
                            // onClick={() => {
                            //   if (!window.confirm("Really clear item?")) {
                            //     return;
                            //   }

                            //   modifyBomLeveled({
                            //     uuid: b.bom?.bomLeveled?.uuid,
                            //     modifier: (bx) =>
                            //       bx
                            //         ? {
                            //             ...bx,
                            //             bomLeveled: bx?.bomLeveled
                            //               ? {
                            //                   ...bx.bomLeveled,
                            //                   extItemId: null,
                            //                 }
                            //               : bx?.bomLeveled,
                            //           }
                            //         : null,
                            //   });
                            // }}
                            className="d-flex flex-wrap"
                          >
                            <small
                              className={`${
                                b.bom?.bomLeveled?.extItemId
                                  ? `bg-success`
                                  : `bg-danger`
                              } font-weight-bold text-light`}
                            >
                              Item
                            </small>
                            <small>
                              <strong> {b.bom?.bomLeveled?.name} </strong>
                              {/* {JSON.stringify(b.bom?.bomLeveled?.extItemId)} */}
                            </small>
                          </div>
                        </td>
                        <td
                          className={`border border-dark d-flex ${
                            !b?.bom?.bomLeveled?.qty ||
                            b?.bom?.bomLeveled?.qty === 0
                              ? "bg-danger"
                              : ""
                          }`}
                        >
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            style={{ width: 75 }}
                            key={b?.bom?.bomLeveled?.uuid}
                            defaultValue={b?.bom?.bomLeveled?.qty ?? undefined}
                            onBlur={(e) => {
                              modifyBomLeveled({
                                uuid: b?.bom?.bomLeveled?.uuid,
                                modifier: (b) =>
                                  b
                                    ? {
                                        ...b,
                                        bomLeveled: b?.bomLeveled
                                          ? {
                                              ...b.bomLeveled,
                                              qty: isNaN(
                                                parseFloat(e.target.value)
                                              )
                                                ? 0.0
                                                : parseFloat(e.target.value),
                                            }
                                          : b?.bomLeveled,
                                      }
                                    : null,
                              });
                            }}
                          />
                          {b?.bom?.bomLeveled?.qty ?? 0}{" "}
                          {b?.bom?.bomLeveled?.type === "Component"
                            ? items.find(
                                (i) => i.id === b?.bom?.bomLeveled?.extItemId
                              )?.defaultUm
                            : "EA"}
                        </td>
                        <td className="border border-dark">
                          {/* {JSON.stringify (b?.bom?.bomLeveled?.forProcess)} */}
                          <Autocomplete
                            className="bg-light"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="For Process.."
                                variant="outlined"
                                size="small"
                              />
                            )}
                            options={forProcesses}
                            defaultValue={b?.bom?.bomLeveled?.forProcess}
                            getOptionLabel={(f) => f.name ?? ""}
                            onChange={(_, f) => {
                              modifyBomLeveled({
                                uuid: b?.bom?.bomLeveled?.uuid,
                                modifier: (b) => {
                                  if (b?.bomLeveled) {
                                    b.bomLeveled.forProcess = f as ForProcess;
                                  }

                                  return b ?? null;
                                },
                              });
                            }}
                          />
                          <small>{b.bom?.bomLeveled?.forProcess?.name}</small>
                        </td>
                        <td className="border border-dark">
                          <div className="d-flex">
                            <button
                              className="btn-danger btn btn-sm"
                              onClick={(e) => {
                                const newBomLeveled = { ...bomLeveled };

                                const traverseBom = (
                                  bx: BomLeveledRecursive | null
                                ) => {
                                  if (bx?.children) {
                                    bx.children = bx.children.filter(
                                      (bx) =>
                                        bx?.bomLeveled?.uuid !==
                                        b?.bom?.bomLeveled?.uuid
                                    );

                                    bx.children.forEach((bx) =>
                                      traverseBom(bx)
                                    );
                                  }
                                };

                                traverseBom(newBomLeveled);

                                // Reset approval
                                if (newBomLeveled.bomLeveled) {
                                  newBomLeveled.bomLeveled.approvals =
                                    newBomLeveled.bomLeveled.approvals?.map(
                                      (a) => ({ ...a, id: null })
                                    ) ?? [];
                                }

                                setBomLeveled(newBomLeveled);
                                setBomLeveledDeletedIds([
                                  ...bomLeveledDeletedIds,
                                  b?.bom?.bomLeveled?.id ?? 0,
                                ]);
                              }}
                            >
                              <small>
                                <Delete />
                              </small>
                            </button>
                            {b?.bom?.bomLeveled?.type !== "Component" &&
                            b?.bom?.bomLeveled?.type !== "Submodule" ? (
                              <>
                                {" "}
                                <div>
                                  <button
                                    className="mx-2 btn btn-sm btn-secondary"
                                    onClick={async () => {
                                      setSelectedBomCopy({
                                        bomLeveled: b?.bom ?? null,
                                      });
                                      try {
                                        const resp = await fetch(
                                          `${
                                            process.env.REACT_APP_BASE_URL
                                          }/bomleveled-type-filter?bomType=${handleFindBomType(
                                            b?.bom
                                          )}`
                                        );

                                        if (resp.status !== 200)
                                          throw await resp.text();

                                        setFilteredBomLeveledByType(
                                          await resp.json()
                                        );
                                      } catch (e) {
                                        console.log("[getting bom not ok]", e);
                                      }
                                      setShowDialog(true);
                                    }}
                                  >
                                    <FileCopy />
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {(() => {
                              switch (b?.bom?.bomLeveled?.type) {
                                case "Set":
                                  return (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        modifyBomLeveled({
                                          uuid: b?.bom?.bomLeveled?.uuid,
                                          modifier: (b) =>
                                            b
                                              ? {
                                                  ...b,
                                                  children: b.children
                                                    ? [
                                                        ...b.children,
                                                        {
                                                          bomLeveled: {
                                                            ...defaultBomLeveled,
                                                            uuid: uuidv4(),
                                                            type: "Module",
                                                          },
                                                          children: [],
                                                          bomLeveledIndividualEntities:
                                                            [],
                                                        },
                                                      ]
                                                    : b.children,
                                                }
                                              : null,
                                        });
                                      }}
                                    >
                                      <small>
                                        <Add /> Module
                                      </small>
                                    </button>
                                  );
                                case "Module":
                                  return (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        modifyBomLeveled({
                                          uuid: b?.bom?.bomLeveled?.uuid,
                                          modifier: (b) =>
                                            b
                                              ? {
                                                  ...b,
                                                  children: b.children
                                                    ? [
                                                        ...b.children,
                                                        {
                                                          bomLeveled: {
                                                            ...defaultBomLeveled,
                                                            uuid: uuidv4(),
                                                            type: "Submodule",
                                                          },
                                                          children: [],
                                                          bomLeveledIndividualEntities:
                                                            [],
                                                        },
                                                      ]
                                                    : b.children,
                                                }
                                              : null,
                                        });
                                      }}
                                    >
                                      <small>
                                        <Add /> Submodule
                                      </small>
                                    </button>
                                  );
                                case "Submodule":
                                  return (
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        modifyBomLeveled({
                                          uuid: b?.bom?.bomLeveled?.uuid,
                                          modifier: (b) =>
                                            b
                                              ? {
                                                  ...b,
                                                  children: b.children
                                                    ? [
                                                        ...b.children,
                                                        {
                                                          bomLeveled: {
                                                            ...defaultBomLeveled,
                                                            uuid: uuidv4(),
                                                            type: "Component",
                                                          },
                                                          children: [],
                                                          bomLeveledIndividualEntities:
                                                            [],
                                                        },
                                                      ]
                                                    : b.children,
                                                }
                                              : null,
                                        });
                                      }}
                                    >
                                      <small>
                                        <Add /> Component
                                      </small>
                                    </button>
                                  );

                                default:
                                  return <></>;
                              }
                            })()}
                          </div>
                        </td>
                        <td className="border border-dark">
                          {b?.bom?.bomLeveled?.remark}
                        </td>
                        <td
                          className="border border-dark"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <div style={{ width: 200 }}>
                            <Select
                              styles={{
                                control: (bx) => ({
                                  ...bx,
                                  backgroundColor: b.bom?.bomLeveled
                                    ?.extRequestItemId
                                    ? // req item id item found
                                      items.find(
                                        (i) =>
                                          `${i.id}` ===
                                          `${b.bom?.bomLeveled?.extRequestItemId}`
                                      )
                                      ? bomListFlattened.find(
                                          (bxx) =>
                                            `${bxx.bom.bom?.bomLeveled?.extRequestItemId}` ===
                                            `${b.bom?.bomLeveled?.extRequestItemId}`
                                        )
                                        ? // If item is found in the flattened list
                                          `lightgreen`
                                        : // If item is not found in the flattened list
                                          `yellow`
                                      : // req item id item not found
                                        `lightsalmon`
                                    : // No req item id
                                      undefined,
                                }),
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              className={``}
                              options={itemsReq.current.map((i) => ({
                                label: `#${i.id}:${i.partNum}:${i.partName}`,
                                value: i,
                              }))}
                              value={itemsReq.current
                                .map((i) => ({
                                  label: `#${i.id}:${i.partNum}:${i.partName}`,
                                  value: i,
                                }))
                                .find(
                                  (v) =>
                                    b.bom?.bomLeveled?.extRequestItemId &&
                                    `${v.value.id}` ===
                                      `${b.bom?.bomLeveled?.extRequestItemId}`
                                )}
                              onChange={(v) => {
                                const val = v as { value: ExtItemReq };

                                modifyBomLeveled({
                                  uuid: b?.bom?.bomLeveled?.uuid,
                                  modifier: (b) => {
                                    if (b?.bomLeveled) {
                                      b.bomLeveled.extRequestItemId =
                                        val.value.id;
                                    }

                                    return b ?? null;
                                  },
                                });
                              }}
                              placeholder="Request Item.."
                            />
                            {/* {b.bom?.bomLeveled?.extRequestItemId} */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </>
        )}
        <div>
          <hr className="border border-dark" />
        </div>
        {bomLeveled.bomLeveled?.id ? (
          <>
            <div>
              <h4>Jobs using this BOM:</h4>
            </div>
            <div>
              <div className="flex-grow-1">
                <Select
                  placeholder="Job..."
                  options={jobs.current.jobs.map((j) => ({
                    label: j.name,
                    value: j,
                  }))}
                  getOptionLabel={(j) => `${j.value.name ?? "No name"}`}
                  onChange={(v) => {
                    const val = v as { value: PpicJob };

                    if (
                      isNaN(parseInt(val.value.masterJavaBaseModel?.id ?? ""))
                    ) {
                      return;
                    }

                    const jobID = parseInt(
                      val.value.masterJavaBaseModel?.id ?? ""
                    );

                    if (
                      jobBomLeveleds.current.find(
                        (j) => `${j.job?.id}` === `${jobID}`
                      )
                    ) {
                      return;
                    }

                    jobBomLeveleds.current.push({
                      ...initialJobBomLeveled,
                      job: { ...initialJob, id: jobID },
                      bomLeveled: {
                        ...initialBomLeveled,
                        id: bomLeveled.bomLeveled?.id ?? null,
                      },
                    });

                    render();
                  }}
                />
              </div>
            </div>
            <div>
              <ol>
                {[
                  ...new Set(
                    jobBomLeveleds.current.map((j) => {
                      const foundJob = jobs.current.jobs.find(
                        (jx) =>
                          `${jx.masterJavaBaseModel?.id}` === `${j.job?.id}`
                      );

                      return foundJob?.masterJavaBaseModel?.id ?? "";
                    })
                  ),
                ].map((jid) => {
                  const foundJob = jobs.current.jobs.find(
                    (jx) => `${jx.masterJavaBaseModel?.id}` === `${jid}`
                  );

                  return (
                    <>
                      <li>{foundJob?.name}</li>
                    </>
                  );
                })}
              </ol>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Dialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <div className="m-3">
          {(() => {
            const type: BomType | null = handleFindBomType(
              selectedBomCopy?.bomLeveled
            );

            return (
              <>
                <div>
                  <h5>
                    Add {selectedBomCopy?.bomLeveled?.bomLeveled?.type} {type}{" "}
                    from existing{" "}
                  </h5>
                </div>
                <hr />
                <div>
                  {filteredBomLeveledByType.map((b) => {
                    return (
                      <>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={async (e) => {
                            try {
                              const resp = await fetch(
                                `${process.env.REACT_APP_BASE_URL}/bomleveled/${b.id}`,
                                {
                                  headers: { authorization: ctx?.apiKey ?? "" },
                                }
                              );

                              if (resp.status !== 200) throw await resp.text();

                              const bomRes =
                                (await resp.json()) as BomLeveledRecursive;

                              const modifyBomRes = (
                                b?: BomLeveledRecursive | null
                              ) => {
                                if (b && b.bomLeveled) {
                                  b.bomLeveled.id = null;
                                  b.bomLeveled.uuid = uuidv4();
                                }

                                b?.children.forEach((b) => {
                                  modifyBomRes(b);
                                });
                              };

                              modifyBomRes(bomRes);

                              console.log("[new bom res]", bomRes);

                              if (!bomLeveled.bomLeveled?.type) {
                                if (bomLeveled) {
                                  setBomLeveled({
                                    ...bomLeveled,
                                    children: bomLeveled.children
                                      ? [...bomLeveled.children, bomRes]
                                      : bomLeveled.children,
                                  });
                                }
                              } else {
                                modifyBomLeveled({
                                  uuid: selectedBomCopy?.bomLeveled?.bomLeveled
                                    ?.uuid,
                                  modifier: (b) =>
                                    b
                                      ? {
                                          ...b,
                                          children: b.children
                                            ? [...b.children, bomRes]
                                            : b.children,
                                        }
                                      : b ?? null,
                                });
                              }

                              setShowDialog(false);
                            } catch (e) {
                              console.log("[Failed getting bom] ", e);
                            }
                          }}
                          className="p-1 border border-dark"
                        >
                          (UID {b.id}) {b.name}
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            );
          })()}
        </div>
      </Dialog>
      <Dialog
        open={selectItemModal}
        onClose={() => {
          setSelectItemModal(false);
          setSelectBomItemModalOpen(false);
        }}
      >
        <div className="m-3">
          {items.length} items detected.
          <div className="d-flex">
            <input
              placeholder="Search by mfr, part desc, part name, part num..."
              className="form-control form-control-sm"
              onBlur={(e) => {
                setSearchItemFilter(e.target.value);
              }}
            />
            <button className="btn btn-dark btn-sm">Search</button>
          </div>
          {items
            .filter(
              (i) =>
                searchItemFilter !== "" &&
                `${i.mfr}${i.partDesc}${i.partName}${i.partNum}`
                  .toLowerCase()
                  .includes(searchItemFilter.toLowerCase())
            )
            .map((i, i_) => {
              return (
                <div
                  onClick={() => {
                    if (selectBomItemModalOpen) {
                      setBomLeveled({
                        ...bomLeveled,
                        bomLeveled: bomLeveled.bomLeveled
                          ? {
                              ...bomLeveled.bomLeveled,
                              extItemToCreateId: i.id,
                            }
                          : bomLeveled.bomLeveled,
                      });
                      setSelectItemModal(false);

                      return;
                    }
                    modifyBomLeveled({
                      uuid: selectItemBomLeveledUuid,
                      modifier: (b) =>
                        b
                          ? {
                              ...b,
                              bomLeveled: b?.bomLeveled
                                ? {
                                    ...b.bomLeveled,
                                    extItemId: i?.id ?? null,
                                    name: `${getItemFullDescription(
                                      items.find(
                                        (ix) => `${ix.id}` === `${i.id}`
                                      )
                                    )}`,
                                  }
                                : b?.bomLeveled,
                            }
                          : null,
                    });
                    setSelectItemModal(false);
                  }}
                  className="p-2 my-1 border border-dark"
                  style={{ cursor: "pointer" }}
                >
                  {`#${i_ + 1} (ID: #${i.id}) (${i.partNum} ${i.mfr}) ${
                    i.partName
                  } - ${i.partDesc}`}
                </div>
              );
            })}
        </div>
      </Dialog>
    </>
  );
};

export default BomDocumentHierarchyDetail;
